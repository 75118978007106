import { useState } from "react";

import styles from "./Sistem.module.css";
import daily from "../../../assets/landing/img/alquileres-diarios.png"
import monthly from "../../../assets/landing/img/alquileres-mensuales.png"
import sale from "../../../assets/landing/img/ventas.png"

import dailyIcon from "../../../assets/landing/svg/calendar.svg";
import monthlyIcon from "../../../assets/landing/svg/contract.svg";
import saleIcon from "../../../assets/landing/svg/sale.svg";
import img1 from "../../../assets/landing/img/cooperadores/LOGO LA ESCALA.png";

const sistem = [
  {
    name: "Diarios",
    img: daily,
    icon: dailyIcon,
    text: "Maneja a todos tus huéspedes en un solo lugar:",
    list: [
      "Lleva el control de tus huéspedes.",
      "Registra los ingresos de tus propiedades.",
      "Siempre disponible, donde estés.",
      "Todo en un solo lugar.",
    ]
  },
  {
    name: "Mensuales",
    img: monthly,
    icon: monthlyIcon,
    text: "Gestiona tus contratos mensuales de manera ágil y amigable:",
    list: [
      "Registro de todas tus propiedades que tienes.",
      "Controla los pagos mensuales.",
      "Ten la información disponible donde estés.",
      "Carga tus contratos y tenlos siempre listos.",
    ]
  },
  {
    name: "Ventas",
    img: sale,
    icon: saleIcon,
    text: "Registra tus ventas y mantén un control de todos tus ingresos:",
    list: [
      "Enlista tus propiedades en venta.",
      "Supervisa y registra tus ventas.",
      "Simplifica la gestión y optimiza tu tiempo.",
    ]
  }
];

export default function Sistem() {
  const [selected, setSelected] = useState(0);

  function handleSelectSistem(index: number) {
    setSelected(index);
  }

  return (
    <div id="sistem" className={styles.section}>
      <div className={styles.widthContainer}>
        <h2 data-aos="fade-up" data-aos-duration="800">Nuestro sistema</h2>
        <div className={styles.sistemInfo}>
          <div className={styles.text}>
            <div className={styles.header}>
              <img src={sistem[selected].icon} alt="svg" />
              <h4>{sistem[selected].name}</h4>
            </div>
            <span>{sistem[selected].text}</span>
            <ul>
              {sistem[selected].list.map((text, i) => (<li data-aos="fade-right" data-aos-delay={(i * 200)} data-aos-duration="800">{text}</li>))}
            </ul>
          </div>
          <div className={styles.imgContainer} data-aos="fade-left" data-aos-duration="800">
            <img src={sistem[selected].img} alt="sistem-img" />
          </div>
        </div>
        <div className={styles.controls}>
          <button className={selected === 0 ? styles.selected : ""} type="button" onClick={() => handleSelectSistem(0)}>.</button>
          <button className={selected === 1 ? styles.selected : ""} type="button" onClick={() => handleSelectSistem(1)}>.</button>
          <button className={selected === 2 ? styles.selected : ""} type="button" onClick={() => handleSelectSistem(2)}>.</button>
        </div>
      </div>
      <div className={styles.colaboradores}>
        <h4>Colaboradores del sistema</h4>
        <div>
          <img src={img1} alt="img" data-aos="fade-up" data-aos-delay="150" data-aos-duration="600" />
          <img src={img1} alt="img" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600" />
          <img src={img1} alt="img" data-aos="fade-up" data-aos-delay="450" data-aos-duration="600" />
          <img src={img1} alt="img" data-aos="fade-up" data-aos-delay="600" data-aos-duration="600" />
        </div>
      </div>
    </div>
  )
}
