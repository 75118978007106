import { useDispatch, useSelector } from "react-redux";
import { History, HistoryType } from "../../../interfaces/History";
import { deleteHistory } from "../../../redux/actions/history";
import { closeLoading } from "../../../redux/actions/loading";
import { RootState } from "../../../interfaces/RootState";
import { useEffect, useState } from "react";
import {
  setDailyRent,
  updateDailyRent,
  deleteDailyRent,
  setDailyRentPrevPage,
  setDailyRentNextPage,
  getDailyFirstPage,
  getDailyRentNextPage,
} from "../../../redux/actions/rentals/dailyRent";
import { DailyRent, initDailyFilter } from "../../../interfaces/Rentals";
import useHistory from "../../useHistory";
import swal from "sweetalert";

export default function useDailyRent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const daily = useSelector((state: RootState) => state.dailyRentals);
  const [filters, setFilters] = useState(initDailyFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [currentData, setData] = useState<DailyRent[]>([]);

  useEffect(() => {
    if (daily.data.length > 0) {
      setData(daily.data[daily.current - 1]);
    }
  }, [daily]);

  async function error(promise: Promise<void>) {
    setLoading(true);
    return promise
      .then(() => setLoading(false))
      .catch((err: Error) => {
        console.log(err);
        setLoading(false);
        swal("Error", "Ocurrió un error, intentelo mas tarde", "error");
      });
  }

  async function addRent(rent: DailyRent) {
    const historyId: string = history.generateId();

    const newHistory: History = {
      id: historyId,
      propertyId: rent.propertyId,
      tenantName: rent.tenant.name,
      price: rent.price,
      admissionDate: new Date(),
      type: HistoryType.daily,
      rentalDates: rent.dates,
    };

    const newRent: DailyRent = {
      ...rent,
      historyId: newHistory.id,
    };

    return await Promise.all([
      dispatch<any>(setDailyRent(newRent)),
      history.add(historyId, newHistory),
    ])
      .then(() => {
        setLoading(true);
        dispatch<any>(getDailyFirstPage(filters)).then(() => setLoading(false));
      })
      .catch((error: Error) => {
        setLoading(false);
        dispatch(closeLoading());
        console.log(error.message);
      });
  }

  async function getFirstPage(): Promise<void> {
    // Get first page
    return await error(dispatch<any>(getDailyFirstPage(filters)));
  }

  async function getNextPage(): Promise<void> {
    // Check if this page already exist
    if (daily.data[daily.current]) {
      await dispatch<any>(setDailyRentNextPage());
    } else {
      // If not exist get this page
      if (!daily.lastDoc) throw new Error("No se encontró el ùltimo elemento");
      return await error(
        dispatch<any>(getDailyRentNextPage(daily.lastDoc, filters))
      );
    }
  }

  async function getPrevPage(): Promise<void> {
    await error(dispatch<any>(setDailyRentPrevPage()));
  }

  async function updateRent(rent: DailyRent) {
    const updateHistory = {
      id: rent.historyId,
      propertyId: rent.propertyId,
      tenantName: rent.tenant.name,
      price: rent.price,
      rentalDates: rent.dates,
    };

    return await Promise.all([
      dispatch<any>(updateDailyRent(rent)),
      history.update(updateHistory),
    ]);
  }

  async function deleteRent(rent: DailyRent) {
    return await Promise.all([
      dispatch<any>(deleteDailyRent(rent)),
      dispatch<any>(deleteHistory(rent.historyId!)),
    ]);
  }

  async function resetFilters() {
    setFilters(initDailyFilter());
    return await getFirstPage();
  }

  return {
    data: currentData,
    totalsDocs: daily.totalDocs,
    filters: {
      data: filters,
      reset: resetFilters,
      setFilters: setFilters,
    },
    page: {
      current: daily.current,
      totals: daily.totalPages,
      firstPage: getFirstPage,
      prevPage: getPrevPage,
      nextPage: getNextPage,
    },
    loading: loading,
    add: addRent,
    update: updateRent,
    delete: deleteRent,
  };
}
