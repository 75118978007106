import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { SigninData, SigninError, initSignin, initSigninError } from "../../interfaces/login";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { signin } from "../../redux/actions/login";
import useLogin from "../../hooks/useLogin";
import swal from "sweetalert";

import styles from "./Signin.module.css";
import logo from "../../assets/img/icon.png";
import Input from "../../component/Inputs/Input";

export default function Signin() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const login = useLogin();
  const [user, setUser] = useState<SigninData>(initSignin());
  const [error, setError] = useState<SigninError>(initSigninError());

  // Save image to profile
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    login.autoRegister();
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUser({ ...user, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  }

  function handleFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files !== null && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const url = URL.createObjectURL(event.target.files[0]);
      setImageUrl(url);
      setError({ ...error, profileImg: "" });
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validations()) {
      dispatch(openLoading());
      dispatch<any>(signin(user, file!))
        .then(() => {
          redirect("/dashboard");
          dispatch(closeLoading());
        })
        .catch((error: any) => {
          dispatch(closeLoading());
          if (error.message.includes("auth/email-already-in-use")) {
            setError({ ...error, email: "Ya existe un usuario con ese correo" });
          } else if (error.message.includes("auth/weak-password")) {
            setError({ ...error, password: "La contraseña debe tener minimo 6 caracteres" });
          } else {
            swal("Error", "No se pudo registrar, inténtelo más tarde", "error");
          }
        });
    }
  }

  function validations() {
    let errors: SigninError = initSigninError();
    let value = true;

    // NAME
    if (user.name === "") {
      errors.name = "Debes escribir un nombre";
      value = false;
    }

    // CUIT-CUIL
    if (user.cuitCuil === 0) {
      errors.cuitCuil = "Debes ingresar tu CUIT o CUIL";
      value = false;
    }

    /*     else if (!isValidCUIT(user.cuitCuil)) {
          errors.cuitCuil = "El CUIT o CUIL ingresado es invalido";
          value = false;
        } */

    // CORREO
    if (user.email === "") {
      errors.email = "Debes escribir un correo";
      value = false;
    }

    // CORREO
    if (user.email === "") {
      errors.email = "Debes escribir un correo";
      value = false;
    }

    // PASSWORD
    if (user.password === "") {
      errors.password = "Debes escribir una contraseña";
      value = false;
    }

    if (user.repeatPassword === "") {
      errors.password = "Debes repetir la contraseña";
      value = false;
    }

    if (user.password !== user.repeatPassword) {
      errors.password = "Las contraseñas no coinciden";
      errors.repeatPassword = "Las contraseñas no coinciden";
      value = false;
    }

    if (file === null) {
      errors.profileImg = "Debes agregar una imagen de perfil";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={styles.background}>
      <header className="toBottom">
        <img src={logo} alt="logo" />
        <h1>GESTOPIA</h1>
      </header>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h3>Crear tu cuenta</h3>
          <div className={styles.selectImg}>
            <div className={styles.logoContainer}>
              {file ? (
                <img src={imageUrl} alt="your-logo" />
              ) : (
                <span>Seleccione una imagen</span>
              )}
            </div>
            <input
              type="file"
              name="file"
              accept="image/*"
              className={`form-control ${error.profileImg ? "is-invalid" : ""}`}
              onChange={handleFile}
              placeholder="logo"
            />
            <small>{error.profileImg}</small>
            <Input
              name="phone"
              label="Telefono"
              value={user.phone}
              error={error.phone}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.inputContainer}>
            <Input
              name="name"
              label="Nombre"
              value={user.name}
              error={error.name}
              handleChange={handleChange}
            />
            <Input
              name="cuitCuil"
              label="CUIT / CUIL"
              value={user.cuitCuil}
              error={error.cuitCuil}
              handleChange={handleChange}
            />
            <Input
              name="email"
              label="Email"
              value={user.email}
              error={error.email}
              handleChange={handleChange}
            />
            <Input
              name="password"
              label="Contraseña"
              type="password"
              value={user.password}
              error={error.password}
              handleChange={handleChange}
            />
            <Input
              name="repeatPassword"
              label="Repetir contraseña"
              type="password"
              value={user.repeatPassword}
              error={error.repeatPassword}
              handleChange={handleChange}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Registrarse
          </button>
        </form>
        <div className={styles.newAcount}>
          <span>Ya tengo cuenta </span>
          <Link to="/login"> Iniciar sesión</Link>
        </div>
      </div>
    </div >
  );
}
