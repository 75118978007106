import { useDispatch, useSelector } from "react-redux";
import { History, HistoryType } from "../../../interfaces/History";
import { useEffect, useState } from "react";
import { deleteHistory } from "../../../redux/actions/history";
import { closeLoading } from "../../../redux/actions/loading";
import { RemoveFiles } from "../../../interfaces/Rentals";
import { RootState } from "../../../interfaces/RootState";
import {
  deleteMonthlyRent,
  getMonthlyFirstPage,
  getMonthlyRentNextPage,
  setMonthlyRent,
  setMonthlyRentNextPage,
  setMonthlyRentPrevPage,
  updateMonthlyRent,
} from "../../../redux/actions/rentals/monthlyRentals";
import {
  MonthlyRent,
  MonthlyRentFiles,
  initMonthlyFilter,
} from "../../../interfaces/Registers/Monthly";
import useHistory from "../../useHistory";
import swal from "sweetalert";

export default function useMonthlyRent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const monthly = useSelector((state: RootState) => state.monthlyRentals);
  const [filters, setFilters] = useState(initMonthlyFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [currentData, setData] = useState<MonthlyRent[]>([]);

  useEffect(() => {
    if (monthly.data.length > 0) {
      setData(monthly.data[monthly.current - 1]);
    }
  }, [monthly]);

  async function error(promise: Promise<void>) {
    setLoading(true);
    return promise
      .then(() => setLoading(false))
      .catch((err: Error) => {
        console.log(err);
        setLoading(false);
        swal("Error", "Ocurrió un error, intentelo mas tarde", "error");
      });
  }

  async function addRent(rent: MonthlyRent, filesToUpload: MonthlyRentFiles) {
    // Create history
    const historyId: string = history.generateId();

    const newHistory: History = {
      id: historyId,
      propertyId: rent.propertyId,
      tenantName: rent.tenant.name,
      price: rent.price,
      admissionDate: new Date(),
      type: HistoryType.monthly,
      rentalDates: rent.dates,
    };

    const newRent: MonthlyRent = {
      ...rent,
      historyId: newHistory.id,
    };

    return await Promise.all([
      dispatch<any>(setMonthlyRent(newRent, filesToUpload)),
      history.add(historyId, newHistory),
    ])
      .then(() => {
        setLoading(true);
        dispatch<any>(getMonthlyFirstPage(filters)).then(() =>
          setLoading(false)
        );
      })
      .catch((error: Error) => {
        setLoading(false);
        dispatch(closeLoading());
        console.log(error.message);
      });
  }

  async function getFirstPage(): Promise<void> {
    // Get first page
    console.log("Getting the first page with this fitlers: ", filters);
    return await error(dispatch<any>(getMonthlyFirstPage(filters)));
  }

  async function getNextPage(): Promise<void> {
    // Check if this page already exist
    if (monthly.data[monthly.current]) {
      await dispatch<any>(setMonthlyRentNextPage());
    } else {
      // If not exist get this page
      return await error(
        dispatch<any>(getMonthlyRentNextPage(monthly.lastDoc!, filters))
      );
    }
  }

  async function getPrevPage(): Promise<void> {
    await error(dispatch<any>(setMonthlyRentPrevPage()));
  }

  async function updateRent(
    rent: MonthlyRent,
    filesToUpload: MonthlyRentFiles,
    filesToRemove: RemoveFiles
  ) {
    return await dispatch<any>(
      updateMonthlyRent(rent, filesToUpload, filesToRemove)
    );
  }

  async function deleteRent(rent: MonthlyRent) {
    return await Promise.all([
      dispatch<any>(deleteMonthlyRent(rent)),
      dispatch<any>(deleteHistory(rent.historyId!)),
    ]);
  }

  async function resetFilters() {
    setFilters(initMonthlyFilter());
    return await getFirstPage();
  }

  return {
    data: currentData,
    totalsDocs: monthly.totalDocs,
    filters: {
      data: filters,
      reset: resetFilters,
      setFilters: setFilters,
    },
    page: {
      current: monthly.current,
      totals: monthly.totalPages,
      firstPage: getFirstPage,
      prevPage: getPrevPage,
      nextPage: getNextPage,
    },
    loading: loading,
    add: addRent,
    update: updateRent,
    delete: deleteRent,
  };
}
