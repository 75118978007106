import { initDailyRentState } from "../../interfaces/RootState";
import { DailyRent } from "../../interfaces/Rentals";
import { AnyAction } from "redux";
import { LOGOUT } from "../actions/login";
import {
  SET_DAILY_RENT,
  UPDATE_DAILY_RENT,
  DELETE_DAILY_RENT,
  GET_DAILY_RENT,
  GET_DAILY_RENT_NEXT_PAGE,
  SET_DAILY_RENT_NEXT_PAGE,
  SET_DAILY_RENT_PREV_PAGE,
} from "../actions/rentals/dailyRent";

const dailyRentReducer = (state = initDailyRentState(), action: AnyAction) => {
  switch (action.type) {
    case SET_DAILY_RENT:
      return {
        data: [...state.data, action.payload.data],
      };

    case GET_DAILY_RENT:
      return {
        ...state,
        data: [action.payload.data],
        current: 1,
        totalDocs: action.payload.totals,
        totalPages: Math.ceil(action.payload.totals / state.length),
        lastDoc: action.payload.lastDoc,
      };

    case GET_DAILY_RENT_NEXT_PAGE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        current: state.current + 1,
        lastDoc: action.payload.lastDoc,
      };

    case SET_DAILY_RENT_NEXT_PAGE:
      return {
        ...state,
        current: state.current + 1,
      };

    case SET_DAILY_RENT_PREV_PAGE:
      return {
        ...state,
        current: state.current - 1,
      };

    case UPDATE_DAILY_RENT:
      return {
        ...state,
        data: state.data.map((page) =>
          page.map((daily) =>
            daily.id === action.payload.id
              ? { ...daily, ...action.payload.data }
              : daily
          )
        ),
      };
    case DELETE_DAILY_RENT:
      return {
        ...state,
        data: state.data.map((page) =>
          page.filter((daily) => daily.id !== action.payload.id)
        ),
      };
      
    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default dailyRentReducer;
