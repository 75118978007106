import { useEffect, useState } from "react";
import { ManagmentType } from "../../../../../../interfaces/Properties";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../interfaces/RootState";
import {
  initErrorMonthlyRent,
  initMonthlyRentFiles,
  MonthlyRentFiles,
  ErrorMonthlyRent,
  initMonthlyRent,
  MonthlyRent,
  FilesData,
  MonthRented,
} from "../../../../../../interfaces/Registers/Monthly";
import {
  RemoveFiles,
  initRemoveFiles,
} from "../../../../../../interfaces/Rentals";

import FilesManagment from "../../../../../../component/FilesManagment/FilesManagment";
import TextAreaInput from "../../../../../../component/Inputs/TextareaInput";
import SelectInput from "../../../../../../component/Inputs/SelectInput";
import InputGroup from "../../../../../../component/Inputs/InputGroup/InputGroup";
import Input from "../../../../../../component/Inputs/Input";

import style from "./MonthlyRentForm.module.css";

interface Props {
  handleClose: () => void;
  handleSubmit: (
    MonthlyRent: MonthlyRent,
    filesToUpload: MonthlyRentFiles,
    filesToRemove: RemoveFiles,
    edit: boolean
  ) => void;
  data: MonthlyRent | undefined;
}

export default function MonthlyRentForm({
  handleClose,
  handleSubmit,
  data,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);
  const [monthlyRent, setMonthlyRent] = useState<MonthlyRent>(
    initMonthlyRent()
  );
  const [error, setError] = useState<ErrorMonthlyRent>(initErrorMonthlyRent());
  const [guarantor, setGuarantor] = useState(false);
  const [months, setMonths] = useState<number>(0);

  const [selection, setSelection] = useState<"contract" | "secure" | "">("");
  const [filesData, setFilesData] = useState<MonthlyRentFiles>(
    initMonthlyRentFiles()
  );
  const [filesToUpload, setFilesToUpload] = useState<MonthlyRentFiles>(
    initMonthlyRentFiles()
  );
  const [filesToRemove, setFilesToRemove] = useState<RemoveFiles>(
    initRemoveFiles()
  );

  useEffect(() => {
    if (data) {
      setMonthlyRent(data);
      setFilesData(data.files);
    }
  }, [data]);

  function handlelocalSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    /*     if (validations()) { */
    handleSubmit(
      monthlyRent,
      filesToUpload,
      filesToRemove,
      data ? true : false
    );
    /*     } */
  }

  function handleChange(
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) {
    setMonthlyRent({ ...monthlyRent, [event.target.name]: event.target.value });
  }

  function handleChangeTenant(event: React.ChangeEvent<HTMLInputElement>) {
    setMonthlyRent({
      ...monthlyRent,
      tenant: {
        ...monthlyRent.tenant,
        [event.target.name]: event.target.value,
      },
    });
  }

  function handleChangeDates(event: React.ChangeEvent<HTMLInputElement>) {
    setMonthlyRent({
      ...monthlyRent,
      dates: {
        ...monthlyRent.dates,
        [event.target.name]: new Date(event.target.value),
      },
    });
  }

  function handleChangeMonths(event: React.ChangeEvent<HTMLInputElement>) {
    const initMonth: number = monthlyRent.dates.initDate.getMonth();
    const monthsToAdd: number = Number(event.target.value);

    if (initMonth) {
      const newEndDate: Date = new Date(monthlyRent.dates.initDate.toString());
      newEndDate.setMonth(initMonth + monthsToAdd);

      setMonthlyRent({
        ...monthlyRent,
        dates: {
          ...monthlyRent.dates,
          endDate: newEndDate,
        },
      });
    }
    setMonths(Number(event.target.value));
  }

  function handleLocalClose() {
    handleClose();
  }

  function handleSeFile(fileToUpload: FilesData, type: "contract" | "secure") {
    // Add file to upload
    setFilesToUpload({
      ...filesToUpload,
      [type]: [...filesToUpload[type], fileToUpload],
    });

    // Add file data to view
    setFilesData({
      ...filesData,
      [type]: [...filesData[type], fileToUpload],
    });
  }

  function handleRemoveFile(file: FilesData, type: "contract" | "secure") {
    // If filePath have text, remove the file to the filesData
    if (file.path !== "") {
      setFilesData({
        ...filesData,
        [type]: filesData[type].filter((upload) => upload.path !== file.path),
      });
    } else {
      // ELse remove to the filesToUpload
      setFilesToUpload({
        ...filesToUpload,
        [type]: filesToUpload[type].filter(
          (upload) =>
            upload.file!.webkitRelativePath !== upload.file?.webkitRelativePath
        ),
      });
    }

    // Add file path to filesToRemove to remove
    setFilesToRemove({
      ...filesToRemove,
      [type]: [...filesToRemove[type], file.path],
    });
  }

  function validations() {
    let errors = initErrorMonthlyRent();
    let value = true;

    /* ADD VALIDATIONS */
    if (monthlyRent.propertyId === "") {
      errors.propertyId = "Debes seleccionar una propiedad";
      value = false;
    }

    setError(errors);
    return value;
  }

  function handleCloseFilesManagment() {
    setSelection("");
  }

  return (
    <div className={style.background}>
      {selection && (
        <FilesManagment
          type={selection}
          files={filesData[selection]}
          setFilesToUpload={handleSeFile}
          setFilesToRemove={handleRemoveFile}
          handleClose={handleCloseFilesManagment}
        />
      )}
      <form className={`toTop ${style.form}`} onSubmit={handlelocalSubmit}>
        <div className={style.close}>
          <h3>Nuevo alquiler mensual</h3>
          <div className="btn-close" onClick={handleLocalClose} />
        </div>
        <SelectInput
          name="rented"
          label="Alquilado"
          value={monthlyRent.rented}
          list={[
            { label: "No", value: MonthRented.NO },
            { label: "Si", value: MonthRented.SI },
          ]}
          handleChange={handleChange}
        />
        {monthlyRent.rented === MonthRented.SI && (
          <div className={style.flex}>
            <div className={style.content}>
              <h5>Propiedad</h5>
              <SelectInput
                name={"propertyId"}
                value={monthlyRent.propertyId}
                label={"Propiedad"}
                list={properties
                  .filter((prop) => prop.registerType.monthly)
                  .map((prop) => ({ label: prop.name, value: prop.id! }))}
                error={error.propertyId}
                handleChange={handleChange}
              />
              <InputGroup
                name={"price"}
                label={"Precio"}
                value={monthlyRent.price}
                type={"number"}
                simbol="$"
                error={error.price}
                handleChange={handleChange}
              />
              <InputGroup
                name={"total"}
                label={"Total"}
                type={"number"}
                simbol="$"
                value={monthlyRent.total}
                handleChange={handleChange}
              />
              {properties.find((prop) => prop.id === monthlyRent.propertyId)
                ?.managment === ManagmentType.Managing && (
                <InputGroup
                  name={"managmentFees"}
                  label={"Comisión"}
                  type={"number"}
                  simbol="$"
                  value={monthlyRent.managmentFees}
                  handleChange={handleChange}
                />
              )}
              <h5>Impuestos</h5>
              <div className={`${style.flex} ${style.taxes}`}>
                <span>1</span>
                <div className={style.column}>
                  <Input
                    name={"taxesName1"}
                    label={"Nombre"}
                    value={monthlyRent.taxesName1}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                  <Input
                    name={"taxesTotal1"}
                    label={"Total"}
                    value={monthlyRent.taxesTotal1}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                </div>
                <Input
                  name={"taxesNumberRef1"}
                  label={"Numero de referencia"}
                  value={monthlyRent.taxesNumberRef1}
                  error={error.taxes}
                  handleChange={handleChange}
                />
                <span>2</span>
                <div className={style.column}>
                  <Input
                    name={"taxesName2"}
                    label={"Nombre"}
                    value={monthlyRent.taxesName2}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                  <Input
                    name={"taxesTotal2"}
                    label={"Total"}
                    value={monthlyRent.taxesTotal2}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                </div>
                <Input
                  name={"taxesNumberRef2"}
                  label={"Numero de referencia"}
                  value={monthlyRent.taxesNumberRef2}
                  error={error.taxes}
                  handleChange={handleChange}
                />
                <span>3</span>
                <div className={style.column}>
                  <Input
                    name={"taxesName3"}
                    label={"Nombre"}
                    value={monthlyRent.taxesName3}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                  <Input
                    name={"taxesTotal3"}
                    label={"Total"}
                    value={monthlyRent.taxesTotal3}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                </div>
                <Input
                  name={"taxesNumberRef3"}
                  label={"Numero de referencia"}
                  value={monthlyRent.taxesNumberRef3}
                  error={error.taxes}
                  handleChange={handleChange}
                />
                <span>4</span>
                <div className={style.column}>
                  <Input
                    name={"taxesName4"}
                    label={"Nombre"}
                    value={monthlyRent.taxesName4}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                  <Input
                    name={"taxesTotal4"}
                    label={"Total"}
                    value={monthlyRent.taxesTotal4}
                    error={error.taxes}
                    handleChange={handleChange}
                  />
                </div>
                <Input
                  name={"taxesNumberRef4"}
                  label={"Numero de referencia"}
                  value={monthlyRent.taxesNumberRef4}
                  error={error.taxes}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className={style.content}>
              <h5>Inquilino</h5>
              <Input
                name={"name"}
                label={"Nombre"}
                value={monthlyRent.tenant.name}
                error={error.tenant}
                handleChange={handleChangeTenant}
              />
              <Input
                name={"email"}
                label={"Email"}
                value={monthlyRent.tenant.email}
                error={error.tenant}
                handleChange={handleChangeTenant}
              />
              <Input
                name={"dni"}
                label={"D.N.I."}
                value={monthlyRent.tenant.dni}
                error={error.tenant}
                handleChange={handleChangeTenant}
              />
              <Input
                name={"phone"}
                label={"Teléfono"}
                value={monthlyRent.tenant.phone}
                error={error.tenant}
                handleChange={handleChangeTenant}
              />
            </div>

            <div className={style.content}>
              <div className={style.guarantor}>
                <button
                  className={guarantor ? style.selected : ""}
                  type="button"
                  onClick={() => setGuarantor(true)}
                >
                  Garante
                </button>
                <button
                  className={!guarantor ? style.selected : ""}
                  type="button"
                  onClick={() => setGuarantor(false)}
                >
                  Seguro
                </button>
              </div>
              {guarantor ? (
                <div>
                  <div className={style.column}>
                    <Input
                      name={"guarantorName1"}
                      label={"Nombre"}
                      value={monthlyRent.guarantorName1}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorDNI1"}
                      label={"D.N.I."}
                      value={monthlyRent.guarantorDNI1}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorEmail1"}
                      label={"Email"}
                      value={monthlyRent.guarantorEmail1}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorPhone1"}
                      label={"Telefono"}
                      value={monthlyRent.guarantorPhone1}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className={style.column}>
                    <Input
                      name={"guarantorName"}
                      label={"Nombre"}
                      value={monthlyRent.guarantorName2}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorDNI"}
                      label={"D.N.I."}
                      value={monthlyRent.guarantorDNI2}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorEmail2"}
                      label={"Email"}
                      value={monthlyRent.guarantorEmail2}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorPhone2"}
                      label={"Telefono"}
                      value={monthlyRent.guarantorPhone2}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className={style.column}>
                    <Input
                      name={"guarantorName3"}
                      label={"Nombre"}
                      value={monthlyRent.guarantorName3}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorDNI3"}
                      label={"D.N.I."}
                      value={monthlyRent.guarantorDNI3}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorEmail3"}
                      label={"Email"}
                      value={monthlyRent.guarantorEmail3}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorPhone3"}
                      label={"Telefono"}
                      value={monthlyRent.guarantorPhone3}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className={style.column}>
                    <Input
                      name={"guarantorName"}
                      label={"Nombre"}
                      value={monthlyRent.guarantorName4}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorDNI"}
                      label={"D.N.I."}
                      value={monthlyRent.guarantorDNI4}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorEmail4"}
                      label={"Email"}
                      value={monthlyRent.guarantorEmail4}
                      handleChange={handleChange}
                    />
                    <Input
                      name={"guarantorPhone4"}
                      label={"Telefono"}
                      value={monthlyRent.guarantorPhone4}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              ) : (
                <div className={style.filesManagment}>
                  <span>Seguro de caución</span>
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => setSelection("secure")}
                  >
                    Administrar
                  </button>
                  <span>{filesData.secure.length} archivos</span>
                </div>
              )}
            </div>

            <div className={style.content}>
              <h5>Contrato</h5>
              <div className={style.column}>
                <SelectInput
                  name={"typeOfContract"}
                  value={monthlyRent.typeOfContract}
                  label={"Tipo de contrato"}
                  list={[
                    { label: "Ley de alquileres", value: "LEY_DE_ALQUILERES" },
                    { label: "Comercial", value: "COMERCIAL" },
                    { label: "Comodato", value: "COMODATO" },
                  ]}
                  error={error.typeOfContract}
                  handleChange={handleChange}
                />
                <Input
                  name={"leaseRenewal"}
                  value={monthlyRent.leaseRenewal}
                  label={"Actualización"}
                  type="number"
                  error={error.leaseRenewal}
                  handleChange={handleChange}
                />
              </div>
              <Input
                name={"initDate"}
                label={"Fecha de inicio"}
                value={monthlyRent.dates.initDate?.toISOString()?.split("T")[0]}
                type={"date"}
                handleChange={handleChangeDates}
              />
              <div className={style.column}>
                <Input
                  name={"expirationDate"}
                  label={"Dia de vencimiento"}
                  value={monthlyRent.expirationDate}
                  type={"number"}
                  error={error.expirationDate}
                  handleChange={handleChange}
                />
                <Input
                  name={"months"}
                  label={"Meses"}
                  value={months}
                  type={"number"}
                  handleChange={handleChangeMonths}
                />
              </div>
              <Input
                name={"endDate"}
                label={"Fecha de finalización"}
                value={monthlyRent.dates.endDate?.toISOString()?.split("T")[0]}
                type={"date"}
                handleChange={handleChangeDates}
              />
              <InputGroup
                name={"deposit"}
                label={"Deposito"}
                value={monthlyRent.deposit}
                type={"number"}
                simbol="$"
                error={error.deposit}
                handleChange={handleChange}
              />
              <div className={style.filesManagment}>
                <span>Contrato</span>
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => setSelection("contract")}
                >
                  Administrar
                </button>
                <span>{filesData.contract.length} archivos</span>
              </div>
            </div>
          </div>
        )}
        {monthlyRent.rented === MonthRented.NO && (
          <div className={style.flex}>
            <div className={style.column}>
              <InputGroup
                name={"price"}
                label={"Precio a pedir"}
                value={monthlyRent.price}
                simbol="$"
                type={"number"}
                handleChange={handleChange}
              />
              <InputGroup
                name={"comission"}
                label={"Comisión"}
                value={monthlyRent.commission}
                type={"number"}
                simbol="$"
                handleChange={handleChange}
              />
            </div>
            <div className={style.column}>
              <SelectInput
                name={"typeOfContract"}
                value={monthlyRent.typeOfContract}
                label={"Tipo de contrato"}
                list={[
                  { label: "Ley de alquileres", value: "LEY_DE_ALQUILERES" },
                  { label: "Comercial", value: "COMERCIAL" },
                  { label: "Comodato", value: "COMODATO" },
                ]}
                error={error.typeOfContract}
                handleChange={handleChange}
              />
              <Input
                name={"guarantors"}
                label={"Garantes"}
                value={monthlyRent.guarantors}
                type="number"
                handleChange={handleChange}
              />
            </div>
            <TextAreaInput
              name={"considerations"}
              label={"Consideraciones"}
              value={monthlyRent.considerations}
              handleChange={handleChange}
            />
          </div>
        )}
        <button type="submit" className="btn btn-success">
          {data ? "Guardar" : "Agregar"} alquiler mensual
        </button>
      </form>
    </div>
  );
}
