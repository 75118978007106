export interface CashRegister {
  id?: string;
  date: Date;
  concept: string;
  acount: string;
  money: {
    income: number;
    expense: number;
  };
  balance: number;
}

export interface Acount {
  id: number;
  name: string;
  balance: number;
}

export interface ErrorCashRegister {
  date: string;
  concept: string;
  acount: string;
  moneyIncome: string;
  moneyExpense: string;
}

export const initAcount = (): Acount => ({
  id: 0,
  name: "",
  balance: 0,
});

export const initCashRegister = (): CashRegister => ({
  date: new Date(),
  concept: "",
  acount: "",
  money: {
    income: 0,
    expense: 0,
  },
  balance: 0,
});

export const initErrorCashRegister = (): ErrorCashRegister => ({
  date: "",
  concept: "",
  acount: "",
  moneyIncome: "",
  moneyExpense: "",
});
