import { useCallback, useEffect, useState } from "react";
import { History } from "../../../../interfaces/History";
import { RootState } from "../../../../interfaces/RootState";
import { useSelector } from "react-redux";

import HistoryRow from "./HistoryRow/HistoryRow";
import DateFilter from "../../../../component/DateFilter/DateFilter";
import SelectInput from "../../../../component/Inputs/SelectInput";

import styles from "./HistoryTable.module.css";
import loadingGif from "../../../../assets/img/loading.gif";
import errorSvg from "../../../../assets/svg/error.svg";
import useHistory from "../../../../hooks/useHistory";
import FullCalendarView from "../../../../component/FullCalendarView/FullCalendarView";

interface Filter {
  year: string;
  month: string | null;
  day: string | null;
}

export default function HistoryTable() {
  const history = useHistory();
  const [propertyId, setPropertyId] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState<History[]>([]);
  const [filters, setFilters] = useState({
    property: "",
    tenant: "",
  });
  const [tenantsList, setTenantsList] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<Filter>({
    year: new Date().getFullYear().toString(),
    month: (new Date().getMonth() + 1).toString(),
    day: "",
  });

  const handleGetHistory = useCallback((localFilter: Filter) => {
    setLoading(true);
    history
      .get(localFilter)
      .then(() => {
        setLoading(false);
        setError(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (history.list.length <= 0) {
      handleGetHistory(dateFilter);
    }
  }, [handleGetHistory]);

  useEffect(() => {
    setRows(
      history.list.filter((item) => {
        if (filters.property && item.propertyId !== filters.property)
          return false;
        if (filters.tenant && item.tenantName !== filters.tenant) return false;
        return true;
      })
    );
  }, [history.list, filters]);

  useEffect(() => {
    const tenants: string[] = [];

    history.list.map((data) => {
      // Check if already exist
      if (
        data.tenantName &&
        !tenants.some((tenant) => tenant === data.tenantName)
      ) {
        tenants.push(data.tenantName);
      }
    });

    setTenantsList(tenants);
  }, [history.list]);

  function handleFilterDate(filter: Filter) {
    setDateFilter(filter);
    handleGetHistory(filter);
  }

  function handleFilters(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleView(propertyId?: string) {
    setPropertyId(propertyId || "");
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {propertyId && (
        <FullCalendarView propertyId={propertyId} handleClose={handleView} />
      )}
      <div className={styles.controls}>
        <DateFilter handleFilterDate={handleFilterDate} />
{/*         <SelectInput
          name="property"
          label="Propiedad"
          value={filters.property}
          list={properties.map((property) => ({
            label: property.name,
            value: property.id!,
          }))}
          handleChange={handleFilters}
        /> */}
        <SelectInput
          name="tenant"
          label="Huesped"
          value={filters.tenant}
          list={tenantsList.map((item: string) => ({
            label: item,
            value: item,
          }))}
          handleChange={handleFilters}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.row} ${styles.firstRow}`}>
            <th>Fecha</th>
            <th>Propiedad</th>
            <th>Huesped</th>
            <th>Tipo</th>
            <th>Fechas</th>
            <th>Precio</th>
            <th>Ver</th>
          </tr>
        </thead>
        <tbody className={styles.contentRows}>
          <div>
            {loading ? (
              <div className={styles.loading}>
                <img src={loadingGif} alt="loading" />
              </div>
            ) : null}
            {error ? (
              <div className={styles.error}>
                <img src={errorSvg} alt="error" />
                <span>No se pudo cargar los history</span>
                <div>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => handleGetHistory(dateFilter)}
                  >
                    Recargar
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    onClick={() => setError(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : null}
            {rows.length <= 0 ? (
              <tr className={styles.emptyRows}>
                <th>No hay historial</th>
              </tr>
            ) : (
              rows?.map((item: History) => (
                <HistoryRow
                  key={item.id!}
                  history={item}
                  handleView={handleView}
                />
              ))
            )}
          </div>
        </tbody>
      </table>
    </div>
  );
}
