import logo from "../../../assets/img/lobo-banner.png";
import house from "../../../assets/svg/sideBar/house.svg";
import realEstate from "../../../assets/svg/sideBar/real-estate.svg";
import history from "../../../assets/svg/sideBar/history.svg";
import cashRegister from "../../../assets/svg/sideBar/cash-register.svg";

import styles from "./SideBar.module.css";

const items = [
  { name: "Propiedades", icon: house },
  { name: "Registros", icon: realEstate },
  { name: "Historial", icon: history },
  { name: "Caja", icon: cashRegister },
];

interface Props {
  table: number;
  changeTable: (number: number) => void;
}

export default function SideBar({ table, changeTable }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.sideBar}>
        <div className={styles.head}>
          <img src={logo} alt="logo" />
        </div>
        {items.map((item, i) => (
          <button
            key={i}
            className={table === i ? styles.selected : ""}
            type="button"
            onClick={() => changeTable(i)}
          >
            <img src={item.icon} alt="users" />
            <span>{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
