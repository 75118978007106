import { PropertySale, SaleFilter } from "../../../interfaces/Rentals";
import { AsyncAction } from "../../../interfaces/Actions";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { auth, db } from "../../../firebase/config";
import {
  DocumentData,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";

export const SET_PROPERTY_SALE = "SET_PROPERTY_SALE";
export const GET_PROPERTIES_SALES = "GET_PROPERTIES_SALES";
export const UPDATE_PROPERTY_SALE = "UPDATE_PROPERTY_SALE";
export const DELETE_PROPERTY_SALE = "DELETE_PROPERTY_SALE";

export const GET_SALE = "GET_SALE";
export const GET_SALE_NEXT_PAGE = "GET_SALE_NEXT_PAGE";
export const SET_SALE_NEXT_PAGE = "SET_SALE_NEXT_PAGE";
export const SET_SALE_PREV_PAGE = "SET_SALE_PREV_PAGE";

export function setPropertySale(propertySale: PropertySale): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const propertyColl = collection(userDoc, "PropertySale");

      const snapshot = await addDoc(propertyColl, propertySale);

      const newProperty = {
        ...propertySale,
        id: snapshot.id,
      };

      dispatch({
        type: SET_PROPERTY_SALE,
        payload: newProperty,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getSaleFirstPage(filters: SaleFilter): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("user is not logged in");

    // To save totals documents in collections
    let totals = 0;

    // User collection references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const propertySaleColl = collection(userDoc, "PropertySale");

    try {
      // Create empty query
      let newQuery = query(propertySaleColl, orderBy("propertyId"));

      // If filter paymentState exist add to query
      if (filters.financing) {
        newQuery = query(
          newQuery,
          where("financing", "==", Boolean(filters.financing))
        );
      }

      // If filter paymentState exist add to query
      if (filters.state) {
        newQuery = query(newQuery, where("state", "==", filters.state));
      }

      // Get total docs
      await getCountFromServer(newQuery).then((data) => {
        totals = data.data().count;
      });

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(5));

      const snapshot = await getDocs(newQuery);

      let propertySale: PropertySale[] = [];
      snapshot.forEach((doc) => {
        propertySale.push({
          id: doc.id,
          ...(doc.data() as PropertySale),
        });
      });

      dispatch({
        type: GET_SALE,
        payload: {
          data: propertySale,
          totals: totals,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getSaleRentNextPage(
  lastDoc?: QuerySnapshot<DocumentData>,
  filters?: SaleFilter
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("user is not logged in");
    if (!lastDoc) throw new Error("last doc not found");

    // User collection references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const propertySaleColl = collection(userDoc, "PropertySale");

    try {
      // Create empty query
      let newQuery = query(propertySaleColl, orderBy("dates.initDate"));

      // If filter paymentState exist add to query
      /*       if (filters?.financing) {
        newQuery = query(newQuery, where("financing", "==", filters.financing));
      } */

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(6), startAfter(lastDoc));

      const snapshot = await getDocs(newQuery);

      let propertySale: PropertySale[] = [];
      snapshot.forEach((doc) => {
        propertySale.push({
          id: doc.id,
          ...(doc.data() as PropertySale),
        });
      });

      dispatch({
        type: GET_SALE_NEXT_PAGE,
        payload: {
          data: propertySale,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function setSaleRentNextPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_SALE_NEXT_PAGE,
    });
  };
}

export function setSaleRentPrevPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_SALE_PREV_PAGE,
    });
  };
}

export function updatePropertySale(propertySale: PropertySale): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const propertyColl = collection(userDoc, "PropertySale");
      const { id, ...updatedProperty } = propertySale;

      await updateDoc(doc(propertyColl, id), updatedProperty);

      dispatch({
        type: UPDATE_PROPERTY_SALE,
        payload: propertySale,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deletePropertySale(propertySale: PropertySale): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const propertyColl = collection(userDoc, "PropertySale");

      await deleteDoc(doc(propertyColl, propertySale.id));

      dispatch({
        type: DELETE_PROPERTY_SALE,
        payload: propertySale,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
