import { auth, db, storage } from "../../../firebase/config";
import { AsyncAction } from "../../../interfaces/Actions";
import { RemoveFiles } from "../../../interfaces/Rentals";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import {
  DocumentData,
  QuerySnapshot,
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  initMonthlyRentFiles,
  MonthlyRentFiles,
  MonthlyRent,
  MonthlyFilter,
} from "../../../interfaces/Registers/Monthly";
import {
  getDownloadURL,
  deleteObject,
  uploadBytes,
  ref,
} from "firebase/storage";

export const SET_MONTHLY_RENT = "SET_MONTHLY_RENT";
export const GET_MONTHLY_RENTS = "GET_MONTHLY_RENTS";
export const UPDATE_MONTHLY_RENT = "UPDATE_MONTHLY_RENT";
export const DELETE_MONTHLY_RENT = "DELETE_MONTHLY_RENT";

export const GET_MONTHLY_RENT = "GET_MONTHLY_RENT";
export const GET_MONTHLY_RENT_NEXT_PAGE = "GET_MONTHLY_RENT_NEXT_PAGE";
export const SET_MONTHLY_RENT_NEXT_PAGE = "SET_MONTHLY_RENT_NEXT_PAGE";
export const SET_MONTHLY_RENT_PREV_PAGE = "SET_MONTHLY_RENT_PREV_PAGE";

export function setMonthlyRent(
  monthlyRent: MonthlyRent,
  files: MonthlyRentFiles
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      // Firestore references
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const monthlyRentColl = collection(userDoc, "MonthlyRent");
      const monthlyDoc = doc(monthlyRentColl);

      // Storage variables
      const filesData: MonthlyRentFiles = initMonthlyRentFiles();

      // Upload the contract files and get ours url
      for (const file of files.contract) {
        const monthlyRentDir = `users/${auth.currentUser.uid}/MonthlyRentFiles/${monthlyDoc.id}/contract/${file.name}`;
        const storageReference = ref(storage, monthlyRentDir);

        // File
        const fileQuery = await uploadBytes(storageReference, file.file!);
        const fileUrl = await getDownloadURL(fileQuery.ref);

        filesData.contract.push({
          name: file.name,
          url: fileUrl,
          path: fileQuery.metadata.fullPath,
        });
      }

      // Upload the 'secure' files and get the URLs
      for (const file of files.secure) {
        const monthlyRentDir = `users/${auth.currentUser.uid}/MonthlyRentFiles/${monthlyDoc.id}/secure/${file.name}`;
        const storageReference = ref(storage, monthlyRentDir);

        // File
        const fileQuery = await uploadBytes(storageReference, file.file!);
        const fileUrl = await getDownloadURL(fileQuery.ref);

        filesData.secure.push({
          name: file.name,
          url: fileUrl,
          path: fileQuery.metadata.fullPath,
        });
      }

      // Add files
      let newMonthlyRent: MonthlyRent = {
        ...monthlyRent,
        files: filesData,
      };

      // Post document
      await setDoc(monthlyDoc, newMonthlyRent);

      // Add id
      newMonthlyRent.id = monthlyDoc.id;

      dispatch({
        type: SET_MONTHLY_RENT,
        payload: newMonthlyRent,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getMonthlyFirstPage(filters: MonthlyFilter): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("user is not logged in");

      // To save totals documents in collections
      let totals = 0;

      // User collection references
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const monthlyColl = collection(userDoc, "MonthlyRent");

      // Create empty query
      let newQuery = query(monthlyColl, orderBy("dates.initDate"));

      // If filter contractType exist add to query
      console.log(filters);

      if (filters.typeOfContract) {
        console.log("typeOfContract");
        newQuery = query(
          newQuery,
          where("typeOfContract", "==", filters.typeOfContract)
        );
      }

      // If filter propertyId exist add to query
      if (filters.propertyId) {
        console.log("propertyId");
        newQuery = query(
          newQuery,
          where("propertyId", "==", filters.propertyId)
        );
      }

      // If filter rented exist add to query
      if (filters.rented) {
        console.log("rented");
        newQuery = query(newQuery, where("rented", "==", filters.rented));
      }

      // Get total docs
      await getCountFromServer(newQuery).then((data) => {
        totals = data.data().count;
      });

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(6));

      const snapshot = await getDocs(newQuery);

      let monthly: any[] = [];
      snapshot.forEach((doc) => {
        monthly.push({
          id: doc.id,
          ...(doc.data() as MonthlyRent),
        });
      });

      monthly = monthly.map((data) => ({
        ...data,
        dates: {
          initDate: (data.dates?.initDate as Timestamp)?.toDate(),
          endDate: (data.dates?.endDate as Timestamp)?.toDate(),
        },
      }));

      dispatch({
        type: GET_MONTHLY_RENT,
        payload: {
          data: monthly,
          totals: totals,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getMonthlyRentNextPage(
  lastDoc?: QuerySnapshot<DocumentData>,
  filters?: MonthlyFilter
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("user is not logged in");
    if (!lastDoc) throw new Error("last doc not found");

    // User collection references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const monthlyColl = collection(userDoc, "MonthlyRent");

    try {
      // Create empty query
      let newQuery = query(monthlyColl, orderBy("dates.initDate"));

      // If filter paymentState exist add to query
      /*       if (filters?.contractType) {
        newQuery = query(
          newQuery,
          where("contractType", "==", filters.contractType)
        );
      } */

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(6), startAfter(lastDoc));

      const snapshot = await getDocs(newQuery);

      let monthly: MonthlyRent[] = [];
      snapshot.forEach((doc) => {
        monthly.push({
          id: doc.id,
          ...(doc.data() as MonthlyRent),
          dates: {
            initDate: (doc.data().dates?.initDate as Timestamp)?.toDate(),
            endDate: (doc.data().dates?.endDate as Timestamp)?.toDate(),
          },
        });
      });

      dispatch({
        type: GET_MONTHLY_RENT_NEXT_PAGE,
        payload: {
          data: monthly,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function setMonthlyRentNextPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_MONTHLY_RENT_NEXT_PAGE,
    });
  };
}

export function setMonthlyRentPrevPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_MONTHLY_RENT_PREV_PAGE,
    });
  };
}

export function updateMonthlyRent(
  monthlyRent: MonthlyRent,
  newFiles?: MonthlyRentFiles,
  filesToRemove?: RemoveFiles
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const monthlyRentColl = collection(userDoc, "MonthlyRent");

      // Storage variables
      const filesData: MonthlyRentFiles = monthlyRent.files;

      // Upload the contract files and get ours url
      if (newFiles) {
        for (const file of newFiles.contract) {
          const monthlyRentDir = `users/${auth.currentUser.uid}/MonthlyRentFiles/${monthlyRent.id}/contract/${file.name}`;
          const storageReference = ref(storage, monthlyRentDir);

          // File
          const fileQuery = await uploadBytes(storageReference, file.file!);
          const fileUrl = await getDownloadURL(fileQuery.ref);

          filesData.contract.push({
            name: file.name,
            url: fileUrl,
            path: fileQuery.metadata.fullPath,
          });
        }

        // Upload the 'secure' files and get the URLs
        for (const file of newFiles.secure) {
          const monthlyRentDir = `users/${auth.currentUser.uid}/MonthlyRentFiles/${monthlyRent.id}/secure/${file.name}`;
          const storageReference = ref(storage, monthlyRentDir);

          // File
          const fileQuery = await uploadBytes(storageReference, file.file!);
          const fileUrl = await getDownloadURL(fileQuery.ref);

          filesData.secure.push({
            name: file.name,
            url: fileUrl,
            path: fileQuery.metadata.fullPath,
          });
        }
      }

      // Delete files
      if (filesToRemove) {
        for (const path of filesToRemove.contract) {
          // Create a reference to the file and d1elete
          const desertRef = ref(storage, path);
          await deleteObject(desertRef);
        }

        filesData.contract = filesData.contract.filter(
          (file) => !filesToRemove.contract.some((path) => file.path === path)
        );

        for (const path of filesToRemove.secure) {
          // Create a reference to the file and delete
          const desertRef = ref(storage, path);
          await deleteObject(desertRef);
        }

        filesData.secure = filesData.secure.filter(
          (file) => !filesToRemove.secure.some((path) => file.path === path)
        );
      }

      // Delete id
      const { id, ...updatedMonthlyRent } = monthlyRent;

      // Add new file data
      updatedMonthlyRent.files = filesData;

      // Post data
      await updateDoc(doc(monthlyRentColl, id), updatedMonthlyRent);

      dispatch({
        type: UPDATE_MONTHLY_RENT,
        payload: {
          ...updatedMonthlyRent,
          id: id,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteMonthlyRent(monthlyRent: MonthlyRent): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const monthlyRentColl = collection(userDoc, "MonthlyRent");

      await deleteDoc(doc(monthlyRentColl, monthlyRent.id));

      dispatch({
        type: DELETE_MONTHLY_RENT,
        payload: monthlyRent,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
