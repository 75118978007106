import { useEffect, useState } from "react";
import AOS from "aos";

import AboutUs from "./AboutUs/AboutUs";
import ContactForm from "./ContactForm/ContactForm";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Navbar from "./Navbar/Navbar";
import Sistem from "./Sistem/Sistem";

import styles from "./Landing.module.css"
import "bootstrap/dist/css/bootstrap.min.css";

export default function Landing() {
  const [opaque, setOpaque] = useState(false);

  useEffect(() => {
    AOS.init();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  function handleScroll() {
    const scrollY = window.scrollY;
    const scrollThreshold = 100; // Puedes ajustar este umbral según tus necesidades

    if (scrollY > scrollThreshold) {
      if (!opaque) setOpaque(true);
    } else {
      if (opaque) setOpaque(false);
    }
  };

  return (
    <div className={styles.landing}>
      <Navbar opaque={opaque} />
      <Home />
      <AboutUs />
      <Sistem />
      <ContactForm />
      <Footer />
    </div>
  );
}
