import { useState, useEffect } from "react";
import { Property } from "../../../../../interfaces/Properties";

import style from "./PropertyRow.module.css";
import calendarSvg from "../../../../../assets/svg/calendar.svg";
import editSvg from "../../../../../assets/svg/edit.svg";
import deleteSvg from "../../../../../assets/svg/delete.svg";
import img from "../../../../.././assets/img/img.png";

interface Props {
  property: Property;
  handleEdit: (property: Property) => void;
  handleView: (propertyId: string) => void;
  handleDelete: (property: Property) => void;
}

export default function PropertyRow({
  property,
  handleEdit,
  handleView,
  handleDelete,
}: Props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!property.registerType) {
      setError(true);
    } else {
      setError(false);
    }
  }, [property]);

  return (
    <tr className={`${style.row} ${error ? style.error : ""}`}>
      <div className={style.imgContainer}>
        <img
          src={property.imgUrl.length > 0 ? property.imgUrl[0].normal.url : img}
          alt="property-img"
        />
      </div>
      <td>{property.name}</td>
      <td>{property.location}</td>
      <td>{property.managment}</td>
      <td>{property.owner?.name}</td>
      <td className={property.registerType?.daily ? style.enable : style.disable}>
        <div></div> Diario
      </td>
      <td className={property.registerType?.monthly ? style.enable : style.disable}>
        <div></div> Mensual
      </td>
      <td className={property.registerType?.sale ? style.enable : style.disable}>
        <div></div> Venta
      </td>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleView(property.id!)}
      >
        <img src={calendarSvg} alt="calendar" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(property)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(property)}
      >
        <img src={deleteSvg} alt="delete" />
      </button>
    </tr>
  );
}
