export interface Property {
  id?: string;
  name: string;
  managment: ManagmentType;
  owner?: Owner;
  location: string;
  type: PropertyType;
  details: {
    measures?: string; // Medidas
    expenses?: boolean; // Expensas
    privateNeighborhood?: boolean; // Barrio privado
    bedrooms?: number; // Habitaciones
    bathrooms?: number; // Baños
    kitchen?: number; // Cocina
    diningRoom?: number; // Comedor
    yard?: number; // Patio
    living?: number;
    garage?: number;
    totalSquereMeters?: number; // Metros cuadrados totales
    covertedSquereMeters?: number; // Metros cuadrados cubiertos
    uncovertedSquereMeters?: number; // Metros cuadrados descubiertos
    hectares?: number; // Hectareas
  };
  maxTenants: number;
  active: boolean;
  imgUrl: PropertyImage[];
  registerType: {
    monthly: boolean;
    daily: boolean;
    sale: boolean;
  };
}

export interface PropertyImage {
  normal: {
    url: string;
    path: string;
  };
  optimized?: {
    url: string;
    path: string;
  };
}

export interface Owner {
  id?: string;
  name: string;
  email: string;
  phone: string;
}

export enum PropertyType {
  ANY = "",
  TERRENO = "Terreno",
  CASA = "Casa",
  DEPARTAMENTO = "Departamento",
  DESARROLLO = "Desarrollo",
  COMECIAL = "Comercial",
  CABANIA = "Cabaña",
  OFICINA = "Oficina",
  GALPON = "Galpón",
  CAMPO = "Campo",
}

export enum ManagmentType {
  Owner = "Propietario",
  Managing = "Administrado",
  Any = "",
}

export interface ErrorProperty {
  managment: string;
  name: string;
  maxTenants: string;
  squereMeters: string;
  location: string;
  ownerName: string;
  type: string;
}

export interface PropertyFilters {
  managment: ManagmentType;
  type: PropertyType;
  registerType: string;
  expenses: boolean | null;
  bedrooms: number | null;
  bathrooms: number | null;
}

export const initPropertyFilters = (): PropertyFilters => ({
  managment: ManagmentType.Any,
  type: PropertyType.ANY,
  registerType: "",
  expenses: null,
  bedrooms: null,
  bathrooms: null,
});

export const initProperty = (): Property => ({
  name: "",
  managment: ManagmentType.Owner,
  location: "",
  active: true,
  maxTenants: 0,
  imgUrl: [],
  type: PropertyType.ANY,
  registerType: {
    monthly: false,
    daily: false,
    sale: false,
  },
  details: {},
});

export const initErrorProperty = (): ErrorProperty => ({
  managment: "",
  name: "",
  maxTenants: "",
  squereMeters: "",
  location: "",
  ownerName: "",
  type: "",
});

export const initOwner = (): Owner => ({
  name: "",
  email: "",
  phone: "",
});

export const typeProperty1 = () => ({
  measures: "",
  expenses: false,
  privateNeighborhood: false,
});

export const typeProperty2 = () => ({
  totalSquereMeters: 0,
  bedrooms: 0,
  bathrooms: 0,
  kitchen: 0,
  diningRoom: 0,
  living: 0,
  yard: 0,
  garage: 0,
  expenses: false,
});

export const typeProperty3 = () => ({
  totalSquereMeters: 0,
  covertedSquereMeters: 0,
  uncovertedSquereMeters: 0,
  bathrooms: 0,
  kitchen: 0,
});

export const typeProperty4 = () => ({
  hectares: 0,
});
