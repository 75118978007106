import { CashRegister } from "../../../../../interfaces/CashRegister";

import style from "./CashRegisterRow.module.css";
import editSvg from "../../../../../assets/svg/edit.svg";
import deleteSvg from "../../../../../assets/svg/delete.svg";
import dateFormat from "../../../../../functions/dateFormat";

interface Props {
  cashRegister: CashRegister;
  handleEdit: (cashRegister: CashRegister) => void;
  handleDelete: (cashRegisterId: string) => void;
}

export default function CashRegisterRow({
  cashRegister,
  handleEdit,
  handleDelete,
}: Props) {
  return (
    <tr className={style.row}>
      <td>{dateFormat(cashRegister.date.toISOString().split("T")[0])}</td>
      <td><b>{cashRegister.concept}</b></td>
      <td>{cashRegister.acount}</td>
      <td>{cashRegister.money.income !== 0 ? `+$${cashRegister.money.income}` : "$0"}</td>
      <td>{cashRegister.money.expense !== 0 ? `-$${cashRegister.money.expense}` : "$0"}</td>
      <td>${Number(cashRegister.money.income) - Number(cashRegister.money.expense)}</td>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(cashRegister)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(cashRegister.id!)}
      >
        <img src={deleteSvg} alt="delete" />
      </button>
    </tr>
  );
}
