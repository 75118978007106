import { Property, initPropertyFilters } from "../../interfaces/Properties";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { useEffect, useState } from "react";
import {
  setProperty,
  getProperties,
  updateProperty,
  deleteProperty,
} from "../../redux/actions/property";
import swal from "sweetalert";

interface Page {
  data: Property[];
  current: number;
  total: number;
  totalDocs: number;
  limit: number;
}

const initPage = (): Page => ({
  data: [],
  current: 1,
  total: 0,
  totalDocs: 0,
  limit: 6,
});

export default function useProperty() {
  const dispatch = useDispatch();
  const properties = useSelector((state: RootState) => state.properties);
  const [filters, setFilters] = useState(initPropertyFilters());
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<Page>(initPage());

  useEffect(() => {
    setFirstPage();
  }, [properties, filters]);

  // Handle error
  async function error(promise: Promise<void>) {
    setLoading(true);
    return promise
      .then(() => setLoading(false))
      .catch((err: Error) => {
        console.log(err);
        setLoading(false);
        swal("Error", "Ocurrió un error, intentelo mas tarde", "error");
      });
  }

  // Get data of the page selected
  function setPageData(pageNumber: number) {
    const from = (pageNumber - 1) * page.limit;
    const to = from + page.limit;
    const currentData = properties
      .filter((property) => {
        if (
          filters.bathrooms &&
          filters.bathrooms !== property.details.bathrooms
        )
          return false;

        if (filters.bedrooms && filters.bedrooms !== property.details.bedrooms)
          return false;

        if (filters.expenses && filters.expenses !== property.details.expenses)
          return false;

        if (filters.managment && filters.managment !== property.managment)
          return false;

        if (
          filters.registerType === "daily" &&
          property.registerType.daily !== true
        )
          return false;

        if (
          filters.registerType === "monthly" &&
          property.registerType.monthly !== true
        )
          return false;

        if (
          filters.registerType === "sale" &&
          property.registerType.sale !== true
        )
          return false;

        if (filters.type && filters.type === property.type) return true;

        if (filters.type && filters.type) return false;
        return true;
      })
      .slice(from, to);

    setPage({
      ...page,
      data: currentData,
      current: pageNumber,
      total: Math.ceil(properties.length / page.limit),
      totalDocs: page.limit,
    });
  }

  async function addPropertyData(
    property: Property,
    images: File[]
  ): Promise<void> {
    return await error(dispatch<any>(setProperty(property, images)));
  }

  const getAllProperties = async (): Promise<void> => {
    error(dispatch<any>(getProperties()));
  };

  const setFirstPage = () => setPageData(1);
  const setNextPage = () =>
    page.current < page.total && setPageData(page.current + 1);
  const setPrevPage = () => page.current > 1 && setPageData(page.current - 1);

  async function updatePropertyData(
    updatedData: Property,
    newImages?: File[],
    imagesToRemove?: string[]
  ) {
    return await error(
      dispatch<any>(updateProperty(updatedData, newImages, imagesToRemove))
    );
  }

  async function deletePropertyData(property: Property): Promise<void> {
    return await error(dispatch<any>(deleteProperty(property)));
    // Eliminar todos los registros
  }

  async function disableProperty(property: Property): Promise<void> {
    return await error(
      dispatch<any>(updateProperty({ ...property, active: false }))
    );
  }

  async function enableProperty(property: Property): Promise<void> {
    return await error(
      dispatch<any>(updateProperty({ ...property, active: true }))
    );
  }

  async function resetFilters() {
    setFilters(initPropertyFilters());
  }

  return {
    data: page.data,
    totalsDocs: page.totalDocs,
    filters: {
      data: filters,
      reset: resetFilters,
      setFilters: setFilters,
    },
    page: {
      current: page.current,
      totals: page.total,
      get: getAllProperties,
      firstPage: setFirstPage,
      prevPage: setPrevPage,
      nextPage: setNextPage,
    },
    loading: loading,
    add: addPropertyData,
    update: updatePropertyData,
    delete: deletePropertyData,
    disabled: disableProperty,
    active: enableProperty,
  };
}
