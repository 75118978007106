import { useState, useEffect } from "react";
import useLogin from "../../hooks/useLogin";

import SideBar from "./SideBar/SideBar";

import Navbar from "./Navbar/Navbar";
import PropertyTable from "./Tables/Property/PropertyTable";
import Rentals from "./Tables/Rentals/Rentals";
import HistoryTable from "./Tables/History/HistoryTable";
import CashRegisterTable from "./Tables/CashRegister/CashRegisterTable";

import styles from "./Dashboard.module.css";
import Profile from "../Profile/Profile";

export default function Dashboard() {
  const login = useLogin();
  const [table, setTable] = useState<number>(0);
  const [profile, setProfile] = useState<boolean>(false);

  useEffect(() => {
    login.autoLogin();
  }, []);

  function changeTable(table: number) {
    setTable(table);
  }

  function handleProfile() {
    setProfile(!profile);
    console.log(profile);
  }

  return (
    <div className={styles.dahsboard}>
      {profile && <Profile handleClose={handleProfile} />}
      <SideBar table={table} changeTable={changeTable} />
      <div className={styles.container}>
        <Navbar table={table} handleProfile={handleProfile} />
        {table === 0 ? <PropertyTable /> : null}
        {table === 1 ? <Rentals /> : null}
        {table === 2 ? <HistoryTable /> : null}
        {table === 3 ? <CashRegisterTable /> : null}
      </div>
    </div>
  );
}
