interface Filter {
  year: string;
  month: string | null;
  day: string | null;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
}

export default function getDateRange(filter: Filter): DateRange {
  const startDate = new Date(Number(filter.year), 0, 1); // Primer día del año
  const endDate: Date = new Date();

  if (filter.month) {
    const month = Number(filter.month) - 1;
    startDate.setMonth(month);
  }

  if (filter.day) {
    const day = Number(filter.day);
    startDate.setDate(day);
  }

  if (filter.month) {
    endDate.setFullYear(Number(filter.year));
    endDate.setMonth(startDate.getMonth() + 1);
    endDate.setDate(0);
  } else {
    endDate.setFullYear(Number(filter.year));
    endDate.setMonth(11);
  }

  return {
    startDate,
    endDate,
  };
}
