import { AnyAction } from "redux";
import {
  SET_HISTORY,
  GET_HISTORY,
  UPDATE_HISTORY,
  DELETE_HISTORY,
} from "../actions/history";
import { LOGOUT } from "../actions/login";
import { History } from "../../interfaces/History";
import { initState } from "../../interfaces/RootState";
import { DELETE_DAILY_RENT } from "../actions/rentals/dailyRent";
import { DELETE_MONTHLY_RENT } from "../actions/rentals/monthlyRentals";
import { DELETE_PROPERTY_SALE } from "../actions/rentals/propertySale";

const historyReducer = (state = initState().history, action: AnyAction) => {
  switch (action.type) {
    case SET_HISTORY:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case GET_HISTORY:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_HISTORY:
      return {
        ...state,
        data: state.data.map((history: History) =>
          history.id === action.payload.id ? { ...history, ...action.payload } : history
        ),
      };

    case DELETE_HISTORY:
      return {
        ...state,
        data: state.data.filter(
          (history: History) => history.id !== action.payload
        ),
      };

    case DELETE_DAILY_RENT:
      return {
        ...state,
        data: state.data.filter(
          (history: History) => history.id !== action.payload.historyId
        ),
      };

    case DELETE_MONTHLY_RENT:
      return {
        ...state,
        data: state.data.filter(
          (history: History) => history.id !== action.payload.historyId
        ),
      };

    case DELETE_PROPERTY_SALE:
      return {
        ...state,
        data: state.data.filter(
          (history: History) => history.id !== action.payload.historyId
        ),
      };

    case LOGOUT:
      return state;

    default:
      return state;
  }
};

export default historyReducer;
