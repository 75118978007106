import { RootState } from "../../../interfaces/RootState";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../redux/actions/login";

import styles from "./Navbar.module.css";
import userSvg from "../../../assets/svg/user.svg";
import emailSvg from "../../../assets/svg/menu/email.svg";
import passSvg from "../../../assets/svg/menu/password.svg";
import logoutSvg from "../../../assets/svg/menu/logout.svg";

interface Props {
  table: number;
  handleProfile: () => void;
}

export default function Navbar({ table, handleProfile }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const redirect = useNavigate();

  function handleLogout() {
    dispatch<any>(logOut());
    redirect("/login");
  }

  return (
    <nav className={styles.nav}>
      <h2>
        {table === 0 ? "Propiedades" : null}
        {table === 1 ? "Registros" : null}
        {table === 2 ? "Historial" : null}
        {table === 3 ? "Caja" : null}
      </h2>
      <div className={styles.profile}>
        <div className={styles.userImg}>
          <img src={user.profileImg || userSvg} alt="user" />
        </div>
        <ul className={styles.menu}>
          <li>
            <b>Perfil</b>
          </li>
          <li>{user.name}</li>
          <li onClick={handleProfile}>
            <img src={userSvg} alt="email" /> <span>Perfil</span>
          </li>
          <li>
            <img src={emailSvg} alt="email" /> <span>Cambiar correo</span>
          </li>
          <li>
            <img src={passSvg} alt="password" /> <span>Cambiar contraseña</span>
          </li>
          <li onClick={handleLogout}>
            <img src={logoutSvg} alt="logout" /> <span>Cerrar sesion</span>
          </li>
        </ul>
      </div>
    </nav>
  );
}
