import { ThunkAction } from "redux-thunk";
import { RootState } from "../../../interfaces/RootState";
import { AnyAction, Dispatch } from "redux";
import { auth, db, storage } from "../../../firebase/config";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { User } from "../../../interfaces/users";
import {
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_EMAIL = "UPDATE_EMAIL";

export function getUser(): ThunkAction<
  Promise<void>,
  RootState,
  null,
  AnyAction
> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");

      // Get user data
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const userData = (await getDoc(userDoc))?.data();

      dispatch({
        type: GET_USER,
        payload: userData,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateUser(
  userData: User,
  file: File | null
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");

      const data = userData;

      if (file) {
        // Save logo'
        const dir = `users/${auth.currentUser.uid}/logo`;
        const storageReference = storageRef(storage, dir);
        const imageQuery = await uploadBytes(storageReference, file);

        // GET invoice image url
        const imageUrl = await getDownloadURL(imageQuery.ref);
        data.profileImg = imageUrl;
      }

      // Save user data
      const userColl = collection(db, "Users");
      await updateDoc(doc(userColl, auth.currentUser.uid), { ...data });

      dispatch({
        type: UPDATE_USER,
        payload: userData,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function changePassword(
  newPassword: string,
  curretnPassword: string,
  user: User
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");
      await signInWithEmailAndPassword(auth, user.email, curretnPassword);
      await updatePassword(auth.currentUser, newPassword);
      dispatch({
        type: UPDATE_PASSWORD,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function changeEmail(
  newEmail: string,
  curretnPassword: string,
  user: User
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");
      await signInWithEmailAndPassword(auth, user.email, curretnPassword);
      await updateEmail(auth.currentUser, newEmail);
      const userCol = collection(db, "Users");
      const userDoc = doc(userCol, auth.currentUser.uid);

      updateDoc(userDoc, { email: newEmail });

      dispatch({
        type: UPDATE_EMAIL,
        payload: newEmail,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
