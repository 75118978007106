import { AsyncAction } from "../../../interfaces/Actions";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { DailyFilter, DailyRent } from "../../../interfaces/Rentals";
import { auth, db } from "../../../firebase/config";
import {
  doc,
  limit,
  where,
  query,
  addDoc,
  orderBy,
  getDocs,
  updateDoc,
  deleteDoc,
  collection,
  startAfter,
  DocumentData,
  QuerySnapshot,
  getCountFromServer,
  Timestamp,
} from "firebase/firestore";

export const SET_DAILY_RENT = "SET_DAILY_RENT";
export const GET_DAILY_RENTS = "GET_DAILY_RENTS";
export const UPDATE_DAILY_RENT = "UPDATE_DAILY_RENT";
export const DELETE_DAILY_RENT = "DELETE_DAILY_RENT";

export const GET_DAILY_RENT = "GET_DAILY_RENT";
export const GET_DAILY_RENT_NEXT_PAGE = "GET_DAILY_RENT_NEXT_PAGE";
export const SET_DAILY_RENT_NEXT_PAGE = "SET_DAILY_RENT_NEXT_PAGE";
export const SET_DAILY_RENT_PREV_PAGE = "SET_DAILY_RENT_PREV_PAGE";

export function setDailyRent(dailyRent: DailyRent): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const dailyRentColl = collection(userDoc, "DailyRent");

      const snapshot = await addDoc(dailyRentColl, dailyRent);

      const newDailyRent = {
        ...dailyRent,
        id: snapshot.id,
      };

      dispatch({
        type: SET_DAILY_RENT,
        payload: newDailyRent,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getDailyFirstPage(filters: DailyFilter): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("user is not logged in");

    // To save totals documents in collections
    let totals = 0;

    // User collection references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const dailyRentColl = collection(userDoc, "DailyRent");

    try {
      // Create empty query
      let newQuery = query(dailyRentColl, orderBy("dates.initDate"));

      // If filter month exist add to query
      if (filters?.month) {
        // Para buscar en initDate
        newQuery = query(
          newQuery,
          where("dates.initDate", ">=", new Date().setMonth(filters.month - 1, 1)),
          where("dates.initDate", "<", new Date().setMonth(filters.month, 1))
        );
      }

      // If filter paymentState exist add to query
      if (filters.paymentState) {
        newQuery = query(
          newQuery,
          where("paymentState", "==", filters.paymentState)
        );
      }

      // If filter propertyId exist add to query
      if (filters.propertyId) {
        newQuery = query(
          newQuery,
          where("propertyId", "==", filters.propertyId)
        );
      }

      // If filter paymentState exist add to query
      if (filters.reservationChannel) {
        newQuery = query(
          newQuery,
          where("reservationChannel", "==", filters.reservationChannel)
        );
      }

      // Get total docs
      await getCountFromServer(newQuery).then((data) => {
        totals = data.data().count;
      });

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(5));

      const snapshot = await getDocs(newQuery);

      let properties: any[] = [];
      snapshot.forEach((doc) => {
        properties.push({
          id: doc.id,
          ...(doc.data() as DailyRent),
        });
      });

      // Covnvert Timestamp to Date
      properties = properties.map((data) => ({
        ...data,
        dates: {
          initDate: data.dates.initDate.toDate(),
          endDate: data.dates.endDate.toDate(),
        },
      }));

      dispatch({
        type: GET_DAILY_RENT,
        payload: {
          data: properties,
          totals: totals,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getDailyRentNextPage(
  lastDoc?: QuerySnapshot<DocumentData>,
  filters?: DailyFilter
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("user is not logged in");
    if (!lastDoc) throw new Error("last doc not found");

    // User collection references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const dailyRentColl = collection(userDoc, "DailyRent");

    try {
      // Create empty query
      let newQuery = query(dailyRentColl, orderBy("dates.initDate"));

      // If filter paymentState exist add to query
      if (filters?.paymentState) {
        newQuery = query(
          newQuery,
          where("paymentState", "==", filters.paymentState)
        );
      }

      // Create query to get 6 docs
      newQuery = query(newQuery, limit(6), startAfter(lastDoc));

      const snapshot = await getDocs(newQuery);

      let daily: DailyRent[] = [];
      snapshot.forEach((doc) => {
        daily.push({
          ...(doc.data() as DailyRent),
          id: doc.id,
          dates: {
            initDate: (doc.data().dates?.initDate as Timestamp)?.toDate(),
            endDate: (doc.data().dates?.endDate as Timestamp)?.toDate(),
          },
        });
      });

      dispatch({
        type: GET_DAILY_RENT_NEXT_PAGE,
        payload: {
          data: daily,
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function setDailyRentNextPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_DAILY_RENT_NEXT_PAGE,
    });
  };
}

export function setDailyRentPrevPage() {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: SET_DAILY_RENT_PREV_PAGE,
    });
  };
}

export function updateDailyRent(dailyRent: DailyRent): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const dailyRentColl = collection(userDoc, "DailyRent");
      const { id, ...updatedDailyRent } = dailyRent;

      await updateDoc(doc(dailyRentColl, id), updatedDailyRent);

      dispatch({
        type: UPDATE_DAILY_RENT,
        payload: dailyRent,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteDailyRent(dailyRent: DailyRent): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("User logged in");

      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const dailyRentColl = collection(userDoc, "DailyRent");

      await deleteDoc(doc(dailyRentColl, dailyRent.id));

      dispatch({
        type: DELETE_DAILY_RENT,
        payload: dailyRent,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
