import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import loadingReducer from "./loadingReducer";
import userReducer from "./userReducer";
import propertyReducer from "./propertyReducer";
import historyReducer from "./historyReducer";
import monthlyRentReducer from "./monthlyRentReducer";
import dailyRentReducer from "./dailyRentReducer";
import propertySalesReducer from "./propertySalesReducer";
import cashRegisterReducer from "./cashRegisterReducer";

const rootReducer = combineReducers({
  loading: loadingReducer,
  login: loginReducer,
  user: userReducer,
  properties: propertyReducer,
  monthlyRentals: monthlyRentReducer,
  dailyRentals: dailyRentReducer,
  propertiesSale: propertySalesReducer,
  history: historyReducer,
  cashRegister: cashRegisterReducer,
});

export default rootReducer;
