import { User, UserRol } from "./users";

export interface LoginData {
  email: string;
  password: string;
}

export interface SigninData extends User {
  repeatPassword: string;
}

export interface SigninError {
  name: string;
  profileImg: string;
  cuitCuil: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword: string;
}

export const initLogin = (): LoginData => ({
  email: "",
  password: "",
});

export const initSignin = (): SigninData => ({
  name: "",
  profileImg: "",
  cuitCuil: 0,
  email: "",
  phone: "",
  rol: UserRol.Admin,
  password: "",
  repeatPassword: "",
});

export const initSigninError = (): SigninError => ({
  name: "",
  profileImg: "",
  cuitCuil: "",
  email: "",
  phone: "",
  password: "",
  repeatPassword: "",
});
