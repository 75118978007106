import { closeLoading, openLoading } from "../../../../redux/actions/loading";
import { useEffect, useState } from "react";
import { deleteProperty } from "../../../../redux/actions/property";
import { useDispatch } from "react-redux";
import { Property } from "../../../../interfaces/Properties";
import useProperty from "../../../../hooks/useProperty";
import swal from "sweetalert";

import Filters from "./Filters/Filters";
import PropertyRow from "./PropertyRow/PropertyRow";
import PropertyForm from "./PropertyForm/PropertyForm";
import FullCalendarView from "../../../../component/FullCalendarView/FullCalendarView";

import styles from "./PropertyTable.module.css";
import loadingSvg from "../../../../assets/img/loading.gif";

export default function PropertyTable() {
  const dispatch = useDispatch();
  const { data, totalsDocs, filters, page, loading, ...properties } =
    useProperty();

  const [dataEdit, setDataEdit] = useState<Property | undefined>();
  const [dataView, setDataView] = useState<string>("");
  const [form, setForm] = useState(false);

  useEffect(() => {
    if (data.length <= 0) {
      setTimeout(() => {
        page.get();
      }, 1500);
    }
  }, []);

  function handleView(propertyId?: string) {
    setDataView(propertyId || "");
  }

  function handleEdit(data: Property) {
    setDataEdit(data);
    handleClose();
  }

  function handleClose() {
    setForm(!form);
    if (form) {
      setDataEdit(undefined);
    }
  }

  function handleSaveProperty(
    property: Property,
    imagestoUpload: File[],
    imagesToRemove: string[],
    edit: boolean
  ) {
    dispatch(openLoading());
    (edit
      ? properties.update(property, imagestoUpload, imagesToRemove)
      : properties.add(property, imagestoUpload)
    )
      .then(() => {
        dispatch(closeLoading());
        handleClose();
        swal("Guardado", "Se guardo el property correctamente", "success");
      })
      .catch((err: any) => {
        dispatch(closeLoading());
        swal("Error", "No se pudo guardar el property", "error");
        console.log(err);
      });
  }

  function handleDelete(property: Property) {
    swal({
      text: "¿Seguro desea eliminar este property?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: any) => {
      if (response === "Si") {
        dispatch(openLoading());
        dispatch<any>(deleteProperty(property))
          .then(() => {
            dispatch(closeLoading());
            swal(
              "Eliminado",
              "Se elimino el property correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el property", "error");
            console.log(err);
          });
      }
    });
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {form ? (
        <PropertyForm
          handleClose={handleClose}
          handleSubmit={handleSaveProperty}
          data={dataEdit}
        />
      ) : null}
      {dataView ? (
        <FullCalendarView propertyId={dataView} handleClose={handleView} />
      ) : null}
      <header>
        <div className={styles.controls}>
          <Filters
            filters={filters.data}
            setFilters={filters.setFilters}
            handleSubmit={page.firstPage}
            handleReset={filters.reset}
          />
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleClose}
          >
            + Nueva propiedad
          </button>
        </div>
      </header>
      <div className={styles.table}>
        {loading && (
          <div className={styles.loading}>
            <img src={loadingSvg} alt="loading" />
          </div>
        )}
        <div className={`${styles.row} ${styles.firstRow}`}>
          <span>Imagenes</span>
          <span>Nombre de la propiedad</span>
          <span>Ubicacion</span>
          <span>Propietario / Administrado</span>
          <span>Nombre del propietario</span>
          <span>Clasificacion</span>
          <span>Actions</span>
        </div>
        <div className={styles.body}>
          {data?.length <= 0 ? (
            <tr className={styles.emptyRows}>
              <th>No hay propiedades</th>
            </tr>
          ) : (
            data?.map((property: Property) => (
              <PropertyRow
                key={property.id}
                property={property}
                handleEdit={handleEdit}
                handleView={handleView}
                handleDelete={handleDelete}
              />
            ))
          )}
        </div>
        <div className={styles.pagination}>
          <span>{totalsDocs} Propiedades</span>
          <button
            className={page.current <= 1 ? "" : styles.btnActive}
            type="button"
            onClick={page.prevPage}
            disabled={page.current <= 1}
          >{`<`}</button>
          <span>{`${page.current} de ${page.totals}`}</span>
          <button
            type="button"
            onClick={page.nextPage}
            disabled={page.current >= page.totals}
          >{`>`}</button>
        </div>
      </div>
    </div>
  );
}
