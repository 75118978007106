import { History } from "../../interfaces/History";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import {
  deleteHistory,
  getHistory,
  setHistory,
  updateHistory,
} from "../../redux/actions/history";
import { closeLoading } from "../../redux/actions/loading";
import { collection, doc } from "firebase/firestore";
import { auth, db } from "../../firebase/config";
import getDateRange from "../../functions/getDateRange";

interface Filter {
  year: string | undefined;
  month: string | undefined | null;
  day: string | undefined | null;
}

export default function useHistory() {
  const dispatch = useDispatch();
  const history = useSelector((state: RootState) => state.history);

  async function addHistoryItem(historyId: string, history: History) {
    return await dispatch<any>(setHistory(historyId, history)).catch(
      (error: Error) => {
        dispatch(closeLoading());
      }
    );
  }

  async function getHistoryItems({ year, month, day }: Filter) {
    const currentYear = year || new Date().getFullYear().toString();
    const currentMonth = month || (new Date().getMonth() + 1).toString();
    const dates = getDateRange({
      year: currentYear,
      month: currentMonth,
      day: day || null,
    });

    return await dispatch<any>(getHistory(dates.startDate, dates.endDate));
  }

  async function updateHistoryItem(history: any) {
    return await dispatch<any>(updateHistory(history));
  }

  async function deleteHistoryItem(historyId: string) {
    return await dispatch<any>(deleteHistory(historyId));
  }

  function generateId() {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");
    const historyDoc = doc(historyColl);

    return historyDoc.id;
  }

  return {
    list: history.data,
    fullCalendar: history.fullCallendar,
    add: addHistoryItem,
    get: getHistoryItems,
    update: updateHistoryItem,
    delete: deleteHistoryItem,
    generateId,
  };
}
