import { useSelector } from "react-redux";
import { RootState } from "../../../../../../interfaces/RootState";
import { useState } from "react";
import {
  MonthRented,
  MonthlyFilter,
} from "../../../../../../interfaces/Registers/Monthly";

import SelectInput from "../../../../../../component/Inputs/SelectInput";

import style from "./Filter.module.css";
import filterSvg from "../../../../../../assets/svg/filter.svg";

interface Props {
  filters: MonthlyFilter;
  setFilters: (filters: MonthlyFilter) => void;
  handleSubmit: () => void;
  handleReset: () => void;
}

export default function Filters({
  filters,
  setFilters,
  handleSubmit,
  handleReset,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);
  const [open, setOpen] = useState<boolean>(false);

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <div className={style.filter}>
      <button className={style.btnFilter} type="button" onClick={handleOpen}>
        <span>Filtros</span>
        <img src={filterSvg} alt="filtros" />
      </button>
      {open ? (
        <div className={style.filterContainer}>
          <SelectInput
            name="propertyId"
            label="Propiedad"
            list={properties.map((property) => ({
              label: property.name,
              value: property.id!,
            }))}
            value={filters.propertyId}
            handleChange={handleChange}
          />
          <SelectInput
            name="rented"
            label="Alquilado"
            list={[
              { label: MonthRented.SI, value: MonthRented.SI },
              { label: MonthRented.NO, value: MonthRented.NO },
            ]}
            value={filters.rented}
            handleChange={handleChange}
          />
          <SelectInput
            name="typeOfContract"
            label="Tipo de contrato"
            list={[
              { label: "Ley de Alquileres", value: "LEY_DE_ALQUILERES" },
              { label: "Comercial", value: "COMERCIAL" },
              { label: "Comodato", value: "COMODATO" },
            ]}
            value={filters.typeOfContract}
            handleChange={handleChange}
          />
          <div className={style.contentBtn}>
            <button
              className="btn btn-success"
              type="button"
              onClick={handleSubmit}
            >
              Aplicar
            </button>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleReset}
            >
              Borrar
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
