import { AnyAction } from "redux";
import {
  SET_CASH_REGISTER,
  GET_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
  DELETE_CASH_REGISTER,
  UPDATE_ACOUNTS,
  GET_ACOUNTS,
} from "../actions/cashRegister";
import { LOGOUT } from "../actions/login";
import { CashRegister } from "../../interfaces/CashRegister";
import { initState } from "../../interfaces/RootState";

const cashRegisterReducer = (
  state = initState().cashRegister,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_CASH_REGISTER:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case GET_CASH_REGISTER:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_CASH_REGISTER:
      return {
        ...state,
        data: state.data.map((cashRegister: CashRegister) =>
          cashRegister.id === action.payload.id ? action.payload : cashRegister
        ),
      };

    case DELETE_CASH_REGISTER:
      return {
        ...state,
        data: state.data.filter(
          (cashRegister: CashRegister) => cashRegister.id !== action.payload
        ),
      };

    case GET_ACOUNTS:
      return {
        ...state,
        acounts: action.payload,
      };

    case UPDATE_ACOUNTS:
      return {
        ...state,
        acounts: action.payload,
      };

    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default cashRegisterReducer;
