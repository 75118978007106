import { useEffect, useState } from "react";
import {
  ErrorCashRegister,
  CashRegister,
  initErrorCashRegister,
  initCashRegister,
} from "../../../../../interfaces/CashRegister";

import style from "./CashRegisterForm.module.css";
import Input from "../../../../../component/Inputs/Input";
import SelectInput from "../../../../../component/Inputs/SelectInput";

interface Props {
  handleClose: () => void;
  handleSubmit: (cashRegister: CashRegister, edit: boolean) => void;
  data: CashRegister | undefined;
  acounts: string[];
}

export default function CashRegisterForm({
  handleClose,
  handleSubmit,
  data,
  acounts,
}: Props) {
  const [cashRegister, setCashRegister] = useState<CashRegister>(initCashRegister());
  const [error, setError] = useState<ErrorCashRegister>(initErrorCashRegister());

  useEffect(() => {
    if (data) setCashRegister(data);
  }, [data]);

  function handlelocalSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(cashRegister, data ? true : false);
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const name = event.target.name;
    const value = event.target.value;
    const type = event.target.type;

    if (type === "date")
      setCashRegister({ ...cashRegister, [name]: new Date(`${value}:00:00:00`) });
    else
      setCashRegister({ ...cashRegister, [name]: value });

    setError({ ...error, [name]: "" });
  }

  function handleChangeMoney(event: React.ChangeEvent<HTMLInputElement>) {
    setCashRegister({
      ...cashRegister,
      money: {
        ...cashRegister.money,
        [event.target.name]: event.target.value,
      },
    });
  }

  function handleLocalClose() {
    handleClose();
  }

  function validations() {
    let errors = initErrorCashRegister();
    let value = true;

    if (cashRegister.acount === "") {
      errors.acount = "Debes ingresar una cuenta";
      value = false;
    }

    if (cashRegister.date === null) {
      errors.date = "Debes ingresar una fecha";
      value = false;
    }

    if (cashRegister.concept === "") {
      errors.concept = "Debes ingresar un concepto";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={style.background}>
      <form className={`toTop ${style.form}`} onSubmit={handlelocalSubmit}>
        <div className={style.close}>
          <h3>Agregar movimiento</h3>
          <div className="btn-close" onClick={handleLocalClose} />
        </div>
        <div className={style.flex}>
          <SelectInput
            name="acount"
            label="Tipo de transaccion"
            value={cashRegister.acount}
            list={acounts.map((item) => ({ value: item, label: item }))}
            error={error.acount}
            handleChange={handleChange}
          />
          <Input
            name="date"
            label="Fecha"
            type="date"
            value={cashRegister.date?.toISOString().split("T")[0]}
            error={error.date}
            handleChange={handleChange}
          />
          <Input
            name="concept"
            label="Concepto"
            value={cashRegister.concept}
            error={error.concept}
            handleChange={handleChange}
          />
          <Input
            name="expense"
            label="Salida"
            type="number"
            value={cashRegister.money.expense}
            error={error.moneyExpense}
            handleChange={handleChangeMoney}
          />
          <Input
            name="income"
            label="Entrada"
            type="number"
            value={cashRegister.money.income}
            error={error.moneyExpense}
            handleChange={handleChangeMoney}
          />
          <button type="submit" className="btn btn-success">
            Agregar movimiento
          </button>
        </div>
      </form>
    </div>
  );
}
