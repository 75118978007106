import { CashRegister } from "../../interfaces/CashRegister";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { closeLoading } from "../../redux/actions/loading";
import {
  deleteCashRegister,
  getCashRegister,
  getAcounts,
  setCashRegister,
  updateCashRegister,
} from "../../redux/actions/cashRegister";
import getDateRange from "../../functions/getDateRange";

interface Filter {
  year: string;
  month: string | undefined | null;
  day: string | undefined | null;
}

export default function useCashRegister() {
  const dispatch = useDispatch();
  const cashRegister = useSelector(
    (state: RootState) => state.cashRegister.data
  );

  async function addRegister(register: CashRegister) {
    return await dispatch<any>(setCashRegister(register)).catch(
      (error: Error) => {
        dispatch(closeLoading());
        console.log(error.message);
      }
    );
  }

  async function getRegisters({ year, month, day }: Filter) {

    const dates = getDateRange({
      year: year,
      month: month || null,
      day: day || null,
    });

    return await Promise.all([
      dispatch<any>(getCashRegister(dates.startDate, dates.endDate)),
      dispatch<any>(getAcounts()),
    ]).catch((error: Error) => {
      dispatch(closeLoading());
      console.log(error.message);
    });
  }

  async function updateRegister(register: CashRegister) {
    return await dispatch<any>(updateCashRegister(register)).catch(
      (error: Error) => {
        dispatch(closeLoading());
        console.log(error.message);
      }
    );
  }

  async function deleteRegister(registerId: string) {
    return await dispatch<any>(deleteCashRegister(registerId)).catch(
      (error: Error) => {
        dispatch(closeLoading());
        console.log(error.message);
      }
    );
  }

  return {
    list: cashRegister,
    add: addRegister,
    get: getRegisters,
    update: updateRegister,
    delete: deleteRegister,
  };
}
