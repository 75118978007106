import { History } from "../../../../../interfaces/History";

import style from "./HistoryRow.module.css";
import viewSvg from "../../../../../assets/svg/view.svg";
import dateFormat from "../../../../../functions/dateFormat";

interface Props {
  history: History;
  handleView: (propertyId?: string) => void;
}

export default function HistoryRow({ history, handleView }: Props) {
  return (
    <tr className={style.row}>
      <td>
        {dateFormat(history.admissionDate?.toISOString().split("T")[0] || "")}
      </td>
      {/*       <td><b>{history.propertyName}</b></td> */}
      <td>{history.tenantName || "-"}</td>
      <td>{history.type}</td>
      <td>
        {history.rentalDates
          ? `${history.rentalDates.initDate?.toISOString().split("T")[0]} a ${
              history.rentalDates.endDate?.toISOString().split("T")[0]
            }`
          : "-"}
      </td>
      <td>$ {history.price}</td>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleView(history.propertyId)}
      >
        <img src={viewSvg} alt="edit" />
      </button>
    </tr>
  );
}
