import { PropertySale } from "../../../../../../interfaces/Rentals";
import { RootState } from "../../../../../../interfaces/RootState";
import { useSelector } from "react-redux";

import style from "./PropertySaleRow.module.css";
import editSvg from "../../../../../../assets/svg/edit.svg";
import calendarSvg from "../../../../../../assets/svg/calendar.svg";
import deleteSvg from "../../../../../../assets/svg/delete.svg";

interface Props {
  propertySale: PropertySale;
  handleEdit: (propertySale: PropertySale) => void;
  handleView: (propertyId: string) => void;
  handleDelete: (propertySale: PropertySale) => void;
}

export default function PropertySaleRow({
  propertySale,
  handleEdit,
  handleView,
  handleDelete,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);

  return (
    <tr className={style.row}>
      <td>
        {properties.find((property) => property.id === propertySale.propertyId)
          ?.name || ""}
      </td>
      <td>${propertySale.basePrice}</td>
      <td>${propertySale.currentPrice}</td>
      <td>{propertySale.state}</td>
      <td>{propertySale.permuta ? "Si" : "No"} </td>
      <td>{propertySale.financiacion ? "Si" : "NO"} </td>
      <td>%{propertySale.managmentFees} </td>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleView(propertySale.id!)}
      >
        <img src={calendarSvg} alt="calendar" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(propertySale)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(propertySale)}
      >
        <img src={deleteSvg} alt="delete" />
      </button>
    </tr>
  );
}
