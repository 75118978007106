import { DailyRent } from "../../../../../../interfaces/Rentals";
import { Property } from "../../../../../../interfaces/Properties";

import style from "./DailyRentRow.module.css";
import editSvg from "../../../../../../assets/svg/edit.svg";
import calendarSvg from "../../../../../../assets/svg/calendar.svg";
import deleteSvg from "../../../../../../assets/svg/delete.svg";
import houseSvg from "../../../../../../assets/svg/house.svg";
import dateFormat from "../../../../../../functions/dateFormat";

interface Props {
  dailyRent: DailyRent;
  property: Property | undefined;
  handleEdit: (dailyRent: DailyRent) => void;
  handleView: (propertyId: string) => void;
  handleDelete: (dailyRent: DailyRent) => void;
}

export default function DailyRentRow({
  dailyRent,
  property,
  handleEdit,
  handleView,
  handleDelete,
}: Props) {
  return (
    <div className={style.row}>
      <div className={style.imgContainer}>
        <img
          src={
            property && property?.imgUrl?.length > 0
              ? property?.imgUrl[0].normal.url
              : houseSvg
          }
          alt="property-img"
        />
      </div>
      <span>{property?.name}</span>
      <span>{dailyRent.reservationChannel}</span>
      <span>{dailyRent.tenant.name}</span>
      <span>{dailyRent.nigths}</span>
      <span>
        {dateFormat(dailyRent.dates.initDate.toISOString().split("T")[0])}
      </span>
      <span>
        {dateFormat(dailyRent.dates.endDate.toISOString().split("T")[0])}
      </span>
      <span>$ {dailyRent.total}</span>
      <span>{dailyRent.paymentState}</span>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleView(dailyRent.propertyId)}
      >
        <img src={calendarSvg} alt="calendar" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(dailyRent)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(dailyRent)}
      >
        <img src={deleteSvg} alt="delete" />
      </button>
    </div>
  );
}
