import { PropertySale, initSaleFilter } from "../../../interfaces/Rentals";
import { useDispatch, useSelector } from "react-redux";
import { History, HistoryType } from "../../../interfaces/History";
import { useEffect, useState } from "react";
import { deleteHistory } from "../../../redux/actions/history";
import { RootState } from "../../../interfaces/RootState";
import {
  setPropertySale,
  updatePropertySale,
  deletePropertySale,
  getSaleFirstPage,
  setSaleRentNextPage,
  getSaleRentNextPage,
  setSaleRentPrevPage,
} from "../../../redux/actions/rentals/propertySale";
import useHistory from "../../useHistory";
import swal from "sweetalert";

export default function usePropertySales() {
  const dispatch = useDispatch();
  const history = useHistory();
  const sales = useSelector((state: RootState) => state.propertiesSale);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState(initSaleFilter());
  const [currentData, setData] = useState<PropertySale[]>([]);

  useEffect(() => {
    if (sales.data.length > 0) {
      setData(sales.data[sales.current - 1]);
    }
  }, [sales]);

  async function error(promise: Promise<void>) {
    setLoading(true);
    return promise
      .then(() => setLoading(false))
      .catch((err: Error) => {
        console.log(err);
        setLoading(false);
        swal("Error", "Ocurrió un error, intentelo mas tarde", "error");
      });
  }

  async function addSale(sale: PropertySale) {
    const historyId: string = history.generateId();

    const newHistory: History = {
      id: historyId,
      propertyId: sale.propertyId,
      price: sale.currentPrice,
      admissionDate: new Date(),
      type: HistoryType.sale,
    };

    const newSale: PropertySale = { ...sale, historyId };

    return await Promise.all([
      dispatch<any>(setPropertySale(newSale)),
      history.add(historyId, newHistory),
    ]);
  }

  async function getFirstPage(): Promise<void> {
    // Get first page
    return await error(dispatch<any>(getSaleFirstPage(filters)));
  }

  async function getNextPage(): Promise<void> {
    // Check if this page already exist
    if (sales.data[sales.current]) {
      await dispatch<any>(setSaleRentNextPage());
    } else {
      // If not exist get this page
      return await error(
        dispatch<any>(getSaleRentNextPage(sales.lastDoc!, filters))
      );
    }
  }

  async function getPrevPage(): Promise<void> {
    await error(dispatch<any>(setSaleRentPrevPage()));
  }

  async function updateSale(property: PropertySale) {
    return await dispatch<any>(updatePropertySale(property));
  }

  async function deleteSale(sale: PropertySale) {
    return await Promise.all([
      dispatch<any>(deletePropertySale(sale)),
      dispatch<any>(deleteHistory(sale.historyId!)),
    ]);
  }

  async function resetFilters() {
    setFilters(initSaleFilter());
    return await getFirstPage();
  }

  return {
    data: currentData,
    totalsDocs: sales.totalDocs,
    filters: {
      data: filters,
      reset: resetFilters,
      setFilters: setFilters,
    },
    page: {
      current: sales.current,
      totals: sales.totalPages,
      firstPage: getFirstPage,
      prevPage: getPrevPage,
      nextPage: getNextPage,
    },
    loading: loading,
    add: addSale,
    update: updateSale,
    delete: deleteSale,
  };
}
