import { AsyncAction } from "../../../interfaces/Actions";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { History } from "../../../interfaces/History";
import { auth, db } from "../../../firebase/config";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const SET_HISTORY = "SET_HISTORY";
export const GET_HISTORY = "GET_HISTORY";
export const UPDATE_HISTORY = "UPDATE_HISTORY";
export const DELETE_HISTORY = "DELETE_HISTORY";

export function setHistory(historyId: string, history: History): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");
    if (!history.id) throw new Error("missing id");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");
    await setDoc(doc(historyColl, historyId), history);

    try {
      dispatch({
        type: SET_HISTORY,
        payload: history,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getHistory(initDate: Date, endDate: Date): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");

    // Convert to firebase Timestamp
    const startTimeStamp = Timestamp.fromDate(initDate);
    const endTimeStamp = Timestamp.fromDate(endDate);

    const historyQuery = query(
      historyColl,
      where("admissionDate", ">=", startTimeStamp),
      where("admissionDate", "<=", endTimeStamp)
    );

    const snapshot = await getDocs(historyQuery);

    let properties: any = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      let history: any = {
        ...data,
        id: doc.id,
        admissionDate: data.admissionDate?.toDate(),
      };

      if (data.rentalDates) {
        history.rentalDates = {
          initDate: data.rentalDates?.initDate.toDate(),
          endDate: data.rentalDates?.endDate.toDate(),
        };
      }
      properties.push(history);
    });

    try {
      dispatch({
        type: GET_HISTORY,
        payload: properties,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getHistoryPerProperty(propertyId: string): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");

    const historyQuery = query(
      historyColl,
      where("propertyId", "==", propertyId)
    );

    const snapshot = await getDocs(historyQuery);

    let properties: any = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      let history: any = {
        ...data,
        id: doc.id,
        admissionDate: data.admissionDate?.toDate(),
      };

      if (data.rentalDates) {
        history.rentalDates = {
          initDate: data.rentalDates?.initDate.toDate(),
          endDate: data.rentalDates?.endDate.toDate(),
        };
      }
      properties.push(history);
    });

    try {
      dispatch({
        type: GET_HISTORY,
        payload: properties,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateHistory(history: History): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");
    const { id, ...updatedhistory } = history;

    await updateDoc(doc(historyColl, id), { ...updatedhistory });

    try {
      dispatch({
        type: UPDATE_HISTORY,
        payload: history,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteHistory(historyId: string): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const historyColl = collection(userDoc, "History");

    await deleteDoc(doc(historyColl, historyId));

    try {
      dispatch({
        type: DELETE_HISTORY,
        payload: historyId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
