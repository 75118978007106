import { User, UserError, initUser, initUserError } from "../../interfaces/users";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useProfile from "../../hooks/useProfile";

import Input from "../../component/Inputs/Input";

import styles from "./Profile.module.css";

interface Props {
  handleClose: () => void;
}

export default function Profile({ handleClose }: Props) {
  const profile = useProfile();
  const [user, setUser] = useState<User>(initUser());
  const [error, setError] = useState<UserError>(initUserError());
  const [edit, setEdit] = useState(false);

  // Set user data
  useEffect(() => {
    if (profile.data) setUser(profile.data);
  }, [profile.data]);

  // Save image to profile
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState("");

  // Save changes
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUser({ ...user, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  }

  // Save new image
  function handleFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files !== null && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const url = URL.createObjectURL(event.target.files[0]);
      setImageUrl(url);
      setError({ ...error, profileImg: "" });
    }
  }

  // Update profile data
  function handleUpdate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validations()) profile.update(user, file)
  }

  // Validate data to submit
  function validations() {
    let errors: UserError = initUserError();
    let value = true;

    // NAME
    if (user.name === "") {
      errors.name = "Debes escribir un nombre";
      value = false;
    }

    // CUIT-CUIL
    if (user.cuitCuil === 0) {
      errors.cuitCuil = "Debes ingresar tu CUIT o CUIL";
      value = false;
    }

    // CORREO
    if (user.email === "") {
      errors.email = "Debes escribir un correo";
      value = false;
    }

    // CORREO
    if (user.email === "") {
      errors.email = "Debes escribir un correo";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={styles.background}>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleUpdate}>
          <header>
            <h3>Perfil</h3>
            <div className="btn btn-close" onClick={handleClose} />
          </header>
          <div className={styles.selectImg}>
            <div className={styles.logoContainer}>
              {profile.data.profileImg || imageUrl ? (
                <img src={imageUrl || profile.data.profileImg} alt="your-logo" />
              ) : (
                <span>Seleccione una imagen</span>
              )}
            </div>
            {edit &&
              <input
                type="file"
                name="file"
                accept="image/*"
                className={`form-control ${error.profileImg ? "is-invalid" : ""}`}
                onChange={handleFile}
                placeholder="logo"
              />
            }
            <small>{error.profileImg}</small>
          </div>
          <div className={styles.inputContainer}>
            <Input
              name="name"
              label="Nombre"
              value={user.name}
              error={error.name}
              handleChange={handleChange}
              disabled={!edit}
            />
            <Input
              name="cuitCuil"
              label="CUIT / CUIL"
              value={user.cuitCuil}
              error={error.cuitCuil}
              handleChange={handleChange}
              disabled={!edit}
            />
            <Input
              name="email"
              label="Email"
              value={user.email}
              error={error.email}
              handleChange={handleChange}
              disabled={!edit}
            />
            <Input
              name="phone"
              label="Telefono"
              value={user.phone}
              error={error.phone}
              handleChange={handleChange}
              disabled={!edit}
            />
            <div className={styles.btnContainer}>
              <Link to="/reset-password" className="btn btn-primary" type="submit">
                Cambiar contraseña
              </Link>
              <Link to="/reset-email" className="btn btn-primary" type="button" onClick={() => setEdit(false)}>
                Cambiar correo
              </Link>
            </div>
          </div>
          {
            !edit ?
              <div className={styles.btnContainer}>
                <button className="btn btn-success" type="button" onClick={() => setEdit(true)}>
                  Editar
                </button>
              </div>
              :
              <div className={styles.btnContainer}>
                <button className="btn btn-outline-danger" type="button" onClick={() => setEdit(false)}>
                  Cancelar
                </button>
                <button className="btn btn-success" type="submit">
                  Guardar
                </button>
              </div>
          }
        </form>
      </div>
    </div >
  );
}
