import { CashRegister } from "../../../interfaces/CashRegister";
import { ThunkAction } from "redux-thunk";
import { AsyncAction } from "../../../interfaces/Actions";
import { RootState } from "../../../interfaces/RootState";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { auth, db } from "../../../firebase/config";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

export const SET_CASH_REGISTER = "SET_CASH_REGISTER";
export const GET_CASH_REGISTER = "GET_CASH_REGISTER";
export const UPDATE_CASH_REGISTER = "UPDATE_CASH_REGISTER";
export const DELETE_CASH_REGISTER = "DELETE_CASH_REGISTER";

export const GET_ACOUNTS = "GET_ACOUNTS";
export const UPDATE_ACOUNTS = "UPDATE_ACOUNTS";

export function setCashRegister(cashRegister: CashRegister): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const cashRegisterColl = collection(userDoc, "CashRegisters");

    const snapshot = await addDoc(cashRegisterColl, cashRegister);

    const newCashRegister = {
      ...cashRegister,
      id: snapshot.id,
    };

    try {
      dispatch({
        type: SET_CASH_REGISTER,
        payload: newCashRegister,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getCashRegister(initDate: Date, endDate: Date): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const cashRegisterColl = collection(userDoc, "CashRegisters");

    console.log(
      initDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );

    // Convert to firebase Timestamp
    const startTimeStamp = Timestamp.fromDate(initDate);
    const endTimeStamp = Timestamp.fromDate(endDate);

    const cashRegisterQuery = query(
      cashRegisterColl,
      where("date", ">=", startTimeStamp),
      where("date", "<=", endTimeStamp)
    );

    const snapshot = await getDocs(cashRegisterQuery);

    let properties: CashRegister[] = [];
    snapshot.forEach((doc) => {
      properties.push({
        id: doc.id,
        ...(doc.data() as CashRegister),
        date: doc.data().date?.toDate(),
      });
    });

    try {
      dispatch({
        type: GET_CASH_REGISTER,
        payload: properties,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateCashRegister(cashRegister: CashRegister): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const cashRegisterColl = collection(userDoc, "CashRegisters");
    const { id, ...updatedCashRegister } = cashRegister;

    await updateDoc(doc(cashRegisterColl, id), updatedCashRegister);

    try {
      dispatch({
        type: UPDATE_CASH_REGISTER,
        payload: cashRegister,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteCashRegister(cashRegisterId: string): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const cashRegisterColl = collection(userDoc, "CashRegisters");

    await deleteDoc(doc(cashRegisterColl, cashRegisterId));

    try {
      dispatch({
        type: DELETE_CASH_REGISTER,
        payload: cashRegisterId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getAcounts(): ThunkAction<
  Promise<void>,
  RootState,
  null,
  AnyAction
> {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);

    const query = await getDoc(userDoc);
    const list = query.data()?.list || [];

    try {
      dispatch({
        type: GET_ACOUNTS,
        payload: list,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateAcount(list: string[]): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);

    await updateDoc(userDoc, { list });

    try {
      dispatch({
        type: UPDATE_ACOUNTS,
        payload: list,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
