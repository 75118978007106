import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./interfaces/RootState";

import Landing from "./pages/Landing/Landing";
import Login from "./pages/Login/Login";
import Signin from "./pages/Signin/Signin";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Loading from "./component/Loading/Loading";

import "./App.css";
import "./animation.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function App() {
  const loading = useSelector((state: RootState) => state.loading);

  return (
    <div className="App">
      {loading ? <Loading /> : null}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
