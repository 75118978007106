export interface User {
  id?: string;
  name: string;
  profileImg: string;
  cuitCuil: number;
  email: string;
  phone: string;
  rol: UserRol;
  password?: string;
}

export enum UserRol {
  Admin = "Admin",
  User = "Owner",
}

export const initUser = (): User => ({
  name: "",
  profileImg: "",
  cuitCuil: 0,
  phone: "",
  email: "",
  rol: UserRol.Admin,
  password: "",
});

export interface UserError {
  name: string;
  profileImg: string;
  cuitCuil: string;
  phone: string;
  email: string;
  password?: string;
}

export const initUserError = (): UserError => ({
  name: "",
  profileImg: "",
  cuitCuil: "",
  phone: "",
  email: "",
  password: "",
});
