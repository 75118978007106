export interface Charts {
  processesChart: CashChartData[];
  typeChart: TypeChartData[];
}

export interface CashChartData {
  day: number;
  income: number;
  expense: number;
}

export interface StageChartData {
  etapa: string;
  cantidad: number;
}

export interface TypeChartData {
  tipo: string;
  cantidad: number;
}

export const initCharts = {
  entityChart: {
    demandado: 0,
    demandante: 0,
  },
  processesChart: [],
  stageChart: [],
  typeChart: [],
};

export const initCashChartData = (
  year: number,
  month: number
): CashChartData[] => {
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);

  const chartData: CashChartData[] = [];

  for (let i = startDate.getDate(); i <= endDate.getDate(); i++) {
    const data: CashChartData = {
      day: i,
      income: 0,
      expense: 0,
    };
    chartData.push(data);
  }

  return chartData;
};
