import { FilesData } from "../../interfaces/Registers/Monthly";

import styles from "./FilesManagment.module.css";
import view from "../../assets/svg/view.svg";

interface Props {
  type: "contract" | "secure",
  files: FilesData[],
  setFilesToUpload: (fileToUpload: FilesData, type: "contract" | "secure") => void,
  setFilesToRemove: (file: FilesData, type: "contract" | "secure") => void,
  handleClose: () => void;
}

export default function FilesManagment({
  type,
  files,
  setFilesToUpload,
  setFilesToRemove,
  handleClose,
}: Props) {

  function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
    // Get file
    const file = event.target.files?.[0];

    // Check if file exist
    if (file) {
      // Create file data
      const fileData: FilesData = {
        file: file,
        name: file.name,
        path: "",
        url: URL.createObjectURL(file),
      }

      // Save data
      setFilesToUpload(fileData, type);
    }
  }

  function handleRemove(file: FilesData) {
    setFilesToRemove(file, type);
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.close}>
          <h4>Gestor de archivos</h4>
          <div className="btn-close" onClick={handleClose} />
        </div>
        <div className={styles.filesList}>
          {files.length > 0 ? files.map((file) => (
            <div className={styles.fileRow}>
              <span>{file.name}</span>
              {file.path &&
                <a href={file.url} target="_blank" rel="noreferrer">
                  <img src={view} alt="view" />
                </a>
              }
              <div
                className={`btn-close ${styles.delete}`}
                onClick={() => handleRemove(file)}
              />
            </div>
          ))
            : (
              <div className={styles.empty}>
                <span>Sin archivos</span>
              </div>
            )}
        </div>
        <div className={styles.controllers}>
          <label className="form-control" htmlFor="images">
            Seleccione un archivo para agregar
          </label>
          <input
            className="form-control"
            id="images"
            type="file"
            onChange={handleUpload}
          />
        </div>
      </div>
    </div>
  );
}
