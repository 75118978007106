import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { closeLoading, openLoading } from "../../../../redux/actions/loading";
import { CashRegister } from "../../../../interfaces/CashRegister";
import { RootState } from "../../../../interfaces/RootState";
import { updateAcount } from "../../../../redux/actions/cashRegister";
import swal from "sweetalert";

import CashRegisterRow from "./CashRegisterRow/CashRegisterRow";
import ProcessesChart from "./ProcessesChart/ProcessesChart";
import CashRegisterForm from "./CashRegisterForm/CashRegisterForm";
import useCashRegister from "../../../../hooks/useCashRegister";
import DateFilter from "../../../../component/DateFilter/DateFilter";
import AcountsList from "./AcountsList/AcountsList";

import styles from "./CashRegisterTable.module.css";
import loadingGif from "../../../../assets/img/loading.gif";
import errorSvg from "../../../../assets/svg/error.svg";

interface Filter {
  year: string;
  month: string | null;
  day: string | null;
}

export default function CashRegisterTable() {
  const dispatch = useDispatch();
  const cashRegisters = useCashRegister();
  const acounts = useSelector((state: RootState) => state.cashRegister.acounts);
  const [dataEdit, setDataEdit] = useState<CashRegister | undefined>();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [error, setError] = useState(false);
  const [acountsForm, setAcountsForm] = useState(false);
  const [filter, setFilters] = useState<Filter>({
    year: new Date().getFullYear().toString(),
    month: `0${(new Date().getMonth() + 1)}`.slice(-2).toString(),
    day: "",
  });

  const handleGetCashRegister = useCallback((localFilter: Filter) => {
    setLoading(true);
    setError(false);
    cashRegisters
      .get(localFilter)
      .then(() => {
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, []);

  useEffect(() => {
    handleGetCashRegister(filter);
  }, [handleGetCashRegister]);

  function handleFilterDate(filter: Filter) {
    console.log(filter);
    handleGetCashRegister(filter);
  }

  function handleEdit(data: CashRegister) {
    setDataEdit(data);
    handleClose();
  }

  function handleClose() {
    setForm(!form);
    if (form) {
      setDataEdit(undefined);
    }
  }

  function handleSaveCashRegister(cashRegister: CashRegister, edit: boolean) {
    dispatch(openLoading());
    (edit
      ? cashRegisters.update(cashRegister)
      : cashRegisters.add(cashRegister))
      .then(() => {
        dispatch(closeLoading());
        handleClose();
        swal(
          "Guardado",
          "Se guardo el movimiento correctamente",
          "success"
        );
      })
      .catch((err: any) => {
        dispatch(closeLoading());
        swal("Error", "No se pudo guardar el movimiento", "error");
        console.log(err);
      });
  }

  function handleDelete(idCashRegister: string) {
    swal({
      text: "¿Seguro desea eliminar este movimiento?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: any) => {
      if (response === "Si") {
        dispatch(openLoading());
        cashRegisters
          .delete(idCashRegister)
          .then(() => {
            dispatch(closeLoading());
            swal(
              "Eliminado",
              "Se elimino el movimiento correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el movimiento", "error");
            console.log(err);
          });
      }
    });
  }

  function handleSaveAcounts(acounts: string[]) {
    dispatch(openLoading());
    dispatch<any>(updateAcount(acounts))
      .then(() => {
        dispatch(closeLoading());
        swal("Guardado", "Cuentas actualizadas", "success");
      })
      .catch((error: Error) => {
        console.log(error.message);
        dispatch(closeLoading());
        swal(
          "Error",
          "Se produjo un error al querer actualizar las cuentas",
          "error"
        );
      });
  }

  function handleAcounts() {
    setAcountsForm(!acountsForm);
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {form ? (
        <CashRegisterForm
          handleClose={handleClose}
          handleSubmit={handleSaveCashRegister}
          data={dataEdit}
          acounts={acounts}
        />
      ) : null}
      {acountsForm && (
        <AcountsList
          data={acounts}
          handleSubmit={handleSaveAcounts}
          handleClose={handleAcounts}
        />
      )}
      <header>
        <ProcessesChart filter={filter} />
        <div className={styles.controls}>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleAcounts}
          >
            Cuentas
          </button>
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleClose}
          >
            + Nuevo movimiento
          </button>
          <DateFilter
            initFilter={filter}
            handleFilterDate={handleFilterDate}
            day={false}
            required={{ month: false }}
          />
        </div>
      </header>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.row} ${styles.firstRow}`}>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Cuenta</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody className={styles.contentRows}>
          <div>
            {loading ? (
              <div className={styles.loading}>
                <img src={loadingGif} alt="loading" />
              </div>
            ) : null}
            {error ? (
              <div className={styles.error}>
                <img src={errorSvg} alt="error" />
                <span>No se pudo cargar los movimientos</span>
                <div>
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => handleGetCashRegister(filter)}
                  >
                    Recargar
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    onClick={() => setError(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : null}
            {cashRegisters?.list?.length <= 0 ? (
              <tr className={styles.emptyRows}>
                <th>No hay movimientos</th>
              </tr>
            ) : (
              cashRegisters.list?.sort((a, b) => {
                if (a.date.getTime() === b.date.getTime()) return 0;
                if (a.date.getTime() < b.date.getTime()) return -1;
                if (a.date.getTime() > b.date.getTime()) return 1;
                return 0;
              }).map((cashRegister: CashRegister) => (
                <CashRegisterRow
                  key={cashRegister.id}
                  cashRegister={cashRegister}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))
            )}
          </div>
        </tbody>
      </table>
    </div>
  );
}
