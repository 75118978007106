import { useSelector } from "react-redux";
import { MonthlyRent } from "../../../../../../interfaces/Registers/Monthly";
import { RootState } from "../../../../../../interfaces/RootState";
import { useEffect } from "react";

import style from "./MonthlyRentRow.module.css";
import editSvg from "../../../../../../assets/svg/edit.svg";
import calendarSvg from "../../../../../../assets/svg/calendar.svg";
import deleteSvg from "../../../../../../assets/svg/delete.svg";
import dateFormat from "../../../../../../functions/dateFormat";

interface Props {
  monthlyRent: MonthlyRent;
  handleEdit: (monthlyRent: MonthlyRent) => void;
  handleView: (propertyId: string) => void;
  handleDelete: (monthlyRent: MonthlyRent) => void;
}

export default function MonthlyRentRow({
  monthlyRent,
  handleEdit,
  handleView,
  handleDelete,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);

  return (
    <tr className={style.row}>
      <td>
        {properties.find((property) => property.id === monthlyRent.propertyId)
          ?.name || ""}
      </td>
      <td>
        <span>
          <b>Nombre: </b>
          {monthlyRent.tenant.name}
        </span>
        <span>
          <b>Email: </b>
          {monthlyRent.tenant.email || ""}
        </span>
      </td>
      <td>{monthlyRent.typeOfContract}</td>
      <td>
        <span>
          <b>Inicio:</b>
          {dateFormat(
            monthlyRent.dates.initDate?.toISOString()?.split("T")[0]
          ) || "-"}
        </span>
        <span>
          <b>Final:</b>
          {dateFormat(
            monthlyRent.dates.endDate?.toISOString()?.split("T")[0]
          ) || "-"}
        </span>
      </td>
      <td>{monthlyRent.expirationDate}</td>
      <td>${monthlyRent.deposit}</td>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleView(monthlyRent.propertyId)}
      >
        <img src={calendarSvg} alt="calendar" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(monthlyRent)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(monthlyRent)}
      >
        <img src={deleteSvg} alt="delete" />
      </button>
    </tr>
  );
}
