import { Rent, initTenant } from "../Rentals";

export interface MonthlyRent extends Rent {
  rented: MonthRented;
  typeOfContract: TypeOfContract;
  expirationDate: number;
  securiryDeposit: number;
  deposit: number;
  leaseRenewal: number;
  taxes: string;
  files: MonthlyRentFiles;

  /* Data for th new monthly rent */
  commission?: number;
  guarantors?: number;
  considerations?: string;

  guarantorName1?: string;
  guarantorDNI1?: number;
  guarantorEmail1?: number;
  guarantorPhone1?: number;

  guarantorName2?: string;
  guarantorDNI2?: number;
  guarantorEmail2?: number;
  guarantorPhone2?: number;

  guarantorName3?: string;
  guarantorDNI3?: number;
  guarantorEmail3?: number;
  guarantorPhone3?: number;

  guarantorName4?: string;
  guarantorDNI4?: number;
  guarantorEmail4?: number;
  guarantorPhone4?: number;

  taxesName1?: "";
  taxesNumberRef1?: "";
  taxesTotal1?: 0;
  taxesName2?: "";
  taxesNumberRef2?: "";
  taxesTotal2?: 0;
  taxesName3?: "";
  taxesNumberRef3?: "";
  taxesTotal3?: 0;
  taxesName4?: "";
  taxesNumberRef4?: "";
  taxesTotal4?: 0;
}

export interface MonthlyRentFiles {
  contract: FilesData[];
  secure: FilesData[];
}

export interface FilesData {
  file?: File;
  name: string;
  path: string;
  url: string;
}

export enum MonthRented {
  ANY = "",
  SI = "Si",
  NO = "No",
}

export interface MonthlyFilter {
  propertyId: string;
  rented: MonthRented;
  typeOfContract: string;
}

export interface ErrorMonthlyRent {
  propertyId: string;
  typeOfContract: string;
  price: string;
  tenant: string;
  securiryDeposit: string;
  deposit: string;
  expirationDate: string;
  leaseRenewal: string;
  taxes: string;
  startMonth: string;
  endMonth: string;
  guarantorName: string;
  guarantorDNI: string;
}

export enum TypeOfContract {
  LEY_DE_ALQUILERES = "LEY_DE_ALQUILERES",
  COMERCIAL = "COMERCIAL",
  COMODATO = "COMODATO",
}

export const initMonthlyFilter = (): MonthlyFilter => ({
  propertyId: "",
  rented: MonthRented.ANY,
  typeOfContract: "",
});

export const initMonthlyRent = (): MonthlyRent => ({
  propertyId: "",
  typeOfContract: TypeOfContract.COMERCIAL,
  tenant: initTenant(),
  securiryDeposit: 0,
  rented: MonthRented.NO,
  price: 0,
  total: 0,
  deposit: 0,
  expirationDate: 1,
  leaseRenewal: 0,
  taxes: "",
  dates: {
    initDate: new Date(),
    endDate: new Date(),
  },
  files: {
    contract: [],
    secure: [],
  },
});

export const initMonthlyRentFiles = (): MonthlyRentFiles => ({
  contract: [],
  secure: [],
});

export const initErrorMonthlyRent = (): ErrorMonthlyRent => ({
  propertyId: "",
  typeOfContract: "",
  tenant: "",
  guarantorName: "",
  guarantorDNI: "",
  securiryDeposit: "",
  price: "",
  deposit: "",
  expirationDate: "",
  leaseRenewal: "",
  taxes: "",
  startMonth: "",
  endMonth: "",
});
