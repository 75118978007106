import { AnyAction } from "redux";
import { initState } from "../../interfaces/RootState";
import { LOGOUT } from "../actions/login";
import { GET_USER, UPDATE_USER } from "../actions/users";

const userReducer = (state = initState().user, action: AnyAction) => {
  switch (action.type) {
    case GET_USER:
      return action.payload;

    case UPDATE_USER:
      return action.payload;

    case LOGOUT:
      return initState().user;

    default:
      return state;
  }
};

export default userReducer;
