/* import { useState } from "react";
 */import PlacesAutocomplete from "./PlaceAutocomplete/PlaceAutocomplete";

import styles from "./MapContainer.module.css";

interface Props {
  location: string;
  error: string;
  setLocation: (adress: string) => void;
}

const MapContainer = ({ location, error, setLocation }: Props) => {
  /*
  const [response, setResponse] = useState<string[]>([]);

   useEffect(() => {
    axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=Paris&types=geocode&key=${process.env.REACT_APP_MAPS_API_KEY}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }, [location]);
 */
  return (
    <div className={styles.container}>
      <PlacesAutocomplete
        location={location}
        error={error}
        setLocation={setLocation}
      />
      <iframe
        title="google_maps"
        className={styles.map}
        src={`https://www.google.com/maps/embed/v1/search?key=${
          process.env.REACT_APP_MAPS_API_KEY
        }&q=${location || "España"}`}
      ></iframe>
    </div>
  );
};
export default MapContainer;
