import { useEffect, useState } from "react";
import {
  ErrorProperty,
  ManagmentType,
  Property,
  PropertyType,
  initErrorProperty,
  initOwner,
  initProperty,
  typeProperty1,
  typeProperty2,
  typeProperty3,
  typeProperty4,
} from "../../../../../interfaces/Properties";

import AddImages from "./AddImages/AddImages";
import Input from "../../../../../component/Inputs/Input";
import Checkbox from "../../../../../component/Inputs/Checkbox";
import SelectInput from "../../../../../component/Inputs/SelectInput";
import MapContainer from "./MapContainer/MapContainer";

import style from "./PropertyForm.module.css";

interface Props {
  handleClose: () => void;
  handleSubmit: (
    property: Property,
    imagesToUpload: File[],
    imagesToRemove: string[],
    edit: boolean) => void;
  data: Property | undefined;
}

interface ImagesToUpload {
  url: string,
  file: File
}

export default function PropertyForm({
  handleClose,
  handleSubmit,
  data,
}: Props) {
  const [property, setProperty] = useState<Property>(initProperty());
  const [error, setError] = useState<ErrorProperty>(initErrorProperty());

  const [imagesToUpload, setImagesToUpload] = useState<ImagesToUpload[]>([]);
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);

  useEffect(() => {
    console.log(property);
  }, [property]);

  useEffect(() => {
    if (data) setProperty(data);
  }, [data]);

  function handlelocalSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(
        property,
        imagesToUpload.map(img => img.file),
        imagesToRemove,
        data ? true : false);
    }
  }

  function handleOwner(event: React.ChangeEvent<HTMLInputElement>) {
    setProperty({
      ...property,
      owner: {
        ...property.owner!,
        [event.target.name]: event.target.value,
      },
    });
  }

  function handleChange(
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) {
    // If the property is managed, add the owner
    if (event.target.name === "managment") {
      if (property.managment === ManagmentType.Managing) {
        // If is managed and owner don't exist add initOwner
        setProperty({
          ...property,
          managment: event.target.value as ManagmentType,
          owner: property.owner || initOwner()
        });
      } else {
        // If is not managed delete owner
        const { owner, ...newProperty } = property;
        setProperty({
          ...newProperty,
          managment: event.target.value as ManagmentType,
        });
      }
    } else {
      setProperty({ ...property, [event.target.name]: event.target.value });
    }
    setError({ ...error, [event.target.name]: "" });
  }

  function setLocation(adress: string) {
    setProperty({ ...property, location: adress });
    setError({ ...error, location: "" });
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const checked = event.target.checked;

    if (name === "monthly") {
      setProperty({
        ...property,
        maxTenants: 0,
        registerType: {
          ...property.registerType,
          monthly: checked,
          daily: false,
        },
      });
    } else if (name === "daily") {
      setProperty({
        ...property,
        registerType: {
          ...property.registerType,
          monthly: false,
          daily: checked,
        },
      });
    } else {
      setProperty({
        ...property,
        registerType: {
          ...property.registerType,
          [name]: checked,
        },
      });
    }
  }

  function handleDetails(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    setProperty({
      ...property,
      details: {
        ...property.details,
        [name]: value
      }
    });
  }

  function handleDetailsCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const checked = event.target.checked;

    setProperty({
      ...property,
      details: {
        ...property.details,
        [name]: checked
      }
    });
  }

  function handlePropertyType(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;

    if (value === PropertyType.TERRENO) {
      setProperty({
        ...property,
        type: value,
        details: typeProperty1()
      });
    } else if (
      value === PropertyType.CASA ||
      value === PropertyType.DEPARTAMENTO ||
      value === PropertyType.DESARROLLO ||
      value === PropertyType.CABANIA ||
      value === PropertyType.OFICINA
    ) {
      setProperty({
        ...property,
        type: value,
        details: typeProperty2()
      });
    } else if (value === PropertyType.GALPON) {
      setProperty({
        ...property,
        type: value,
        details: typeProperty3()
      });
    } else if (value === PropertyType.CAMPO) {
      setProperty({
        ...property,
        type: value,
        details: typeProperty4()
      });
    } else if (value === PropertyType.COMECIAL) {
      setProperty({
        ...property,
        type: value,
        details: {}
      });
    }

    if (value !== PropertyType.ANY) {
      setError({ ...error, type: "" });
    } else {
      setError({ ...error, type: "Debes seleccionar un tipo" });
    }
  }

  function handleLocalClose() {
    handleClose();
  }

  function validations() {
    let errors = initErrorProperty();
    let value = true;

    if (property.managment === ManagmentType.Any) {
      errors.managment = "Debes seleccionar un tipo";
      value = false;
    }

    if (property.name === "") {
      errors.name = "Debes ingresar un nombre";
      value = false;
    }

    if (property.registerType?.daily && property.maxTenants === 0) {
      errors.maxTenants = "Debes ingresar los huespedes máximos";
      value = false;
    }

    if (property.location === "") {
      errors.location = "Debes ingresar la ubicación";
      value = false;
    }

    if (
      property.managment !== ManagmentType.Any &&
      property.owner?.name === ""
    ) {
      errors.ownerName = "Debes ingresar el nombre del propietario";
      value = false;
    }

    if (property.type === PropertyType.ANY) {
      errors.type = "Debes seleccionar un tipo";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={style.background}>
      <form className={`toTop ${style.form}`} onSubmit={handlelocalSubmit}>
        <div className={style.close}>
          <h3>Agregar propiedad</h3>
          <div className="btn-close" onClick={handleLocalClose} />
        </div>
        <div className={style.flex}>
          <AddImages
            images={property.imgUrl}
            imagesToUpload={imagesToUpload}
            imagesToRemove={imagesToRemove}
            setImagesToUpload={setImagesToUpload}
            setImagesToRemove={setImagesToRemove}
          />
          <div className={style.inputsContainer}>
            <h5>Propiedad</h5>
            <SelectInput
              name="managment"
              label="Propietario / Administrado"
              value={property.managment}
              list={[
                { value: ManagmentType.Owner, label: "Propietario" },
                { value: ManagmentType.Managing, label: "Administrado" },
              ]}
              error={error.managment}
              handleChange={handleChange}
            />
            {property.managment === ManagmentType.Managing &&
              <div className={style.owner} >
                <h5>Propietario</h5>
                <Input
                  name="name"
                  label="Nombre"
                  value={property.owner?.name}
                  error={error.ownerName}
                  handleChange={handleOwner}
                />
                <Input
                  name="email"
                  label="Email"
                  value={property.owner?.email}
                  handleChange={handleOwner}
                />
                <Input
                  name="phone"
                  label="Telefono"
                  value={property.owner?.phone}
                  handleChange={handleOwner}
                />
              </div>
            }
            <Input
              name="name"
              label="Nombre de la propiedad"
              value={property.name}
              error={error.name}
              handleChange={handleChange}
            />
            <MapContainer
              location={property.location}
              error={error.location}
              setLocation={setLocation}
            />
            <h5>Detalles</h5>
            <SelectInput
              name="type"
              label="Tipo de propiedad"
              value={property.type}
              list={[
                { value: PropertyType.TERRENO, label: "Terreno" },
                { value: PropertyType.CASA, label: "Casa" },
                { value: PropertyType.DEPARTAMENTO, label: "Departamento" },
                { value: PropertyType.COMECIAL, label: "Comercial" },
                { value: PropertyType.CABANIA, label: "Cabaña" },
                { value: PropertyType.OFICINA, label: "Oficina" },
                { value: PropertyType.GALPON, label: "Galpón" },
                { value: PropertyType.CAMPO, label: "Campo" },
              ]}
              error={error.type}
              handleChange={handlePropertyType}
            />
            {
              property.type === PropertyType.TERRENO && (
                <div className={style.type}>
                  <Input
                    name="measures"
                    label="Medidas"
                    type="string"
                    value={property.details.measures}
                    handleChange={handleDetails}
                  />
                  <Checkbox
                    name="expenses"
                    value={property.details.expenses}
                    label="Expensas"
                    handleCheck={handleDetailsCheck}
                  />
                  <Checkbox
                    name="privateNeighborhood"
                    value={property.details.privateNeighborhood}
                    label="Barrio privado"
                    handleCheck={handleDetailsCheck}
                  />
                </div>
              )
            }
            {
              (
                property.type === PropertyType.CASA ||
                property.type === PropertyType.DEPARTAMENTO ||
                property.type === PropertyType.DESARROLLO ||
                property.type === PropertyType.CABANIA ||
                property.type === PropertyType.OFICINA
              ) && (
                <div className={style.type}>
                  <Input
                    name="totalSquereMeters"
                    label="Metros cuadrados"
                    type="string"
                    value={property.details.totalSquereMeters}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="bedrooms"
                    label="Habitaciones"
                    type="number"
                    value={property.details.bedrooms}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="bathrooms"
                    label="Baños"
                    type="number"
                    value={property.details.bathrooms}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="kitchen"
                    label="Cocina"
                    type="number"
                    value={property.details.kitchen}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="diningRoom"
                    label="Comedor"
                    type="number"
                    value={property.details.diningRoom}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="yard"
                    label="Patio"
                    type="number"
                    value={property.details.yard}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="living"
                    label="Living"
                    type="number"
                    value={property.details.living}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="garage"
                    label="Garage"
                    type="number"
                    value={property.details.garage}
                    handleChange={handleDetails}
                  />
                  <Checkbox
                    name="expenses"
                    value={property.details.expenses}
                    label="Expensas"
                    handleCheck={handleDetailsCheck}
                  />
                </div>
              )
            }
            {
              property.type === PropertyType.GALPON && (
                <div className={style.type}>
                  <h5>Metros cuadrados</h5>
                  <div className={style.totalSquereMeters}>
                    <Input
                      name="totalSquereMeters"
                      label="Totales"
                      type="number"
                      value={property.details.totalSquereMeters}
                      handleChange={handleDetails}
                    />
                    <Input
                      name="covertedSquereMeters"
                      label="Cubiertos"
                      type="number"
                      value={property.details.covertedSquereMeters}
                      handleChange={handleDetails}
                    />
                    <Input
                      name="uncovertedSquereMeters"
                      label="Descubiertos"
                      type="number"
                      value={property.details.uncovertedSquereMeters}
                      handleChange={handleDetails}
                    />
                  </div>
                  <hr></hr>
                  <Input
                    name="bathrooms"
                    label="Baños"
                    type="number"
                    value={property.details.bathrooms}
                    handleChange={handleDetails}
                  />
                  <Input
                    name="kitchen"
                    label="Cocina"
                    type="number"
                    value={property.details.kitchen}
                    handleChange={handleDetails}
                  />
                </div>
              )
            }
            {
              property.type === PropertyType.CAMPO && (
                <div className={style.type}>
                  <Input
                    name="hectares"
                    label="Hectares"
                    type="string"
                    value={property.details.hectares}
                    handleChange={handleDetails}
                  />
                </div>
              )
            }
            {
              property.registerType?.daily &&
              <Input
                name="maxTenants"
                label="Huespedes máximos"
                value={property.maxTenants}
                type="number"
                error={error.maxTenants}
                handleChange={handleChange}
              />
            }
            <h5>Clasificaciones</h5>
            <Checkbox
              name="daily"
              value={property.registerType?.daily}
              label="Diario"
              handleCheck={handleCheck}
            />
            <Checkbox
              name="monthly"
              value={property.registerType?.monthly}
              label="Mensual"
              handleCheck={handleCheck}
            />
            <Checkbox
              name="sale"
              value={property.registerType?.sale}
              label="Venta"
              handleCheck={handleCheck}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-success">
          {data ? "Guardar" : "Agregar"} propiedad
        </button>
      </form >
    </div >
  );
}
