import React, { useState } from "react";
import swal from "sweetalert";

import styles from "./ContactForm.module.css";
import contact from "../../../assets/landing/svg/contact.svg";
import whatsapp from "../../../assets/landing/svg/whatsapp.svg";
import email from "../../../assets/landing/svg/email.svg";
import loadingSvg from "../../../assets/landing/img/loading.gif";
import Input from "../../../component/Inputs/Input";
import TextAreaInput from "../../../component/Inputs/TextareaInput";

interface Consult {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const initConsult = (): Consult => ({
  name: "",
  email: "",
  subject: "",
  message: "",
});

export default function ContactForm() {
  const [consult, setConsult] = useState(initConsult());
  const [errors, setErrors] = useState(initConsult());
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (validations()) {
      setLoading(true);
      fetch("https://us-central1-rental-manager-d1508.cloudfunctions.net/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: consult.name,
          email: consult.email,
          subject: consult.subject,
          message: consult.message,
        }),
      })
        .then(() => {
          setConsult(initConsult());
          swal("Enviado", "¡Gracias por enviarnos su consulta!", "success");
          setLoading(false);
        })
        .catch((error: Error) => {
          console.log(error);
          swal("Error", "No se pudo enviar su consulta, intente más tarde", "error");
          setLoading(false);
        })
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setConsult({ ...consult, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  }

  function validations() {
    let errors = initConsult();
    let value = true;

    if (consult.name === "") {
      errors.name = "Debes agregar un nombre";
      value = false;
    }

    if (consult.email === "") {
      errors.email = "Debes agregar un correo";
      value = false;
    }

    if (consult.subject === "") {
      errors.subject = "Debes agregar un asunto";
      value = false;
    }

    if (consult.message === "") {
      errors.message = "Debes agregar un mensaje";
      value = false;
    }

    setErrors(errors);
    return value;
  }

  return (
    <div id="form" className={styles.form}>
      <h2 data-aos="fade-up" data-aos-duration="600">Contacto</h2>
      <div className={styles.contactForm} data-aos="fade-up" data-aos-duration="800">
        {
          loading && <div className={styles.loading}>
            <img src={loadingSvg} alt="loading" />
          </div>
        }
        <img src={contact} alt="contact" />
        <div className={styles.contactinfo} >
          <img src={whatsapp} alt="whatsapp" />
          <span>+5493794267780</span>
          <img src={email} alt="email" />
          <span>contacto@gestopia.com</span>
        </div>
        <form onSubmit={handleSubmit}>
          <h4>Envianos un mail</h4>
          <Input
            name="name"
            label="Nombre"
            value={consult.name}
            error={errors.name}
            handleChange={handleChange}
          />
          <Input
            name="email"
            label="Email"
            type="email"
            value={consult.email}
            error={errors.email}
            handleChange={handleChange}
          />
          <Input
            name="subject"
            label="Asunto"
            value={consult.subject}
            error={errors.subject}
            handleChange={handleChange}
          />
          <TextAreaInput
            name="message"
            label="Mensaje"
            value={consult.message}
            error={errors.message}
            handleChange={handleChange}
          />
          <button className="btn btn-success" type="submit">Enviar</button>
        </form>
      </div>
    </div>
  )
}
