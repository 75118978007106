import { AnyAction } from "redux";
import { initState } from "../../interfaces/RootState";
import { LOGIN, LOGOUT, SIGN_IN } from "../actions/login";

const loginReducer = (state = initState().user, action: AnyAction) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.payload,
      };

    case LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGOUT:
      return initState().user;

    default:
      return state;
  }
};

export default loginReducer;
