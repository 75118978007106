import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/users";
import { RootState } from "../../interfaces/RootState";
import { User } from "../../interfaces/users";
import swal from "sweetalert";

export default function useProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  function updateUserData(updatedUser: User, imgFile: File | null) {
    dispatch<any>(openLoading());
    dispatch<any>(updateUser(updatedUser, imgFile))
      .then(() => {
        dispatch<any>(closeLoading());
        swal("Actualizado", "Se actualizó correctamente el perfil", "success");
      })
      .catch(() => {
        dispatch<any>(closeLoading());
        swal("Error", "No se pudo actualizar correctamente el perfil", "error");
      });
  }

  return {
    data: user,
    update: updateUserData,
  };
}
