import { useEffect, useState } from "react";
import { EventInput } from "@fullcalendar/core";
import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import esLocale from "@fullcalendar/core/locales/es";

import styles from "./FullCalendarView.module.css";

interface Props {
  propertyId: string;
  handleClose: () => void;
}

export default function FullCalendarView({ propertyId, handleClose }: Props) {
  const daily = useSelector((state: RootState) => state.dailyRentals.data?.[0]);
  const monthly = useSelector(
    (state: RootState) => state.monthlyRentals.data?.[0]
  );
  const [events, setEvents] = useState<EventInput[]>([]);

  useEffect(() => {
    const events = [];

    // Iterate dailyRent
    if (monthly) {
      for (const rent of daily) {
        if (rent.propertyId !== propertyId) continue;

        // Get dates
        const initDate: string = rent.dates.initDate.toISOString();
        const endDate: string = rent.dates.endDate.toISOString();

        // Create event
        const event: EventInput = {
          title: rent.tenant.name,
          start: initDate,
          end: endDate,
        };

        // Push event
        events.push(event);
      }
    }

    // Iterate monthlyRent
    if (monthly) {
      for (const rent of monthly) {
        if (rent.propertyId !== propertyId) continue;

        // Get dates
        const initDate: string = rent.dates.initDate.toISOString();
        const endDate: string = rent.dates.endDate.toISOString();

        // Create event
        const event: EventInput = {
          title: rent.tenant.name,
          start: initDate,
          end: endDate,
        };

        // Push event
        events.push(event);
      }
    }

    setEvents(events);
  }, [propertyId, daily, monthly]);

  return (
    <div className={styles.background}>
      <header>
        <div className="btn btn-close" onClick={() => handleClose()} />
      </header>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        height="100%"
        events={events}
        locale={esLocale}
        timeZone="Ameriva/Argentina/Buenos_Aires"
      />
    </div>
  );
}
