import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../../interfaces/RootState";
import { DailyRent } from "../../../../../interfaces/Rentals";
import {
  closeLoading,
  openLoading,
} from "../../../../../redux/actions/loading";
import useDailyRent from "../../../../../hooks/Rentals/useDaillyRent";
import swal from "sweetalert";

import FullCalendarView from "../../../../../component/FullCalendarView/FullCalendarView";
import DailyRentRow from "./DailyRentRow/DailyRentRow";
import DailyRentForm from "./DailyRentForm/DailyRentForm";
import Filters from "./Filters/Filters";

import styles from "./DailyRentTable.module.css";

export default function DailyRentTable() {
  const dispatch = useDispatch();
  const properties = useSelector((state: RootState) => state.properties);
  const { data, totalsDocs, filters, page, loading, ...dailyRentals } =
    useDailyRent();
  const [dataEdit, setDataEdit] = useState<DailyRent | undefined>();
  const [propertyId, setDataView] = useState<string>("");
  const [form, setForm] = useState(false);

  useEffect(() => {
    if (data.length <= 0) {
      setTimeout(() => {
        page.firstPage();
      }, 1500);
    }
  }, []);

  function handleView(propertyId?: string) {
    setDataView(propertyId || "");
  }

  function handleEdit(data: DailyRent) {
    setDataEdit(data);
    handleClose();
  }

  function handleClose() {
    setForm(!form);
    if (form) {
      setDataEdit(undefined);
    }
  }

  function handleSaveDailyRent(DailyRent: DailyRent, edit: boolean) {
    dispatch(openLoading());
    (edit ? dailyRentals.update(DailyRent) : dailyRentals.add(DailyRent))
      .then(() => {
        dispatch(closeLoading());
        handleClose();
        swal("Guardado", "Se guardo el alquiler correctamente", "success");
      })
      .catch((err: any) => {
        dispatch(closeLoading());
        swal("Error", "No se pudo guardar el alquiler", "error");
        console.log(err);
      });
  }

  function handleDelete(dailyRent: DailyRent) {
    swal({
      text: "¿Seguro desea eliminar este alquiler?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: any) => {
      if (response === "Si") {
        dispatch(openLoading());
        dailyRentals
          .delete(dailyRent)
          .then(() => {
            dispatch(closeLoading());
            swal(
              "Eliminado",
              "Se elimino el alquiler correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el alquiler", "error");
            console.log(err);
          });
      }
    });
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {form ? (
        <DailyRentForm
          handleClose={handleClose}
          handleSubmit={handleSaveDailyRent}
          data={dataEdit}
        />
      ) : null}
      {propertyId ? (
        <FullCalendarView propertyId={propertyId} handleClose={handleView} />
      ) : null}
      <div className={styles.controls}>
        <Filters
          filters={filters.data}
          setFilters={filters.setFilters}
          handleSubmit={page.firstPage}
          handleReset={filters.reset}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handleClose}
        >
          + Nuevo alquiler diario
        </button>
      </div>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.firstRow}`}>
          <span></span>
          <span>Nombre</span>
          <span>Canal</span>
          <span>Huesped</span>
          <span>Noches</span>
          <span>Desde</span>
          <span>Hasta</span>
          <span>Total</span>
          <span>Estado</span>
          <span>Acciones</span>
        </div>
        <div className={styles.body}>
          {data?.length <= 0 ? (
            <div className={styles.emptyRows}>
              <span>No hay alquileres diarios</span>
            </div>
          ) : (
            data?.map((dailyRent: DailyRent) => (
              <DailyRentRow
                key={dailyRent.id}
                dailyRent={dailyRent}
                property={properties.find(
                  (porperty) => porperty.id === dailyRent.propertyId
                )}
                handleEdit={handleEdit}
                handleView={handleView}
                handleDelete={handleDelete}
              />
            ))
          )}
        </div>
        <div className={styles.pagination}>
          <span>{page.totals} Propiedades</span>
          <button
            className={page.current <= 1 ? "" : styles.btnActive}
            type="button"
            onClick={page.prevPage}
            disabled={page.current <= 1}
          >{`<`}</button>
          <span>{`${page.current} de ${page.totals}`}</span>
          <button
            className={page.current >= page.totals ? "" : styles.btnActive}
            type="button"
            onClick={page.nextPage}
            disabled={page.current >= page.totals}
          >{`>`}</button>
        </div>
      </div>
    </div>
  );
}
