// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBVMmRZ7trB7t8t3AHjQgyuEsjJreS0_mQ",
  authDomain: "rental-manager-d1508.firebaseapp.com",
  projectId: "rental-manager-d1508",
  storageBucket: "rental-manager-d1508.appspot.com",
  messagingSenderId: "678178147644",
  appId: "1:678178147644:web:bf1f2bddfb1f612968a239",
};

// Initialize Firebase
export const fs = initializeApp(firebaseConfig);

// Initilize FireStore Services
export const db = getFirestore(fs);
export const auth = getAuth(fs);
export const storage = getStorage(fs);

if (process.env.REACT_APP_EMULATOR === "true") {
  console.log("Emulator activated");
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8085);
  connectStorageEmulator(storage, "localhost", 9199);
}
