import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../redux/actions/users";
import { auth } from "../../firebase/config";
import useProperty from "../useProperty";
import swal from "sweetalert";

export default function useLogin() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const property = useProperty();

  function autoLogin() {
    dispatch(openLoading());
    auth.onAuthStateChanged((data) => {
      if (auth.currentUser) {
        dispatch<any>(getUser())
          .then(() => {
            redirect("/dashboard/");
            property.page.get().then(() => dispatch(closeLoading()));
          })
          .catch((err: any) => {
            console.log(err);
            dispatch(closeLoading());
            swal(
              "Error",
              "Hubo un error desconocido al iniciar sesion",
              "error"
            );
          });
      } else {
        redirect("/login");
        dispatch(closeLoading());
      }
    });
  }

  function autoRegister() {
    dispatch(openLoading());
    auth.onAuthStateChanged((data) => {
      if (auth.currentUser) {
        dispatch<any>(getUser())
          .then(() => {
            redirect("/dashboard/");
            property.page.get().then(() => dispatch(closeLoading()));
          })
          .catch((err: any) => {
            console.log(err);
            dispatch(closeLoading());
            swal(
              "Error",
              "Hubo un error desconocido al iniciar sesion",
              "error"
            );
          });
      } else {
        redirect("/signin");
        dispatch(closeLoading());
      }
    });

    setTimeout(() => {
      if (!auth.currentUser) {
        redirect("/signin");
        dispatch(closeLoading());
      }
    }, 5000);
  }

  return {
    autoLogin,
    autoRegister,
  };
}
