import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RemoveFiles } from "../../../../../interfaces/Rentals";
import {
  MonthlyRent,
  MonthlyRentFiles,
} from "../../../../../interfaces/Registers/Monthly";
import {
  closeLoading,
  openLoading,
} from "../../../../../redux/actions/loading";
import useMonthlyRent from "../../../../../hooks/Rentals/useMonthlyRent/index,";
import swal from "sweetalert";

import FullCalendarView from "../../../../../component/FullCalendarView/FullCalendarView";
import MonthlyRentRow from "./MonthlyRentRow/MonthlyRentRow";
import MonthlyRentForm from "./MonthlyRentForm/MonthlyRentForm";
import Filters from "./Filters/Filters";

import styles from "./MonthlyRentTable.module.css";

export default function MonthlyRentTable() {
  const dispatch = useDispatch();
  const { data, totalsDocs, filters, page, loading, ...monthlyRentals } =
    useMonthlyRent();
  const [dataEdit, setDataEdit] = useState<MonthlyRent | undefined>();
  const [propertyId, setDataView] = useState<string>("");
  const [form, setForm] = useState(false);

  useEffect(() => {
    if (data.length <= 0) {
      setTimeout(() => {
        page.firstPage();
      }, 1500);
    }
  }, []);

  function handleView(propertyId?: string) {
    setDataView(propertyId || "");
  }

  function handleEdit(data: MonthlyRent) {
    setDataEdit(data);
    handleClose();
  }

  function handleClose() {
    setForm(!form);
    if (form) {
      setDataEdit(undefined);
    }
  }

  function handleSaveMonthlyRent(
    MonthlyRent: MonthlyRent,
    filesToUpload: MonthlyRentFiles,
    filesToRemove: RemoveFiles,
    edit: boolean
  ) {
    dispatch(openLoading());
    console.log(MonthlyRent, filesToUpload, filesToRemove, edit);

    (edit
      ? monthlyRentals.update(MonthlyRent, filesToUpload, filesToRemove)
      : monthlyRentals.add(MonthlyRent, filesToUpload)
    )
      .then(() => {
        dispatch(closeLoading());
        handleClose();
        swal("Guardado", "Se guardo el alquiler correctamente", "success");
      })
      .catch((err: any) => {
        dispatch(closeLoading());
        console.log(err);
        swal("Error", "No se pudo guardar el alquiler", "error");
      });
  }

  function handleDelete(monthlyRent: MonthlyRent) {
    swal({
      text: "¿Seguro desea eliminar este alquiler?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: any) => {
      if (response === "Si") {
        dispatch(openLoading());
        monthlyRentals
          .delete(monthlyRent)
          .then(() => {
            dispatch(closeLoading());
            swal(
              "Eliminado",
              "Se elimino el alquiler correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el alquiler", "error");
            console.log(err);
          });
      }
    });
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {form ? (
        <MonthlyRentForm
          handleClose={handleClose}
          handleSubmit={handleSaveMonthlyRent}
          data={dataEdit}
        />
      ) : null}
      {propertyId ? (
        <FullCalendarView propertyId={propertyId} handleClose={handleView} />
      ) : null}
      <div className={styles.controls}>
        <Filters
          filters={filters.data}
          setFilters={filters.setFilters}
          handleSubmit={page.firstPage}
          handleReset={filters.reset}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handleClose}
        >
          + Nuevo alquiler mensual
        </button>
      </div>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.firstRow}`}>
          <span>Propiedad</span>
          <span>Inquilino</span>
          <span>Tipo de contrato</span>
          <span>Plazo del contrato</span>
          <span>Dia de cobro</span>
          <span>Deposito</span>
          <span>Acciones</span>
        </div>
        <div className={styles.body}>
          {data.length <= 0 ? (
            <div className={styles.emptyRows}>
              <span>No hay alquileres mensuales</span>
            </div>
          ) : (
            data?.map((monthlyRent: MonthlyRent) => (
              <MonthlyRentRow
                key={monthlyRent.id}
                monthlyRent={monthlyRent}
                handleEdit={handleEdit}
                handleView={handleView}
                handleDelete={handleDelete}
              />
            ))
          )}
        </div>
        <div className={styles.pagination}>
          <span>{0} Propiedades</span>
          <button
            className={page.current <= 1 ? "" : styles.btnActive}
            type="button"
            onClick={() => {
              console.log("Button prev page");
              page.prevPage();
            }}
            disabled={page.current <= 1}
          >{`<`}</button>
          <span>{`${page.current} de ${page.totals}`}</span>
          <button
            className={page.current >= page.totals ? "" : styles.btnActive}
            type="button"
            onClick={() => {
              console.log("Button next page");
              page.nextPage();
            }}
            disabled={page.current >= page.totals}
          >{`>`}</button>
        </div>
      </div>
    </div>
  );
}
