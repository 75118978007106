import { useState } from "react";
import { RentType } from "../../../../interfaces/Rentals";

import DailyRentTable from "./DailyTable/DailyRentTable";
import MonthlyRentTable from "./MonthlyTable/MonthlyRentTable";
import PropertySaleTable from "./PropertySaleTable/PropertySaleTable";

import styles from "./Rentals.module.css";

export default function Rentals() {
  const [type, setType] = useState<RentType>(RentType.Daily);

  function handleType(type: RentType) {
    setType(type);
  }

  return (
    <div className={styles.dashboard}>
      <header>
        <button
          className={type === RentType.Daily ? styles.selected : ""}
          type="button"
          onClick={() => handleType(RentType.Daily)}
        >
          Diario
        </button>
        <button
          className={type === RentType.Monthly ? styles.selected : ""}
          type="button"
          onClick={() => handleType(RentType.Monthly)}
        >
          Mensual
        </button>
        <button
          className={type === RentType.Sale ? styles.selected : ""}
          type="button"
          onClick={() => handleType(RentType.Sale)}
        >
          En venta
        </button>
      </header>
      {type === RentType.Daily ? (
        <DailyRentTable />
      ) : type === RentType.Monthly ? (
        <MonthlyRentTable />
      ) : type === RentType.Sale ? (
        <PropertySaleTable />
      ) : null}
    </div>
  );
}
