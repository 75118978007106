import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../interfaces/RootState";
import {
  ErrorPropertySale,
  PropertySale,
  SaleState,
  initErrorPropertySale,
  initPropertySale,
} from "../../../../../../interfaces/Rentals";

import style from "./PropertySaleForm.module.css";
import Checkbox from "../../../../../../component/Inputs/Checkbox";
import TextAreaInput from "../../../../../../component/Inputs/TextareaInput";
import SelectInput from "../../../../../../component/Inputs/SelectInput";
import InputGroup from "../../../../../../component/Inputs/InputGroup/InputGroup";
import Input from "../../../../../../component/Inputs/Input";

interface Props {
  handleClose: () => void;
  handleSubmit: (PropertySale: PropertySale, edit: boolean) => void;
  data: PropertySale | undefined;
}

export default function PropertySaleForm({
  handleClose,
  handleSubmit,
  data,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);
  const [propertySale, setPropertySale] = useState<PropertySale>(
    initPropertySale()
  );
  const [error, setError] = useState<ErrorPropertySale>(
    initErrorPropertySale()
  );

  useEffect(() => {
    if (data) {
      setPropertySale(data);
    }
  }, [data]);

  function handlelocalSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(propertySale, data ? true : false);
    }
  }

  function handleChange(
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) {
    const name = event.target.name;
    const value = event.target.value;

    setPropertySale({
      ...propertySale,
      [name]: value,
    });
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const checked: boolean = event.target.checked;
    const name: string = event.target.name;
    setPropertySale({ ...propertySale, [name]: checked });
  }

  function handlePaymentState(state: SaleState) {
    setPropertySale({ ...propertySale, state });
  }

  function handleLocalClose() {
    handleClose();
  }

  function validations() {
    let errors = initErrorPropertySale();
    let value = true;

    /* ADD VALIDATIONS */

    setError(errors);
    return value;
  }

  return (
    <div className={style.background}>
      <form className={`toTop ${style.form}`} onSubmit={handlelocalSubmit}>
        <div className={style.close}>
          <h3>Nueva venta</h3>
          <div className="btn-close" onClick={handleLocalClose} />
        </div>
        <div className={style.flex}>
          <div className={style.content}>
            <SelectInput
              name="propertyId"
              label="Propiedad"
              value={propertySale.propertyId}
              list={properties
                .filter((property) => property.registerType?.sale)
                .map((property) => ({
                  label: property.name,
                  value: property.id!,
                }))}
              error={error.propertyId}
              handleChange={handleChange}
            />
            <InputGroup
              name="basePrice"
              label="Precio base"
              type="number"
              simbol="$"
              value={propertySale.basePrice}
              error={error.basePrice}
              handleChange={handleChange}
            />
            <InputGroup
              name="currentPrice"
              label="Precio de venta"
              type="number"
              simbol="$"
              value={propertySale.currentPrice}
              error={error.currentPrice}
              handleChange={handleChange}
            />
            <InputGroup
              name="managmentFees"
              label="Comisión"
              type="number"
              simbol="%"
              value={propertySale.managmentFees}
              error={error.managmentFees}
              handleChange={handleChange}
            />
            <InputGroup
              name="managmentFeesPrice"
              label="Comisión inmobiliaria"
              type="number"
              simbol="$"
              value={propertySale.managmentFeesPrice}
              error={error.managmentFeesPrice}
              handleChange={handleChange}
            />
          </div>
          <div className={style.content}>
            <Checkbox
              name={"financiacion"}
              label={"Financiación"}
              value={propertySale.financiacion}
              handleCheck={handleCheck}
            />
            {propertySale.financiacion && (
              <Input
                name="cuotes"
                label="Cuotas"
                type="number"
                value={propertySale.cuotes}
                handleChange={handleChange}
              />
            )}
            {propertySale.financiacion && (
              <Input
                name="squareMeters"
                label="Metros cuadrados"
                type="number"
                value={propertySale.squareMeters}
                handleChange={handleChange}
              />
            )}
            {propertySale.financiacion && (
              <TextAreaInput
                name="permuta"
                label="Permuta"
                value={propertySale.permuta}
                handleChange={handleChange}
              />
            )}
          </div>
          <div className={style.content}>
            <div className={style.paymentState}>
              <button
                className={
                  propertySale.state === SaleState.IN_SALE ? style.selected : ""
                }
                type="button"
                onClick={() => handlePaymentState(SaleState.IN_SALE)}
              >
                {SaleState.IN_SALE}
              </button>
              <button
                className={
                  propertySale.state === SaleState.DEPOSIT ? style.selected : ""
                }
                type="button"
                onClick={() => handlePaymentState(SaleState.DEPOSIT)}
              >
                {SaleState.DEPOSIT}
              </button>
              <button
                className={
                  propertySale.state === SaleState.SOLD ? style.selected : ""
                }
                type="button"
                onClick={() => handlePaymentState(SaleState.SOLD)}
              >
                {SaleState.SOLD}
              </button>
            </div>
            <InputGroup
              name="amount"
              label="Monto señado"
              type="number"
              simbol="$"
              value={propertySale.amount}
              handleChange={handleChange}
              disabled={!(propertySale.state === SaleState.DEPOSIT)}
            />
            <InputGroup
              name="price"
              label="Monto vendido"
              type="number"
              simbol="$"
              value={propertySale.price}
              handleChange={handleChange}
              disabled={!(propertySale.state === SaleState.SOLD)}
            />
          </div>
          <TextAreaInput
            name="considerations"
            label="Consideraciones"
            value={propertySale.considerations}
            error={error.considerations}
            handleChange={handleChange}
          />
          <button type="submit" className="btn btn-success">
            Agregar venta
          </button>
        </div>
      </form>
    </div>
  );
}
