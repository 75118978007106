import { RootState } from "../../../interfaces/RootState";
import { SigninData } from "../../../interfaces/login";
import { UserRol } from "../../../interfaces/users";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { Dispatch, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { auth, db, storage } from "../../../firebase/config";
import { AsyncAction } from "../../../interfaces/Actions";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

export const SIGN_IN = "SIGN_IN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export function signin(user: SigninData, file: File): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Create user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        user.email,
        user.password!
      );

      // Save logo
      const dir = `users/${userCredential.user.uid}/logo`;
      const storageReference = storageRef(storage, dir);
      const imageQuery = await uploadBytes(storageReference, file);

      // GET invoice image url
      const imageUrl = await getDownloadURL(imageQuery.ref);

      // Save user data
      const userId = userCredential.user.uid;
      const newUser = {
        name: user.name,
        profileImg: imageUrl,
        cuitCuil: Number(user.cuitCuil),
        email: user.email,
        rol: UserRol.Admin,
      };

      const userColl = collection(db, "Users");
      await setDoc(doc(userColl, userId), newUser);

      dispatch({
        type: SIGN_IN,
        payload: {
          id: userId,
          newUser,
        },
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  };
}

export function logIn(user: any): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        user.email,
        user.password
      );

      // Get user data
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, userCredentials.user.uid);
      const userData = await getDoc(userDoc);

      dispatch({
        type: LOGIN,
        payload: userData,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function logOut(): ThunkAction<
  Promise<void>,
  RootState,
  null,
  AnyAction
> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await signOut(auth);
      dispatch({
        type: LOGOUT,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
