import { openLoading, closeLoading } from "../../redux/actions/loading";
import { LoginData, initLogin } from "../../interfaces/login";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logIn } from "../../redux/actions/login";
import useProperty from "../../hooks/useProperty";
import useLogin from "../../hooks/useLogin";

import Input from "../../component/Inputs/Input";

import styles from "./Login.module.css";
import logo from "../../assets/img/icon.png";

export default function Login() {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const properties = useProperty();
  const login = useLogin();
  const [user, setUser] = useState<LoginData>(initLogin());
  const [error, setError] = useState<LoginData>(initLogin());

  useEffect(() => {
    login.autoLogin();
  }, []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const name: string = e.target.name;
    const value: string = e.target.value;
    setUser({ ...user, [name]: value });
    handleValidations(name, value);
  }

  function handleValidations(name: string, value: string) {
    if (name === "email") {
      setError({ ...error, email: "" });
    } else if (name === "password") {
      setError({ ...error, password: "" });
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (user.email === "" || user.password === "") {
      let err: LoginData = initLogin();
      if (user.email === "") err.email = "Debes ingresar un email";
      if (user.password === "") err.password = "Debes ingresar una contraseña";
      setError(err);
    } else {
      dispatch(openLoading());
      dispatch<any>(logIn(user))
        .then(() => {
          redirect("/dashboard");
          properties.page.get().then(() => dispatch(closeLoading()));
        })
        .catch((e: any) => {
          dispatch(closeLoading());
          if (e.message?.includes("invalid-email")) {
            setError({ ...error, email: "El email es invalido" });
          } else if (e.message?.includes("user-not-found")) {
            setError({ ...error, email: "Usuario incorrecto" });
          } else if (e.message?.includes("wrong-password")) {
            setError({ ...error, password: "Contraseña incorrecta" });
          } else {
            console.log(e);
          }
        });
    }
  }

  return (
    <div className={styles.background}>
      <header className="toBottom">
        <img src={logo} alt="logo" />
        <h1>GESTOPIA</h1>
      </header>
      <div className={styles.content}>
        <form className={`toRight ${styles.form}`} onSubmit={handleSubmit}>
          <h3>¡Bienvenido!</h3>
          <Input
            name="email"
            label="Correo"
            type="email"
            value={user.email}
            error={error.email}
            handleChange={handleChange}
          />
          <Input
            name="password"
            label="Contraseña"
            type="password"
            value={user.password}
            error={error.password}
            handleChange={handleChange}
          />
          <button className="btn btn-primary" type="submit">
            Ingresar
          </button>
        </form>
        <div className={styles.newAcount}>
          <Link to="/reset-password">¿Olvidate tu contraseña?</Link>
          <span>¿No tenes cuenta? </span>
          <Link to="/signin"> Crear cuenta</Link>
        </div>
      </div>
    </div>
  );
}
