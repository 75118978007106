import { PropertyState, initPropertyState } from "../../interfaces/RootState";
import { AnyAction } from "redux";
import { Property } from "../../interfaces/Properties";
import { LOGOUT } from "../actions/login";
import {
  SET_PROPERTY,
  GET_PROPERTIES,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
} from "../actions/property";

const propertyReducer = (
  state: PropertyState = initPropertyState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_PROPERTY:
      return [...state, action.payload].sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );

    case GET_PROPERTIES:
      return action.payload?.sort((a: any, b: any) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );

    case UPDATE_PROPERTY:
      return state.map((property: Property) =>
        property.id === action.payload.id
          ? { ...property, ...action.payload }
          : property
      );

    case DELETE_PROPERTY:
      return state.map((property: Property) => property.id !== action.payload);

    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default propertyReducer;
