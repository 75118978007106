import { Property, PropertyFilters, initPropertyFilters } from "./Properties";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { Charts, initCharts } from "./charts";
import { User, initUser } from "./users";
import { CashRegister } from "./CashRegister";
import { History } from "./History";
import { IFrames } from "./iframes";
import {
  DailyFilter,
  DailyRent,
  PropertySale,
  SaleFilter,
  initDailyFilter,
  initSaleFilter,
} from "./Rentals";
import {
  MonthlyFilter,
  MonthlyRent,
  initMonthlyFilter,
} from "./Registers/Monthly";

export interface Page<Type, Filters> {
  length: 6;
  totalDocs: number;
  totalPages: number;
  current: number;
  data: Array<Type[]>;
  filters: Filters;
  lastDoc: QuerySnapshot<DocumentData> | null;
}

export type PropertyState = Property[];

export type DailyRentState = Page<DailyRent, DailyFilter>;

export type MonthlyRentState = Page<MonthlyRent, MonthlyFilter>;

export type PropertySaleState = Page<PropertySale, SaleFilter>;
export interface RootState {
  loading: boolean;
  login: User;
  user: User;
  properties: PropertyState;
  dailyRentals: DailyRentState;
  monthlyRentals: MonthlyRentState;
  propertiesSale: PropertySaleState;
  history: {
    fullCallendar: History[];
    data: History[];
  };
  cashRegister: {
    acounts: string[];
    data: CashRegister[];
  };
  iframes: IFrames[];
  charts: Charts;
}

export const initPage = <Type, Filters>(
  initFilters: Filters
): Page<Type, Filters> => ({
  length: 6,
  totalDocs: 0,
  totalPages: 0,
  current: 0,
  data: [],
  filters: initFilters,
  lastDoc: null,
});

export const initPropertyState = () => [];

export const initDailyRentState = () =>
  initPage<DailyRent, DailyFilter>(initDailyFilter());

export const initMonthlyRentState = () =>
  initPage<MonthlyRent, MonthlyFilter>(initMonthlyFilter());

export const initPropertySaleState = () =>
  initPage<PropertySale, SaleFilter>(initSaleFilter());

export const initState = (): RootState => ({
  loading: false,
  login: initUser(),
  user: initUser(),
  properties: initPropertyState(),
  dailyRentals: initDailyRentState(),
  monthlyRentals: initMonthlyRentState(),
  propertiesSale: initPropertySaleState(),
  history: {
    fullCallendar: [],
    data: [],
  },
  cashRegister: {
    acounts: [],
    data: [],
  },
  charts: initCharts,
  iframes: [],
});
