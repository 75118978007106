import { SaleFilter, SaleState } from "../../../../../../interfaces/Rentals";
import { useState } from "react";

import style from "./Filter.module.css";
import filterSvg from "../../../../../../assets/svg/filter.svg";
import SelectInput from "../../../../../../component/Inputs/SelectInput";

interface Props {
  filters: SaleFilter;
  setFilters: (filters: SaleFilter) => void;
  handleSubmit: () => void;
  handleReset: () => void;
}

export default function Filters({
  filters,
  setFilters,
  handleSubmit,
  handleReset,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <div className={style.filter}>
      <button className={style.btnFilter} type="button" onClick={handleOpen}>
        <span>Filtros</span>
        <img src={filterSvg} alt="filtros" />
      </button>
      {open ? (
        <div className={style.filterContainer}>
          <SelectInput
            name="financing"
            label="Financiación"
            list={[
              { value: "true", label: "Si" },
              { value: "false", label: "No" },
            ]}
            value={filters.financing}
            handleChange={handleChange}
          />
          <SelectInput
            name="state"
            label="Estado"
            list={[
              { label: SaleState.IN_SALE, value: SaleState.IN_SALE },
              { label: SaleState.DEPOSIT, value: SaleState.DEPOSIT },
              { label: SaleState.SOLD, value: SaleState.SOLD },
            ]}
            value={filters.state}
            handleChange={handleChange}
          />
          <div className={style.contentBtn}>
            <button
              className="btn btn-success"
              type="button"
              onClick={handleSubmit}
            >
              Aplicar
            </button>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleReset}
            >
              Borrar
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
