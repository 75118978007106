export interface Rent {
  id?: string;
  propertyId: string;
  squereMeters?: number;
  tenant: Tenant;
  price: number;
  total: number;
  managmentFees?: number;
  dates: {
    initDate: Date;
    endDate: Date;
  };
  historyId?: string;
}

export interface RemoveFiles {
  contract: string[];
  secure: string[];
}

export interface DailyRent extends Rent {
  paymentState: PaymentState;
  reservationChannel: string;
  nigths: number;
  amount: number;
  codeKey: string;
  reservationNumber: number;
  commission: number;
  taxes: {
    name1: string;
    amount1: number;
    name2: string;
    amount2: number;
    name3: string;
    amount3: number;
    name4: string;
    amount4: number;
  };
}

export enum PaymentState {
  ANY = "",
  PENDING = "Pendiente",
  DEPOSIT = "Señado",
  PAID = "Pagado",
  CANCELED = "Cancelado",
}

export interface PropertySale {
  id?: string;
  propertyId: string;
  propertyName: string;
  basePrice: number;
  currentPrice: number;
  state: SaleState;
  considerations: string;
  financiacion: boolean;
  cuotes: number;
  amount: number;
  price: number;
  permuta: string;
  squareMeters: number;
  managmentFeesPrice?: number;
  managmentFees?: number;
  historyId?: string;
}

export enum SaleState {
  ANY = "",
  IN_SALE = "En venta",
  DEPOSIT = "Señado",
  SOLD = "Vendido",
}

export interface Tenant {
  id?: string;
  name: string;
  phone?: string;
  email?: string;
  dni?: number;
}

export enum RentType {
  Monthly,
  Daily,
  Sale,
}

export interface DailyFilter {
  month: number;
  propertyId: string;
  reservationChannel: string;
  paymentState: PaymentState;
}

export interface SaleFilter {
  financing: string;
  state: SaleState;
}

export interface ErrorDailyRent {
  propertyId: string;
  tenantName: string;
  reservationChannel: string;
  maxTenants: string;
  nigths: string;
  price: string;
  total: string;
  dates: string;
  managmentFees?: string;
}

export interface ErrorPropertySale {
  propertyId: string;
  propertyName: string;
  basePrice: string;
  currentPrice: string;
  considerations: string;
  managmentFees: string;
  managmentFeesPrice: string;
}

export const initDailyFilter = (): DailyFilter => ({
  propertyId: "",
  month: 0,
  reservationChannel: "",
  paymentState: PaymentState.ANY,
});

export const initSaleFilter = (): SaleFilter => ({
  financing: "",
  state: SaleState.ANY,
});

export const initTenant = (): Tenant => ({
  name: "",
});

export const initRemoveFiles = (): RemoveFiles => ({
  contract: [],
  secure: [],
});

export const initDailyRent = (): DailyRent => ({
  propertyId: "",
  tenant: initTenant(),
  reservationChannel: "",
  paymentState: PaymentState.PENDING,
  price: 0,
  total: 0,
  nigths: 0,
  managmentFees: 0,
  amount: 0,
  reservationNumber: 0,
  commission: 0,
  codeKey: "",
  taxes: {
    name1: "",
    amount1: 0,
    name2: "",
    amount2: 0,
    name3: "",
    amount3: 0,
    name4: "",
    amount4: 0,
  },
  dates: {
    initDate: new Date(),
    endDate: new Date(),
  },
});

export const initPropertySale = (): PropertySale => ({
  propertyId: "",
  propertyName: "",
  basePrice: 0,
  currentPrice: 0,
  state: SaleState.IN_SALE,
  considerations: "",
  cuotes: 0,
  amount: 0,
  price: 0,
  permuta: "",
  squareMeters: 0,
  financiacion: false,
  managmentFees: 0,
});

export const initErrorDailyRent = (): ErrorDailyRent => ({
  propertyId: "",
  tenantName: "",
  reservationChannel: "",
  maxTenants: "",
  nigths: "",
  price: "",
  total: "",
  dates: "",
  managmentFees: "",
});

export const initErrorPropertySale = (): ErrorPropertySale => ({
  propertyId: "",
  propertyName: "",
  basePrice: "",
  currentPrice: "",
  considerations: "",
  managmentFees: "",
  managmentFeesPrice: "",
});
