import { Property, PropertyImage } from "../../../interfaces/Properties";
import { auth, db, storage } from "../../../firebase/config";
import { AsyncAction } from "../../../interfaces/Actions";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  setDoc,
  query,
  updateDoc,
} from "firebase/firestore";

export const SET_PROPERTY = "SET_PROPERTY";
export const GET_PROPERTIES = "GET_PROPERTIES";

export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const DELETE_PROPERTY = "DELETE_PROPERTY";

export function setProperty(property: Property, images: File[]): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // If the user is logued in
      if (!auth.currentUser) throw new Error("Property: User in not logged in");

      // Firestore ref's
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const propertyColl = collection(userDoc, "Properties");
      const propertyDoc = doc(propertyColl);

      // Storage variables
      const imagesUrls: PropertyImage[] = [];

      // Upload the images and get it's url images
      for (const file of images) {
        const propertyDir = `users/${auth.currentUser.uid}/properties/${propertyDoc.id}/${file.name}`;
        const storageReference = ref(storage, propertyDir);

        // Image
        const imageQuery = await uploadBytes(storageReference, file);
        const imageUrl = await getDownloadURL(imageQuery.ref);

        imagesUrls.push({
          normal: {
            url: imageUrl,
            path: imageQuery.metadata.fullPath,
          },
        });
      }

      // New porperty
      const newProperty = {
        ...property,
        id: propertyDoc.id,
        imgUrl: imagesUrls,
      };

      // Set property doc
      await setDoc(propertyDoc, newProperty);

      dispatch({
        type: SET_PROPERTY,
        payload: newProperty,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getProperties(): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("user is not logged in");

      // User collection references
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const propertyColl = collection(userDoc, "Properties");

      // Get the properties
      const snapshot = await getDocs(query(propertyColl, orderBy("name")));

      let properties: Property[] = [];
      snapshot.forEach((doc) => {
        properties.push({
          id: doc.id,
          ...(doc.data() as Property),
        });
      });

      dispatch({
        type: GET_PROPERTIES,
        payload: properties,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateProperty(
  property: Property,
  newImages?: File[],
  imagesToRemove?: string[]
): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    // Firestore references
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const propertyColl = collection(userDoc, "Properties");

    // Storage variables
    let newPropertyImages: PropertyImage[] = property.imgUrl;

    // Load new images
    if (newImages) {
      for (const file of newImages) {
        const propertyDir = `users/${auth.currentUser.uid}/properties/${property.id}/${file.name}`;
        const storageReference = ref(storage, propertyDir);

        // Image
        const imageQuery = await uploadBytes(storageReference, file);
        const imageUrl = await getDownloadURL(imageQuery.ref);

        newPropertyImages.push({
          normal: {
            url: imageUrl,
            path: imageQuery.metadata.fullPath,
          },
        });
      }
    }

    // Delete images
    if (imagesToRemove) {
      for (const path of imagesToRemove) {
        // Create a reference to the file and delete
        const desertRef = ref(storage, path);
        await deleteObject(desertRef);
      }
      // Remove image from property data
      newPropertyImages = newPropertyImages.filter(
        (image) => !imagesToRemove?.some((path) => image.normal.path === path)
      );
    }

    // Delete id
    let { id, ...updatedProperty } = property;

    // Add images
    updatedProperty = {
      ...updatedProperty,
      imgUrl: newPropertyImages,
    };

    // Update property
    await updateDoc(doc(propertyColl, property.id), updatedProperty);

    try {
      dispatch({
        type: UPDATE_PROPERTY,
        payload: {
          ...property,
          imgUrl: newPropertyImages,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteProperty(property: Property): AsyncAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (!auth.currentUser) throw new Error("User logged in");

    // Firestore
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, auth.currentUser.uid);
    const propertyColl = collection(userDoc, "Properties");

    // Delete property
    await Promise.all([
      deleteDoc(doc(propertyColl, property.id)),
      ...property.imgUrl.map((img) =>
        deleteObject(ref(storage, img.normal.path))
      ),
    ]);

    try {
      dispatch({
        type: DELETE_PROPERTY,
        payload: property.id,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
