import { useSelector } from "react-redux";
import { RootState } from "../../../../../../interfaces/RootState";
import { useState } from "react";
import {
  DailyFilter,
  PaymentState,
} from "../../../../../../interfaces/Rentals";

import SelectInput from "../../../../../../component/Inputs/SelectInput";

import style from "./Filter.module.css";
import filterSvg from "../../../../../../assets/svg/filter.svg";

interface Props {
  filters: DailyFilter;
  setFilters: (filters: DailyFilter) => void;
  handleSubmit: () => void;
  handleReset: () => void;
}

export default function Filters({
  filters,
  setFilters,
  handleSubmit,
  handleReset,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);
  const [open, setOpen] = useState<boolean>(false);

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <div className={style.filter}>
      <button className={style.btnFilter} type="button" onClick={handleOpen}>
        <span>Filtros</span>
        <img src={filterSvg} alt="filtros" />
      </button>
      {open ? (
        <div className={style.filterContainer}>
          <SelectInput
            name="propertyId"
            label="Propiedad"
            list={properties.map((property) => ({
              label: property.name,
              value: property.id!,
            }))}
            value={filters.propertyId}
            handleChange={handleChange}
          />
          <SelectInput
            name="month"
            label="Mes"
            list={[
              { label: "Enero", value: 1 },
              { label: "Febrero", value: 2 },
              { label: "Marzo", value: 3 },
              { label: "Abril", value: 4 },
              { label: "Mayo", value: 5 },
              { label: "Junio", value: 6 },
              { label: "Julio", value: 7 },
              { label: "Agosto", value: 8 },
              { label: "Septiembre", value: 9 },
              { label: "Octubre", value: 10 },
              { label: "Noviembre", value: 11 },
              { label: "Diciembre", value: 12 },
            ]}
            value={filters.month}
            handleChange={handleChange}
          />
          <SelectInput
            name="reservationChannel"
            label="Canal de reserva"
            list={[
              { label: "Booking", value: "Booking" },
              { label: "Airbnb", value: "Airbnb" },
              { label: "Otro", value: "Otro" },
            ]}
            value={filters.reservationChannel}
            handleChange={handleChange}
          />
          <SelectInput
            name="paymentState"
            label="Estado de pago"
            list={[
              { value: PaymentState.PENDING, label: PaymentState.PENDING },
              { value: PaymentState.DEPOSIT, label: PaymentState.DEPOSIT },
              { value: PaymentState.PAID, label: PaymentState.PAID },
            ]}
            value={filters.paymentState}
            handleChange={handleChange}
          />
          <div className={style.contentBtn}>
            <button
              className="btn btn-success"
              type="button"
              onClick={handleSubmit}
            >
              Aplicar
            </button>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleReset}
            >
              Borrar
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
