import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { RootState } from "../../../../../interfaces/RootState";
import { useSelector } from "react-redux";
import {
  CashChartData,
  initCashChartData,
} from "../../../../../interfaces/charts";

const header = ["Dias", "Ingresos", "Egresos"];
const example = [header];

const options = {
  chart: {
    title: "Movimientos diarios",
  },
};

interface Filter {
  year: string;
  month: string | null;
  day: string | null;
}

interface Props {
  filter: Filter;
}

export default function ProcessesChart({ filter }: Props) {
  const cashRegister = useSelector(
    (state: RootState) => state.cashRegister.data
  );
  const [data, setData] = useState<Array<Array<string | number>>>(example);

  useEffect(() => {
    let cashChartData: CashChartData[] = initCashChartData(
      Number(filter.year),
      Number(filter.month)
    );
    let newData: Array<Array<string | number>> = [header];

    cashRegister.map((register) => {
      // Get day
      const day = register.date?.getDate();

      if (day) {
        // Get day data
        const dayData = cashChartData.find((data) => data.day === day);

        // Check if day already exist
        if (dayData) {
          dayData.income += Number(register.money.income);
          dayData.expense += Number(register.money.expense);
        } else {
          cashChartData.push({
            day,
            income: Number(register.money.income),
            expense: Number(register.money.expense),
          });
        }
      }
    });

    cashChartData.map((data) => {
      newData.push([data.day, data.income, data.expense]);
    });

    setData(newData);
  }, [cashRegister]);

  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="200px"
      data={data}
      options={options}
    />
  );
}
