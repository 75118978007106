import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { PropertySale } from "../../../../../interfaces/Rentals";
import {
  closeLoading,
  openLoading,
} from "../../../../../redux/actions/loading";
import usePropertySales from "../../../../../hooks/Rentals/usePropertySale";
import swal from "sweetalert";

import FullCalendarView from "../../../../../component/FullCalendarView/FullCalendarView";
import PropertySaleRow from "./PropertySaleRow/PropertySaleRow";
import PropertySaleForm from "./PropertySaleForm/PropertySaleForm";
import Filters from "./Filters/Filters";

import styles from "./PropertySaleTable.module.css";

export default function PropertySaleTable() {
  const dispatch = useDispatch();
  const { data, totalsDocs, filters, page, loading, ...propertySales } =
    usePropertySales();
  const [dataEdit, setDataEdit] = useState<PropertySale | undefined>();
  const [propertyId, setDataView] = useState<string>();
  const [form, setForm] = useState(false);

  useEffect(() => {
    if (data.length <= 0) {
      setTimeout(() => {
        page.firstPage();
      }, 1500);
    }
  }, []);

  function handleView(propertyId?: string) {
    setDataView(propertyId || "");
  }

  function handleEdit(data: PropertySale) {
    setDataEdit(data);
    handleClose();
  }

  function handleClose() {
    setForm(!form);
    if (form) {
      setDataEdit(undefined);
    }
  }

  function handleSavePropertySale(PropertySale: PropertySale, edit: boolean) {
    dispatch(openLoading());
    edit
      ? propertySales.update(PropertySale)
      : propertySales
          .add(PropertySale)
          .then(() => {
            dispatch(closeLoading());
            handleClose();
            swal(
              "Guardado",
              "Se guardo el propertySale correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo guardar el propertySale", "error");
            console.log(err);
          });
  }

  function handleDelete(propertySale: PropertySale) {
    swal({
      text: "¿Seguro desea eliminar este propertySale?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: any) => {
      if (response === "Si") {
        dispatch(openLoading());
        propertySales
          .delete(propertySale)
          .then(() => {
            dispatch(closeLoading());
            swal(
              "Eliminado",
              "Se elimino el propertySale correctamente",
              "success"
            );
          })
          .catch((err: any) => {
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el propertySale", "error");
            console.log(err);
          });
      }
    });
  }

  return (
    <div className={`toLeft ${styles.dashboard}`}>
      {form ? (
        <PropertySaleForm
          handleClose={handleClose}
          handleSubmit={handleSavePropertySale}
          data={dataEdit}
        />
      ) : null}
      {propertyId ? (
        <FullCalendarView propertyId={propertyId} handleClose={handleView} />
      ) : null}
      <div className={styles.controls}>
        <Filters
          filters={filters.data}
          setFilters={filters.setFilters}
          handleSubmit={page.firstPage}
          handleReset={filters.reset}
        />
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handleClose}
        >
          + Nueva venta
        </button>
      </div>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.firstRow}`}>
          <span>Nombre</span>
          <span>Precio base</span>
          <span>Precio Optimo</span>
          <span>Estado</span>
          <span>Permuta</span>
          <span>Financiación</span>
          <span>Comisión</span>
        </div>
        <div className={styles.body}>
          {data.length <= 0 ? (
            <div className={styles.emptyRows}>
              <span>No hay ventas</span>
            </div>
          ) : (
            data?.map((propertySale: PropertySale) => (
              <PropertySaleRow
                key={propertySale.id}
                propertySale={propertySale}
                handleEdit={handleEdit}
                handleView={handleView}
                handleDelete={handleDelete}
              />
            ))
          )}
        </div>
        <div className={styles.pagination}>
          <span>{0} Propiedades</span>
          <button
            className={page.current <= 1 ? "" : styles.btnActive}
            type="button"
            onClick={() => {
              console.log("Button prev page"); /* page.prevPage() */
            }}
            disabled={page.current <= 1}
          >{`<`}</button>
          <span>{`${page.current} de ${page.totals}`}</span>
          <button
            className={page.current >= page.totals ? "" : styles.btnActive}
            type="button"
            onClick={() => {
              console.log("Button next page"); /* page.nextPage() */
            }}
            disabled={page.current >= page.totals}
          >{`>`}</button>
        </div>
      </div>
    </div>
  );
}
