import { initMonthlyRentState } from "../../interfaces/RootState";
import { AnyAction } from "redux";
import { LOGOUT } from "../actions/login";
import {
  SET_MONTHLY_RENT,
  UPDATE_MONTHLY_RENT,
  DELETE_MONTHLY_RENT,
  GET_MONTHLY_RENT_NEXT_PAGE,
  SET_MONTHLY_RENT_NEXT_PAGE,
  SET_MONTHLY_RENT_PREV_PAGE,
  GET_MONTHLY_RENT,
} from "../actions/rentals/monthlyRentals";

const monthlyRentReducer = (
  state = initMonthlyRentState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_MONTHLY_RENT:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case GET_MONTHLY_RENT:
      return {
        ...state,
        data: [action.payload.data],
        current: 1,
        totalDocs: action.payload.totals,
        totalPages: Math.ceil(action.payload.totals / state.length),
        lastDoc: action.payload.lastDoc,
      };

    case GET_MONTHLY_RENT_NEXT_PAGE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        current: state.current + 1,
        lastDoc: action.payload.lastDoc,
      };

    case SET_MONTHLY_RENT_NEXT_PAGE:
      return {
        ...state,
        current: state.current + 1,
      };

    case SET_MONTHLY_RENT_PREV_PAGE:
      return {
        ...state,
        current: state.current - 1,
      };

    case UPDATE_MONTHLY_RENT:
      return {
        ...state,
        data: state.data.map((page) =>
          page.map((monthly) =>
            monthly.id === action.payload.id
              ? { ...monthly, ...action.payload.data }
              : monthly
          )
        ),
      };

    case DELETE_MONTHLY_RENT:
      return {
        ...state,
        data: state.data.map((page) =>
          page.filter((monthly) => monthly.id !== action.payload.id)
        ),
      };

    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default monthlyRentReducer;
