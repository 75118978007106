export interface History {
  id?: string;
  propertyId: string;
  tenantName?: string;
  price: number;
  admissionDate: Date | null;
  type: HistoryType;
  rentalDates?: {
    initDate: Date;
    endDate: Date;
  };
}

export enum HistoryType {
  monthly = "Mensual",
  daily = "Diario",
  sale = "Venta",
  any = "",
}