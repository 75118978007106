import { initPropertySaleState } from "../../interfaces/RootState";
import { PropertySale } from "../../interfaces/Rentals";
import { AnyAction } from "redux";
import { LOGOUT } from "../actions/login";
import {
  SET_PROPERTY_SALE,
  UPDATE_PROPERTY_SALE,
  DELETE_PROPERTY_SALE,
  GET_SALE,
  GET_SALE_NEXT_PAGE,
  SET_SALE_NEXT_PAGE,
  SET_SALE_PREV_PAGE,
} from "../actions/rentals/propertySale";

const propertySalesReducer = (
  state = initPropertySaleState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_PROPERTY_SALE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    case GET_SALE:
      return {
        ...state,
        data: [action.payload.data],
        current: 1,
        totalDocs: action.payload.totals,
        totalPages: Math.ceil(action.payload.totals / state.length),
        lastDoc: action.payload.lastDoc,
      };

    case GET_SALE_NEXT_PAGE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        current: state.current + 1,
        lastDoc: action.payload.lastDoc,
      };

    case SET_SALE_NEXT_PAGE:
      return {
        ...state,
        current: state.current + 1,
      };

    case SET_SALE_PREV_PAGE:
      return {
        ...state,
        current: state.current - 1,
      };

    case UPDATE_PROPERTY_SALE:
      return {
        ...state,
        data: state.data.map((page: PropertySale[]) =>
          page.map((sale: PropertySale) =>
            sale.id === action.payload.id
              ? { ...sale, ...action.payload.data }
              : sale
          )
        ),
      };

    case DELETE_PROPERTY_SALE:
      return {
        ...state,
        data: state.data.map((page: PropertySale[]) =>
          page.filter((sale: PropertySale) => sale.id !== action.payload.id)
        ),
      };

    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default propertySalesReducer;
