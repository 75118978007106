import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../interfaces/RootState";
import {
  ErrorDailyRent,
  DailyRent,
  initErrorDailyRent,
  initDailyRent,
  PaymentState,
} from "../../../../../../interfaces/Rentals";

import Input from "../../../../../../component/Inputs/Input";
import InputGroup from "../../../../../../component/Inputs/InputGroup/InputGroup";
import SelectInput from "../../../../../../component/Inputs/SelectInput";

import style from "./DailyRentForm.module.css";

interface Props {
  handleClose: () => void;
  handleSubmit: (DailyRent: DailyRent, edit: boolean) => void;
  data: DailyRent | undefined;
}

const canalDeReversa = ["Booking", "Airbnb", "Otro"];

export default function DailyRentForm({
  handleClose,
  handleSubmit,
  data,
}: Props) {
  const properties = useSelector((state: RootState) => state.properties);
  const dailyRentals = useSelector(
    (state: RootState) => state.dailyRentals.data[0]
  );
  const [dailyRent, setDailyRent] = useState<DailyRent>(initDailyRent());
  const [error, setError] = useState<ErrorDailyRent>(initErrorDailyRent());
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    showDateDisplay: true,
  });

  useEffect(() => {
    if (data) {
      setDailyRent(data);
      updateDisabledDates(data.propertyId, data.id);
      setSelectedRange({
        ...selectedRange,
        startDate: data.dates.initDate,
        endDate: data.dates.endDate,
      });
    }
    return () => {
      setDailyRent(initDailyRent());
    };
  }, [data]);

  function updateDisabledDates(propertyId: string, dailyRentId?: string) {
    let disabledDates: Date[] = [];

    // Iterate all rents
    for (const rent of dailyRentals) {
      if (
        rent.propertyId !== propertyId ||
        (dailyRentId && dailyRentId === rent.id)
      )
        continue;
      let startDate: Date = new Date(rent.dates.initDate.toISOString());
      let endDate: Date = new Date(rent.dates.endDate.toISOString());
      let currentDate: Date = new Date(rent.dates.initDate.toISOString());

      do {
        // If currentDate dosn't exist
        if (!disabledDates.includes(currentDate)) {
          // If is a start date
          if (currentDate.getTime() === startDate.getTime()) {
            // If is some endDate too
            if (
              dailyRentals.some(
                (rent) => rent.dates.endDate.getTime() === currentDate.getTime()
              )
            ) {
              // Add to disabledDates
              disabledDates.push(new Date(currentDate.toISOString()));
            } // Don't add because should be selectable as endDate
          } else {
            // Add to disabledDates
            disabledDates.push(new Date(currentDate.toISOString()));
          }
        }

        // Set next day
        currentDate.setDate(currentDate.getDate() + 1);
      } while (currentDate.getTime() < endDate.getTime());
    }
    setDisabledDates(disabledDates);
  }

  const handleSelect = (ranges: any) => {
    const initDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    let nigths = initDate && endDate ? Math.abs(initDate - endDate) : 0;
    nigths = Math.ceil(nigths / (1000 * 60 * 60 * 24));

    if (dailyRent.propertyId) {
      setSelectedRange(ranges.selection);
      setDailyRent({
        ...dailyRent,
        nigths,
        dates: {
          initDate: ranges.selection.startDate,
          endDate: ranges.selection.endDate,
        },
      });
    }
  };

  function handlelocalSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(dailyRent, data ? true : false);
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) {
    setDailyRent({ ...dailyRent, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
    if (event.target.name === "propertyId") {
      updateDisabledDates(event.target.value);
    }
  }

  function handleChangeTaxes(event: React.ChangeEvent<HTMLInputElement>) {
    setDailyRent({
      ...dailyRent,
      taxes: {
        ...dailyRent.taxes,
        [event.target.name]: event.target.value,
      },
    });
  }

  function handlePaymentState(paymentState: PaymentState) {
    setDailyRent({ ...dailyRent, paymentState });
  }

  function handleTenantChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDailyRent({
      ...dailyRent,
      tenant: { ...dailyRent.tenant, [event.target.name]: event.target.value },
    });

    if (event.target.name === "name") {
      setError({ ...error, tenantName: "" });
    }
  }

  function handleLocalClose() {
    handleClose();
  }

  function validations() {
    let errors = initErrorDailyRent();
    let value = true;

    /* ADD VALIDATIONS */
    if (dailyRent.propertyId === "") {
      errors.propertyId = "Debes seleccionar una propiedad";
      value = false;
    }

    if (dailyRent.reservationChannel === "") {
      errors.reservationChannel = "Debes seleccionar un canal de reserva";
      value = false;
    }

    if (dailyRent.price <= 0) {
      errors.price = "Debes agregar un precio";
      value = false;
    }

    if (dailyRent.tenant.name === "") {
      errors.tenantName = "Debes agregar el nombre del huesped";
      value = false;
    }

    if (dailyRent.dates.initDate === null || dailyRent.dates.endDate === null) {
      errors.dates = "Debes seleccionar las fechas";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={style.background}>
      <form className={`toTop ${style.form}`} onSubmit={handlelocalSubmit}>
        <div className={style.close}>
          <h3>Nuevo alquiler diario</h3>
          <div className="btn-close" onClick={handleLocalClose} />
        </div>
        <div className={style.flex}>
          <div className={style.container}>
            <h5>Propiedad</h5>
            <SelectInput
              name={"propertyId"}
              label={"Propiedad"}
              value={dailyRent.propertyId}
              list={properties
                .filter((property) => property.registerType?.daily)
                .map((property) => ({
                  value: property.id!,
                  label: property.name,
                }))}
              error={error.propertyId}
              handleChange={handleChange}
            />
            <SelectInput
              name={"reservationChannel"}
              value={dailyRent.reservationChannel}
              label={"Canal de reserva"}
              list={canalDeReversa.map((canal) => ({
                value: canal,
                label: canal,
              }))}
              error={error.reservationChannel}
              handleChange={handleChange}
            />
            <InputGroup
              name={"price"}
              value={dailyRent.price}
              label={"Precio"}
              simbol="$"
              type={"number"}
              error={error.price}
              handleChange={handleChange}
            />
            <InputGroup
              name={"total"}
              value={dailyRent.total}
              label={"Total"}
              simbol="$"
              type={"number"}
              error={error.total}
              handleChange={handleChange}
            />
            <div className={style.paymentState}>
              <button
                className={
                  dailyRent.paymentState === PaymentState.PENDING
                    ? style.selected
                    : ""
                }
                type="button"
                onClick={() => handlePaymentState(PaymentState.PENDING)}
              >
                Pendiente
              </button>
              <button
                className={
                  dailyRent.paymentState === PaymentState.DEPOSIT
                    ? style.selected
                    : ""
                }
                type="button"
                onClick={() => handlePaymentState(PaymentState.DEPOSIT)}
              >
                Señado
              </button>
              <button
                className={
                  dailyRent.paymentState === PaymentState.PAID
                    ? style.selected
                    : ""
                }
                type="button"
                onClick={() => handlePaymentState(PaymentState.PAID)}
              >
                Pagado
              </button>
              <button
                className={
                  dailyRent.paymentState === PaymentState.CANCELED
                    ? style.selected
                    : ""
                }
                type="button"
                onClick={() => handlePaymentState(PaymentState.CANCELED)}
              >
                Cancelado
              </button>
            </div>
            <Input
              name={"amount"}
              value={dailyRent.amount}
              label={"Monto"}
              handleChange={handleChange}
              disabled={
                !(
                  dailyRent.paymentState === PaymentState.DEPOSIT ||
                  dailyRent.paymentState === PaymentState.CANCELED
                )
              }
            />
            <h5>Otros datos</h5>
            <Input
              name={"codeKey"}
              value={dailyRent.codeKey}
              label={"Código de llave"}
              handleChange={handleChange}
            />
            <Input
              name={"reservationNumber"}
              value={dailyRent.reservationNumber}
              label={"Numero de reserva"}
              handleChange={handleChange}
            />
          </div>
          <div className={style.container}>
            <h5>Huesped</h5>
            <Input
              name={"name"}
              value={dailyRent.tenant.name}
              label={"Nombre"}
              error={error.tenantName}
              handleChange={handleTenantChange}
            />
            <Input
              name={"dni"}
              value={dailyRent.tenant.dni}
              label={"D.N.I."}
              handleChange={handleTenantChange}
            />
            <Input
              name={"email"}
              value={dailyRent.tenant.email}
              label={"Email"}
              type={"email"}
              handleChange={handleTenantChange}
            />
            <Input
              name={"phone"}
              value={dailyRent.tenant.phone}
              label={"Telefono"}
              type={"phone"}
              handleChange={handleTenantChange}
            />
            <Input
              name={"nigths"}
              value={dailyRent.nigths}
              label={"Noches"}
              type={"number"}
              disabled={true}
              error={error.nigths}
              handleChange={handleChange}
            />
            <h5>Impuestos y tasas</h5>
            <div className={style.taxes}>
              <Input
                name={"name1"}
                value={dailyRent.taxes?.name1}
                label={"Nombre"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"amount1"}
                value={dailyRent.taxes?.amount1}
                label={"Monto"}
                type={"number"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"name2"}
                value={dailyRent.taxes?.name2}
                label={"Nombre"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"amount2"}
                value={dailyRent.taxes?.amount2}
                label={"Monto"}
                type={"number"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"name3"}
                value={dailyRent.taxes?.name3}
                label={"Nombre"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"amount3"}
                value={dailyRent.taxes?.amount3}
                label={"Monto"}
                type={"number"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"name4"}
                value={dailyRent.taxes?.name4}
                label={"Nombre"}
                handleChange={handleChangeTaxes}
              />
              <Input
                name={"amount4"}
                value={dailyRent.taxes?.amount4}
                label={"Monto"}
                type={"number"}
                handleChange={handleChangeTaxes}
              />
            </div>
            <InputGroup
              name={"commission"}
              value={dailyRent.commission}
              label={"Comisión"}
              simbol="%"
              handleChange={handleChange}
            />
          </div>
          <div>
            <DateRangePicker
              ranges={[selectedRange]}
              onChange={handleSelect}
              disabledDates={disabledDates}
            />
          </div>
          <span>{error.dates}</span>
        </div>
        <button type="submit" className="btn btn-success">
          {data ? "Guardar" : "Agregar"} alquiler
        </button>
      </form>
    </div>
  );
}
