import { useState } from "react";
import {
  PropertyFilters,
  PropertyType,
} from "../../../../../interfaces/Properties";

import SelectInput from "../../../../../component/Inputs/SelectInput";

import style from "./Filter.module.css";
import filterSvg from "../../../../../assets/svg/filter.svg";

interface Props {
  filters: PropertyFilters;
  setFilters: (fitlers: PropertyFilters) => void;
  handleSubmit: () => void;
  handleReset: () => void;
}

export default function Filters({
  filters,
  setFilters,
  handleSubmit,
  handleReset,
}: Props) {
  const [view, setView] = useState<boolean>(false);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  }

  function handleView() {
    setView(!view);
  }

  function handleRemove() {
    handleReset();
    setView(false);
  }

  return (
    <div className={style.filter}>
      <button className={style.btnFilter} type="button" onClick={handleView}>
        <span>Filtros</span>
        <img src={filterSvg} alt="filtros" />
      </button>
      {view && (
        <div className={style.filterContainer}>
          <SelectInput
            name="registerType"
            label="Tipo de registro"
            value={filters.registerType}
            list={[
              { label: "Diario", value: "daily" },
              { label: "Mensual", value: "monthly" },
              { label: "Venta", value: "sale" },
            ]}
            handleChange={handleChange}
          />
          <SelectInput
            name="type"
            label="Tipo de propiedad"
            value={filters.type}
            list={[
              { label: PropertyType.TERRENO, value: PropertyType.TERRENO },
              { label: PropertyType.CASA, value: PropertyType.CASA },
              {
                label: PropertyType.DEPARTAMENTO,
                value: PropertyType.DEPARTAMENTO,
              },
              {
                label: PropertyType.DESARROLLO,
                value: PropertyType.DESARROLLO,
              },
              { label: PropertyType.CABANIA, value: PropertyType.CABANIA },
              { label: PropertyType.OFICINA, value: PropertyType.OFICINA },
              { label: PropertyType.GALPON, value: PropertyType.GALPON },
              { label: PropertyType.CAMPO, value: PropertyType.CAMPO },
            ]}
            handleChange={handleChange}
          />
          <SelectInput
            name="managment"
            label="Propietario o Administrado"
            value={filters.managment}
            list={[
              { value: "Propietario", label: "Propietario" },
              { value: "Administrado", label: "Administrado" },
            ]}
            handleChange={handleChange}
          />
          <div className={style.contentBtn}>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={handleRemove}
            >
              Borrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
