import { useEffect, useState } from "react";
import { PropertyImage } from "../../../../../../interfaces/Properties";

import img from "../../../../../../assets/img/img.png";

import styles from "./AddImages.module.css";

interface Props {
  images: PropertyImage[],
  imagesToUpload: ImagesToUpload[];
  imagesToRemove: string[];
  setImagesToUpload: (images: ImagesToUpload[]) => void,
  setImagesToRemove: (images: string[]) => void,
}

interface ImagesToUpload {
  url: string,
  file: File
}

export default function AddImages({ images, imagesToUpload, imagesToRemove, setImagesToUpload, setImagesToRemove }: Props) {
  const [imageSelected, setImageSelected] = useState<string>(img);
  const [imagesUrl, setImagesUrl] = useState<string[]>([]);

  // Set current images
  useEffect(() => {
    setImagesUrl(images.map((img) => img.normal.url));
  }, [images]);

  // Set selected image
  useEffect(() => {
    if (imagesUrl.length === 0) {
      setImageSelected(img);
    } else {
      setImageSelected(imagesUrl[0]);
    }
  }, [imagesUrl]);

  function handleSelect(url: string) {
    setImageSelected(url);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Get the file
    const files: FileList | null = event.target.files;
    let urls: string[] = [];
    let image: ImagesToUpload[] = [];

    // If file exist save to upload
    if (files) {
      // Get the local url
      for (const file of files) {
        const newUrl = URL.createObjectURL(file);
        urls.push(newUrl);
        image.push({ url: newUrl, file: file });
      }

      setImagesToUpload([...imagesToUpload, ...image]);
      setImagesUrl([...imagesUrl, ...urls]);
    }
  }

  function handleRemove() {
    // Check if the 'imageSelected' exist in imageToUpload
    const imageToRemove: ImagesToUpload | undefined = imagesToUpload.find((img) => img.url === imageSelected);

    // Check if the 'imageSelected' exist in images from props
    const fileToRemove: PropertyImage | undefined = images.find((img) => img.normal.url === imageSelected);

    // If exist just delete it from 'imageToUpload'
    if (imageToRemove) {
      setImagesToUpload(imagesToUpload.filter((img) => img.url !== imageSelected));
    } else if (fileToRemove) {
      // Otherwise, if image is in the 'images', add your path to imagesToRemove
      setImagesToRemove([...imagesToRemove, fileToRemove.normal.path]);
    }

    // Delete the url
    setImagesUrl(imagesUrl.filter((img) => img !== imageSelected));

  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.imageContainer}>
          {imagesUrl.length > 0 ? (
            <div
              className={`btn-close ${styles.delete}`}
              onClick={handleRemove}
            />
          ) : null}
          <img src={imageSelected} alt="img" />
          <div className={styles.controllers}>
            <div className={styles.imgList}>
              {imagesUrl.length > 0 ? (
                imagesUrl.map((url) => (
                  <div
                    key={url}
                    className={styles.image}
                    onClick={() => handleSelect(url)}
                  >
                    <img src={url} alt="property imgage" />
                  </div>
                ))
              ) : (
                <div className={styles.empty}>
                  <span>Vacío</span>
                </div>
              )}
            </div>
            <div className={`b-3 form-floating ${styles.input}`}>
              <label className="form-control" htmlFor="images">
                +
              </label>
              <input
                className="form-control"
                id="images"
                type="file"
                multiple
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
